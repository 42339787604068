import React, { ReactElement } from 'react';
import { AccordionContainer, Nullable, useTranslations } from '@lerna-core';
import {
  CourseTitleContainer,
  CourseTitleType,
} from '@features/course/courseTitle';
import { CoursePageArticleStyled } from '@features/course/course.styled';
import { useCoursePageContext } from '@features/course/course.context';
import {
  CourseProgramAccordionStyled,
  CourseProgramContainerStyled,
} from './courseProgram.styled';
import { mapAccordionItems } from './courseProgram.helper';

export const CourseProgramContainer = (): Nullable<ReactElement> => {
  const { course } = useCoursePageContext();
  const translations = useTranslations();
  const program = course.programSections;

  if (!program || (program && program.length === 0)) {
    return null;
  }

  return (
    <CourseProgramContainerStyled>
      <CourseTitleContainer
        title={translations.course_sections_title}
        type={CourseTitleType.h3}
      />
      <CourseProgramAccordionStyled>
        <CoursePageArticleStyled>
          <AccordionContainer
            items={mapAccordionItems(program)}
            autoClose={false}
          />
        </CoursePageArticleStyled>
      </CourseProgramAccordionStyled>
    </CourseProgramContainerStyled>
  );
};
