import {
  clearAcademikaCourseItemsVendor,
  CourseItemModel,
  filterCustomerCourses,
  filterCustomerPopCourses,
  UserAggregateModel,
} from '@lerna-core';

export const getBannerCourse = (user: UserAggregateModel): CourseItemModel => {
  const courses = user.courses
    ? [...user.courses.courses.personal, ...user.courses.courses.corporate]
    : [];
  const customerPopCourses = filterCustomerPopCourses(
    courses,
    user.career?.companyId
  );
  const customerCourses = filterCustomerCourses(
    courses,
    user.career?.companyId
  );
  const allCourses = clearAcademikaCourseItemsVendor(
    customerPopCourses.concat(customerCourses)
  );

  return allCourses[0];
};
