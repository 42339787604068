import React, { ReactElement } from 'react';

import { CourseItemComponentProps } from './courseItem.model';
import {
  CourseItemButtonStyled,
  CourseItemContainerStyled,
  CourseItemContentStyled,
  CourseItemContentWrapperStyled,
  CourseItemDescriptionStyled,
  CourseItemLogoStyled,
  CourseItemSubTitleStyled,
  CourseItemTitleStyled,
} from './courseItem.styled';
import { ImageContainer } from '../../image';
import { CourseItemProgressComponent } from './courseItemProgress';
import { VendorEnum } from '../../vendors';

export const CourseItemComponent = ({
  course,
  button,
  className,
}: CourseItemComponentProps): ReactElement => {
  const courseItemImageResource =
    course?.logo || course?.vendor?.coursePlug?.imageResource;

  return (
    <CourseItemContainerStyled className={className}>
      {!!courseItemImageResource && (
        <CourseItemLogoStyled>
          <ImageContainer
            imageResource={courseItemImageResource}
            //TODO: think about naming presets
            preset={'logoCourse'}
          />
        </CourseItemLogoStyled>
      )}
      <CourseItemContentStyled>
        <CourseItemContentWrapperStyled>
          <div>
            <CourseItemTitleStyled>{course.title}</CourseItemTitleStyled>
            <CourseItemDescriptionStyled>
              {course?.vendor && (
                <CourseItemSubTitleStyled>
                  {course.vendor.title}
                </CourseItemSubTitleStyled>
              )}
              {course?.studyType ? (
                <CourseItemSubTitleStyled>
                  {course.studyType.toLowerCase()}
                </CourseItemSubTitleStyled>
              ) : null}
            </CourseItemDescriptionStyled>
          </div>
          {!!button && (
            <CourseItemButtonStyled>{button}</CourseItemButtonStyled>
          )}
        </CourseItemContentWrapperStyled>

        <CourseItemProgressComponent
          vendorSlug={course.vendor?.slug as VendorEnum}
          progress={course.progress}
        />
      </CourseItemContentStyled>
    </CourseItemContainerStyled>
  );
};
