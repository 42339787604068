import React, { ReactElement } from 'react';
import { Nullable, useTranslations } from '@lerna-core';
import { DiscountTotalContainerProps } from './discountPromoCode.model';
import { DiscountTotalContainerStyled } from './discountPromoCode.styled';

export const DiscountPromoCodeContainer = ({
  price,
}: DiscountTotalContainerProps): Nullable<ReactElement> => {
  const translations = useTranslations();

  if (!price) {
    return null;
  }

  return (
    <DiscountTotalContainerStyled>
      {translations.promo_code_discount || 'Скидка по промокоду'}:
      <b>&nbsp;{price.discount?.amount}</b>
    </DiscountTotalContainerStyled>
  );
};
