import styled from 'styled-components';
import { customizeScrollBar } from '../../../styles';

export const SearchSuggestContainerStyled = styled.div`
  border-radius: ${({ theme }): string =>
    theme.IS_MOBILE ? '0' : '0 0 16px 16px'};
  background-color: ${({ theme }): string => theme.COLOR.WHITE};
  overflow: hidden;
`;

export const SearchSuggestWrapperStyled = styled.div`
  padding: ${({ theme }): string => (theme.IS_MOBILE ? '0' : '16px 20px 0')};
  ${customizeScrollBar(0, 16)}
`;
