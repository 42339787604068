import { NextRouter } from 'next/router';
import {
  ApiCashBackModel,
  ApiCoursesSubscriptionAccessModel,
  CoursesSubscriptionAccessModel,
  ImageModel,
  Nullable,
} from '../../models';
import { ApiImage } from '../image';
import { CourseDurationApiModel, CourseDurationModel } from './courseDurations';
import { CourseFaqsApiModel, CourseFaqsModel } from './courseFaqs';
import { CourseLabelApiModel, CourseLabelModel } from './courseLabel';
import { ApiCourseOptionModel, CourseOptionModel } from './courseOptions';
import { ApiCoursePricesModel, CoursePricesModel } from './coursePrices';
import {
  ApiProgramSectionModel,
  CourseProgramSectionModel,
} from './courseProgram';
import { CoursePropModel } from './courseProps';
import { CourseSkillApiModel, CourseSkillModel } from './courseSkills';
import { ApiCourseTutorModel, CourseTutorModel } from './courseTutors';
import { CourseLinkApiModel, CourseLinkModel } from './courseUrl';
import { CourseBenefitApiModel, CourseBenefitModel } from './courseBenefit';
import {
  UniqueSellingPropositionsApiModel,
  UniqueSellingPropositionsModel,
} from './courseUniqueSellingPropositions';
import {
  CourseOrderTypesApiModel,
  CourseOrderTypesModel,
} from './courseOrderTypes';

export enum CourseStatus {
  active = 'active',
  archived = 'archived',
  inactive = 'inactive',
}

export type ApiCourseModel = {
  uuid: string;
  prices: {
    data: ApiCoursePricesModel;
  };
  start_date: string;
  start_date_raw: string;
  title: string;
  link: string;
  slug: string;
  status: CourseStatus;
  options: {
    data: ApiCourseOptionModel[];
  };
  provided_bonus_points: string;
  is_hit: boolean;
  description: string;
  unique_selling_propositions: UniqueSellingPropositionsApiModel;
  external_id: string;
  numeric_id: string;
  order_types: CourseOrderTypesApiModel;
  logo: Nullable<ApiImage>;
  url: Nullable<{
    data: CourseLinkApiModel;
  }>;
  image_background_color: Nullable<string>;
  is_help_employment: Nullable<boolean>;
  type: Nullable<CourseTypes>;
  durations: Nullable<{
    data: CourseDurationApiModel[];
  }>;
  is_active?: boolean;
  vendor_uuid: string;
  mega_discount?: boolean;
  program_sections?: {
    data: ApiProgramSectionModel[];
  };
  tutors?: {
    data: ApiCourseTutorModel[];
  };
  faqs?: {
    data: CourseFaqsApiModel[];
  };
  skills_sections?: {
    data: CourseSkillApiModel[];
  };
  bonuses?: {
    data: ApiCashBackModel;
  };
  subscription_access?: ApiCoursesSubscriptionAccessModel;
  comment?: Nullable<string>;
  is_custom_compensation_available?: boolean;
  logo_label?: CourseLabelApiModel;
  vendor_label?: CourseLabelApiModel;
  is_allow_to_redirect?: boolean;
  benefit?: CourseBenefitApiModel;
  lms_id?: string;
};

export type CourseImageModel = Omit<ImageModel, 'backgroundColor'> & {
  backgroundColor: string;
};

export type CourseModel = {
  uuid: string;
  price: CoursePricesModel;
  props: CoursePropModel[];
  isHelpEmployment: boolean;
  startDate: string;
  startDateRaw: string;
  title: string;
  aboutLink: string;
  slug: string;
  isAllowToRedirect: boolean;
  isFree: boolean;
  isFreeByDiscount: boolean;
  isFreeByBonuses: boolean;
  isFreeByVendor: boolean;
  isFreeByVendorDiscount: boolean;
  status: Nullable<CourseStatus>;
  // todo убрать КТТС
  isActive?: boolean;
  hasDiscount: boolean;
  programSections: CourseProgramSectionModel[];
  options: CourseOptionModel[];
  faqs: CourseFaqsModel[];
  skills: CourseSkillModel[];
  tutors: CourseTutorModel[];
  isHit: boolean;
  externalId: string;
  numericId: string;
  isCustomCompensationAvailable: boolean;
  imageBackgroundColor: string;
  orderTypes: CourseOrderTypesModel;
  logo: Nullable<CourseImageModel>;
  url: Nullable<CourseLinkModel>;
  benefit: Nullable<CourseBenefitModel>;
  subscriptionAccess: Nullable<CoursesSubscriptionAccessModel>;
  type: Nullable<CourseTypes>;
  durations: Nullable<CourseDurationModel[]>;
  description: Nullable<string>;
  comment: Nullable<string>;
  megaDiscount: Nullable<boolean>;
  logoLabel: Nullable<CourseLabelModel>;
  vendorLabel: Nullable<CourseLabelModel>;
  vendorUuid: string;
  lmsId: Nullable<string>;
  uniqueSellingPropositions: Nullable<UniqueSellingPropositionsModel>;
};

export type CourseUrlParams = Record<string, string>;

export type CoursePageHrefProps = {
  course: Pick<CourseModel, 'url' | 'slug'>;
  router: NextRouter;
  urlParams: CourseUrlParams;
  baseDomain?: string;
  basePath?: string;
};

export enum CourseTypes {
  course = 'course',
  faculty = 'faculty',
  profession = 'profession',
  geekschool = 'geek-school',
  granule = 'granule',
  allCourses = 'all_courses',
  client = 'client',
  package = 'package',
  subscription = 'subscription',
  specialized = 'specialized',
  advanced = 'advanced',
  academika = 'academika',
}
