import React, { ReactElement, useMemo } from 'react';
import { AccordionContainer, useTranslations } from '@lerna-core';
import { CourseFaqsContainerProps } from './courseFaqs.model';
import { mapCourseFaqsForAccordion } from './courseFaqs.helper';
import {
  CourseFaqsAccordionStyled,
  CourseFaqsContainerStyled,
} from './courseFaqs.styled';
import { CourseTitleContainer, CourseTitleType } from '../courseTitle';
import { CoursePageArticleStyled } from '../course.styled';

export const CourseFaqsContainer = ({
  faqs,
}: CourseFaqsContainerProps): ReactElement => {
  const translations = useTranslations();
  const accordionItems = useMemo(() => mapCourseFaqsForAccordion(faqs), []);

  return (
    <CourseFaqsContainerStyled>
      <CourseTitleContainer
        title={translations.course_questions_title}
        type={CourseTitleType.h3}
      />
      <CourseFaqsAccordionStyled>
        <CoursePageArticleStyled>
          <AccordionContainer items={accordionItems} autoClose={false} />
        </CoursePageArticleStyled>
      </CourseFaqsAccordionStyled>
    </CourseFaqsContainerStyled>
  );
};
