import React, { ReactElement, useMemo } from 'react';
import { Nullable } from '@lerna-core';
import {
  hasB2CDiscount,
  hasBonusesPoints,
  hasCashback,
  hasCustomerDiscount,
  hasLegalEntitiesDiscount,
  hasProgramDiscount,
} from '@features/common/course';
import { CourseDiscountContainerProps } from './itemDiscount.model';
import { CourseDiscountContainerStyled } from './itemDiscount.styled';
import { DiscountTotalContainer } from './discountTotal';
import { DiscountPointsContainer } from './discountPoints';
import { useShowLegalEntitiesPriceHook } from '@hooks';
import { DiscountPromoCodeContainer } from './discountPromoCode';

const tagLabelBorderRadius = 4;

export const ItemDiscountContainer = ({
  price,
  megaDiscount,
  isFreeCourseByDiscount,
}: CourseDiscountContainerProps): Nullable<ReactElement> => {
  const showLegalEntitiesPrice = useShowLegalEntitiesPriceHook();

  const isB2B = useMemo(() => !!price.priceCustomer, []);
  const cashBack = useMemo(
    () =>
      !hasBonusesPoints(isB2B, price) && hasCashback(price) && price.cashBack,
    [price]
  );
  const hasDiscount = useMemo(() => {
    const B2BDiscount = showLegalEntitiesPrice
      ? hasLegalEntitiesDiscount(price)
      : hasCustomerDiscount(price);

    return (
      (isB2B ? B2BDiscount : hasB2CDiscount(price)) || hasProgramDiscount(price)
    );
  }, [price, showLegalEntitiesPrice]);

  const showCashBack = !!cashBack && !isFreeCourseByDiscount;
  const showPromoCodeDiscount =
    price.priceWithPromoCodeDiscount?.discount?.amount &&
    !isFreeCourseByDiscount;

  if (!hasDiscount && !cashBack && !showPromoCodeDiscount) {
    return null;
  }

  return (
    <CourseDiscountContainerStyled>
      {hasDiscount && (
        <DiscountTotalContainer
          price={price}
          isB2B={isB2B}
          megaDiscount={megaDiscount}
          tagLabelBorderRadius={tagLabelBorderRadius}
        />
      )}
      {showPromoCodeDiscount && (
        <DiscountPromoCodeContainer price={price.priceWithPromoCodeDiscount} />
      )}
      {showCashBack && (
        <DiscountPointsContainer
          cashBack={cashBack}
          tagLabelBorderRadius={tagLabelBorderRadius}
        />
      )}
    </CourseDiscountContainerStyled>
  );
};
