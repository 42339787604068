import {
  IconCloseComponent,
  IconHighlightComponent,
  Nullable,
  useTheme,
  useTranslations,
} from '@lerna-core';
import React, { memo, ReactElement } from 'react';
import { useCoursesCompensationHint } from './coursesCompensationHint.hook';
import {
  CloseIconStyled,
  CoursesCompensationHintStyled,
  HighlightIconStyled,
  WrapperStyled,
} from './coursesCompensationHint.styled';

const CoursesCompensationHint = (): Nullable<ReactElement> => {
  const translations = useTranslations();
  const theme = useTheme();
  const { showHint, handleHintHide } = useCoursesCompensationHint();

  if (!showHint) return null;

  return (
    <CoursesCompensationHintStyled>
      <WrapperStyled>
        <HighlightIconStyled>
          <IconHighlightComponent fillColor={theme.COLOR.INFO_GREEN_COLOR} />
        </HighlightIconStyled>
        {translations?.courses_compensation_hint_text}
      </WrapperStyled>
      <CloseIconStyled onClick={handleHintHide}>
        <IconCloseComponent width={16} height={16} />
      </CloseIconStyled>
    </CoursesCompensationHintStyled>
  );
};

export const CoursesCompensationHintContainer = memo(CoursesCompensationHint);
