import { useTranslations } from '@lerna-core';
import React, { ReactElement } from 'react';
import { useCoursesPageContext } from '../../coursesPage';
import { FilterColumnContainer } from '../filterColumn';
import { FiltersClearContainer } from '../filtersClear';
import { FiltersColumnModel } from './filtersColumn.model';
import { FiltersColumnContainerStyled } from './filtersColumn.styled';

export const FiltersColumnContainer = ({
  withFilterDescription = true,
}: FiltersColumnModel): ReactElement => {
  const translations = useTranslations();
  const { filters } = useCoursesPageContext();

  return (
    <>
      <FiltersColumnContainerStyled>
        {filters.map((filter) => (
          <FilterColumnContainer
            withFilterDescription={withFilterDescription}
            filter={filter}
            key={filter.slug}
          />
        ))}
      </FiltersColumnContainerStyled>
      <FiltersClearContainer label={translations.clear_filters_label} />
    </>
  );
};
