import React, { ReactElement } from 'react';
import {
  ButtonColor,
  ButtonContainer,
  TextAreaControlComponent,
  useTranslations,
} from '@lerna-core';
import {
  ButtonStyled,
  PopupContentStyled,
  TitleStyled,
  WrapperStyled,
} from './popupContent.styled';
import { useCompensationFormContext } from '../../compensationForm.context';
import { AgreementStyled } from '../../compensationForm.styled';

export const PopupContentContainer = (): ReactElement => {
  const {
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    isSubmitting,
    handleFormSubmit,
  } = useCompensationFormContext();
  const translations = useTranslations();

  return (
    <PopupContentStyled>
      <TitleStyled>Мотивационное письмо</TitleStyled>
      <WrapperStyled>
        <TextAreaControlComponent
          value={values.comment}
          onChange={handleChange}
          onBlur={handleBlur}
          name="comment"
          placeholder={translations?.order_form_comment_input_placeholder}
          disabled={isSubmitting}
          touched={touched.comment}
          errorMessage={errors.comment}
          floatingPlaceholder={false}
          margin="0"
        />
        <ButtonStyled>
          <ButtonContainer
            onClick={handleFormSubmit}
            color={ButtonColor.purple}
            borderRadius={8}
            title={translations?.send_request}
            disabled={isSubmitting}
          />
        </ButtonStyled>
        <AgreementStyled
          dangerouslySetInnerHTML={{
            __html: translations?.public_contract_text,
          }}
        />
      </WrapperStyled>
    </PopupContentStyled>
  );
};
