import React, { memo, ReactElement } from 'react';
import * as S from './coursesCount.styled';
import { useCoursesPageContext } from '../coursesPage';
import { useTranslations } from '@lerna-core';
import { useCoursesContext } from '@features/courses';

export const CoursesCount = (): ReactElement => {
  const { seo } = useCoursesPageContext();
  const translations = useTranslations();
  const { courses } = useCoursesContext();
  const title = seo.h1 ? (
    <S.CoursesCountH1>{seo.h1}</S.CoursesCountH1>
  ) : (
    <S.CoursesTitle>{translations?.courses_found}</S.CoursesTitle>
  );

  return (
    <S.CoursesCount>
      {title}
      <S.CoursesNumber>{courses.meta.total}</S.CoursesNumber>
    </S.CoursesCount>
  );
};

export const CoursesCountContainer = memo(CoursesCount);
