import React, { ReactElement } from 'react';
import {
  PricesInstallmentStyled,
  TitleStyled,
  ValueStyled,
  ValueWithDiscountStyled,
} from './pricesInstallment.styled';
import { PricesInstallmentContainerProps } from './pricesInstallment.model';
import {
  getCurrencies,
  getMonthlyPaymentUnits,
  Nullable,
  useTranslations,
} from '@lerna-core';
import { useSelector } from 'react-redux';

export const PricesInstallmentContainer = ({
  installment,
  hasDiscounts,
}: PricesInstallmentContainerProps): Nullable<ReactElement> => {
  const translations = useTranslations();
  const currencies = useSelector(getCurrencies);

  if (!installment) return null;

  return (
    <PricesInstallmentStyled>
      <TitleStyled>
        {translations.installment_price_label} {installment.duration}
      </TitleStyled>
      {hasDiscounts && (
        <ValueStyled>
          {getMonthlyPaymentUnits(installment.price, currencies)}
        </ValueStyled>
      )}
      <ValueWithDiscountStyled>
        {getMonthlyPaymentUnits(installment.priceWithTotalDiscount, currencies)}
      </ValueWithDiscountStyled>
    </PricesInstallmentStyled>
  );
};
