import React, {
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useFormik } from 'formik';
import { useSelector } from 'react-redux';
import {
  browserService,
  CommonEventTypes,
  DeviceInfoContext,
  getCurrentPopupFromOpen,
  getVendors,
  logger,
  Nullable,
  PopupsEnum,
  requestClosePopup,
  useTranslations,
  useUser,
} from '@lerna-core';
import {
  B2BOrderResponse,
  B2CFreeOrderResponse,
  B2COrderResponse,
  getFormOrderInfo,
  isConflictError,
  OrderFormEvents,
  orderFormPushDataLayer,
  OrderFormRequestTypes,
  useGoogleSheetLoggerContext,
  withPromoCodeError,
} from '@features/common/forms';
import {
  getFormSubmitEventValues,
  getGranulePayFormInitialValues,
  getGranulePayFormPaymentEventValues,
  getGranulePayFormValidationSchema,
  pushFormSubmitStatusEvent,
  pushGranulePayFormPaymentEvent,
} from './granulePayForm.helper';
import { sendUserOrderRequest } from '../../userOrderForm.helper';
import { PageNameContext } from '@contexts';
import { useDiscountProgram } from '@features/common/discountProgram';
import { useRouter } from 'next/router';
import {
  GranulePayFormContextModel,
  GranulePayFormContextProviderProps,
} from './granulePayForm.model';
import {
  UserOrderFormPaymentContentTypes,
  UserOrderFormSubmitStatusTypes,
} from '../../userOrderForm.model';
import { useCourseActiveContext } from '@features/courses/coursesActive';
import { useAppDispatch } from '@store/store.hooks';

const GranulePayFormContext =
  React.createContext<Nullable<GranulePayFormContextModel>>(null);

export const GranulePayFormContextProvider = ({
  props,
  children,
}: GranulePayFormContextProviderProps): ReactElement => {
  const router = useRouter();
  const { user } = useUser();
  const dispatch = useAppDispatch();
  const translations = useTranslations();
  const vendors = useSelector(getVendors);
  const pageName = useContext(PageNameContext);
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);
  const { removeNumericId } = useCourseActiveContext();
  const { googleSheetLogger } = useGoogleSheetLoggerContext();
  const { promoCodeQuery, discountProgramQuery } = useDiscountProgram();
  const currentPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.userOrderFormPopup)
  );
  const formOrderInfo = getFormOrderInfo(props.course);

  const initialPaymentContent = !formOrderInfo.hasConsultation
    ? UserOrderFormPaymentContentTypes.onlinePay
    : UserOrderFormPaymentContentTypes.consultation;

  const [paymentContent, setPaymentContent] =
    useState<UserOrderFormPaymentContentTypes>(initialPaymentContent);
  const [isSuccessDisplayed, setIsSuccessDisplayed] = useState<boolean>(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);
  const [isExistOrder, setIsExistOrder] = useState<boolean>(false);

  const submitButtonTitle =
    paymentContent === UserOrderFormPaymentContentTypes.consultation
      ? translations?.send_request
      : translations?.go_to_payment;
  const isOnlinePaySelected =
    paymentContent === UserOrderFormPaymentContentTypes.onlinePay;
  const showFormStatus =
    (isSuccessDisplayed && !isOnlinePaySelected) ||
    isErrorDisplayed ||
    isExistOrder;
  const isFreeCourse =
    props.course.isFree ||
    props.course.isFreeByBonuses ||
    props.course.isFreeByDiscount;

  const showPaymentTabs = formOrderInfo.hasOrder && !isFreeCourse;

  const validationSchema = useMemo(
    () => getGranulePayFormValidationSchema(translations, isOnlinePaySelected),
    [isOnlinePaySelected]
  );
  const initialValues = useMemo(() => getGranulePayFormInitialValues(), []);

  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    setErrors,
    setStatus,
    setTouched,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    validateOnMount: true,
    onSubmit: (values, actions) => {
      actions.setSubmitting(true);
      actions.setStatus({});

      const promoCodeValue =
        !isOnlinePaySelected && values.withPromoCode
          ? values.promoCode
          : undefined;

      const formEventValues = isOnlinePaySelected
        ? getGranulePayFormPaymentEventValues(showPaymentTabs)
        : getFormSubmitEventValues(
            props.course,
            paymentContent,
            showPaymentTabs
          );

      sendUserOrderRequest(
        user!,
        props.course,
        router,
        true,
        false,
        pageName,
        googleSheetLogger.bind(null, formEventValues),
        promoCodeValue,
        undefined,
        discountProgramQuery,
        isOnlinePaySelected
      )
        .then((response) => onSuccess(!isOnlinePaySelected, response))
        .catch((error): void => {
          const conflictError = isConflictError(error);
          const promoCodeError = withPromoCodeError(error);

          if (promoCodeError) {
            setInputError(
              'promoCode',
              `${translations?.promocode_does_not_match}`
            );
            orderFormPushDataLayer(
              OrderFormEvents.formEnrollPromoCodeError,
              CommonEventTypes.coursesEvents,
              props.eventPosition,
              props.course,
              vendors
            );

            return;
          }
          if (conflictError) {
            onExistError();

            return;
          }

          onError();
          logger.error(
            `[ERROR]: error sending request. ${error.response?.data}`
          );
        })
        .finally(() => {
          actions.setSubmitting(false);
        });
    },
  });

  const clearData = (): void => {
    setErrors({});
    setStatus({});
    setTouched({});
    !promoCodeQuery && setFieldValue('withPromoCode', false);
    !promoCodeQuery && setFieldValue('promoCode', '');
    setPaymentContent(initialPaymentContent);
    isSuccessDisplayed && setIsSuccessDisplayed(false);
    isErrorDisplayed && setIsErrorDisplayed(false);
    isExistOrder && setIsExistOrder(false);
  };
  const onSuccess = (
    withAnalytics = true,
    response: Nullable<
      B2COrderResponse | B2BOrderResponse | B2CFreeOrderResponse
    >
  ): void => {
    props.handleFormSent();
    if (withAnalytics) {
      pushFormSubmitStatusEvent(
        UserOrderFormSubmitStatusTypes.success,
        props.course,
        vendors,
        props.eventPosition,
        paymentContent,
        showPaymentTabs,
        (response as B2COrderResponse)?.id ||
          (response as B2BOrderResponse)?.order?.uuid ||
          (response as B2CFreeOrderResponse)?.order?.dealId,
        user?.career?.companyId
          ? OrderFormRequestTypes.b2b
          : OrderFormRequestTypes.b2c
      );
    }
    if (values.withPromoCode) {
      orderFormPushDataLayer(
        OrderFormEvents.formEnrollPromoCode,
        CommonEventTypes.coursesEvents,
        props.eventPosition,
        props.course,
        vendors
      );
    }

    setIsSuccessDisplayed(true);

    const redirect = (response as B2BOrderResponse)?.payment?.url;
    if (redirect) {
      router.push(redirect);
    }
  };
  const onError = (): void => {
    pushFormSubmitStatusEvent(
      UserOrderFormSubmitStatusTypes.error,
      props.course,
      vendors,
      props.eventPosition,
      paymentContent,
      showPaymentTabs
    );
    setIsErrorDisplayed(true);
  };
  const onExistError = (): void => {
    pushFormSubmitStatusEvent(
      UserOrderFormSubmitStatusTypes.exist,
      props.course,
      vendors,
      props.eventPosition,
      paymentContent,
      showPaymentTabs
    );
    setIsExistOrder(true);
  };
  const setInputError = (inputName: string, value: string): void => {
    setFieldError(inputName, value);
  };
  const handlePopupClose = async (): Promise<void> => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollClose,
      CommonEventTypes.coursesEvents,
      props.eventPosition,
      props.course,
      vendors,
      props.courseIndex
    );

    await dispatch(requestClosePopup(PopupsEnum.userOrderFormPopup)).then(
      () => {
        if (typeof removeNumericId === 'function') {
          removeNumericId();
        }
      }
    );
    clearData();
  };
  const handleChangePaymentContent = (
    content: keyof typeof UserOrderFormPaymentContentTypes
  ): void => {
    setPaymentContent(content as UserOrderFormPaymentContentTypes);
  };
  const handleFormSubmit = (): void => {
    if (isOnlinePaySelected) {
      pushGranulePayFormPaymentEvent(
        props.course,
        vendors,
        props.eventPosition,
        showPaymentTabs
      );
    }
    handleSubmit();
  };

  useEffect(() => {
    if (promoCodeQuery && discountProgramQuery) {
      setFieldValue('withPromoCode', true);
      setFieldValue('promoCode', promoCodeQuery);
    }
    if (!discountProgramQuery) {
      setFieldValue('withPromoCode', false);
      setFieldValue('promoCode', '');
    }
  }, [promoCodeQuery, currentPopup]);

  useEffect(() => {
    setPaymentContent(initialPaymentContent);
  }, [initialPaymentContent]);

  const context = {
    ...props,
    currentPopup,
    handlePopupClose,
    paymentContent,
    handleChangePaymentContent,
    submitButtonTitle,
    handleFormSubmit,
    handleChange,
    handleBlur,
    values,
    isSubmitting,
    errors,
    touched,
    setFieldValue,
    isSuccessDisplayed,
    isErrorDisplayed,
    isExistOrder,
    showFormStatus,
    showPaymentTabs,
    isOnlinePaySelected,
    isFreeCourse,
    isMobile,
    discountProgramQuery,
    promoCodeQuery,
    formOrderInfo,
  };

  return (
    <GranulePayFormContext.Provider value={context}>
      {children}
    </GranulePayFormContext.Provider>
  );
};

export const useGranulePayFormContext = (): GranulePayFormContextModel => {
  const value = useContext(GranulePayFormContext);

  if (!value) {
    logger.error('[ERROR]: you cannot use context without a provider');

    return {} as GranulePayFormContextModel;
  }

  return value;
};
