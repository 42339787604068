import React, { ReactElement, useState } from 'react';
import { Nullable, useTranslations } from '@lerna-core';
import {
  CourseTitleContainer,
  CourseTitleType,
} from '@features/course/courseTitle';
import {
  CourseTutorsContainerStyled,
  CourseTutorsWrapStyled,
} from './courseTutors.styled';
import { TutorsItemContainer } from './tutorsItem';
import { PaperFooterContainer } from '@features/course/coursePaper/paperFooter';
import { useCoursePageContext } from '@features/course/course.context';

const TUTORS_LIMIT = 4;

export const CourseTutorsContainer = (): Nullable<ReactElement> => {
  const {
    course: { tutors },
  } = useCoursePageContext();
  const translations = useTranslations();

  const [isOpened, setIsOpened] = useState(tutors.length <= TUTORS_LIMIT);

  const visibleCount = isOpened ? tutors : tutors.slice(0, TUTORS_LIMIT);

  const handleClickShowMore = (): void => setIsOpened(true);

  if (tutors.length === 0) {
    return null;
  }

  return (
    <CourseTutorsContainerStyled>
      <CourseTitleContainer
        type={CourseTitleType.h3}
        title={translations.course_tutors_title}
      />
      <CourseTutorsWrapStyled>
        {visibleCount.map((tutor, idx) => (
          <TutorsItemContainer key={idx} tutor={tutor} />
        ))}
      </CourseTutorsWrapStyled>
      {!isOpened && (
        <PaperFooterContainer
          label={translations.show_more_label}
          handleClick={handleClickShowMore}
        />
      )}
    </CourseTutorsContainerStyled>
  );
};
