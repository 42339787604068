import React, { forwardRef, ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  FilterModel,
  FilterTypeEnum,
  getFilters,
  Nullable,
  useUser,
} from '@lerna-core';
import { useCoursesPageContext } from '../../coursesPage';
import { TabsRowContainer } from './tabsRow';
import { FiltersTabsStyled } from './filtersTabs.styled';

export const FiltersTabsContainer = forwardRef<HTMLDivElement>(
  ({}, ref): Nullable<ReactElement> => {
    const { filters: contextFilters } = useCoursesPageContext();
    const filtersFromStore = useSelector(getFilters);
    const { user } = useUser();

    const filters = !!filtersFromStore.length
      ? filtersFromStore
      : contextFilters;

    const tabFilters = useMemo(
      () => filters.filter((filter) => filter.type === FilterTypeEnum.tab),
      [filters]
    );

    const isFiltersHidden = user?.career?.isFiltersHiddenOnListing;

    if (isFiltersHidden) return null;

    return (
      <FiltersTabsStyled ref={ref}>
        {(tabFilters as FilterModel[]).map((filter) => (
          <TabsRowContainer key={filter.slug} filter={filter} />
        ))}
      </FiltersTabsStyled>
    );
  }
);

FiltersTabsContainer.displayName = 'FiltersTabs';
