import { FastLinkModel, FastLinkWithActiveModel } from './fastLinks.model';
import { Nullable, QueryParams, TranslationModel } from '../../models';
import { objectService, urlService } from '../../services';
import { FiltersSlugEnum } from '../filters';
import { ParsedUrlQuery } from 'querystring';

export const getLinksWithAllCategories = (
  links: FastLinkModel[],
  fastLinkQuery: string,
  translations: TranslationModel
): FastLinkWithActiveModel[] => {
  const allCategoryLink = {
    ...allCategoryFastLink(translations?.all_categories),
    isActive: !fastLinkQuery,
  };
  const linksWithActive = links.map((link) => ({
    isActive: link.slug === fastLinkQuery,
    ...link,
  }));

  return [allCategoryLink, ...linksWithActive];
};

const notFastLinkQueryParams = [
  QueryParams.title,
  QueryParams.promoCode,
  FiltersSlugEnum.onlyMiniCourses,
  FiltersSlugEnum.subscription,
  FiltersSlugEnum.wb,
  FiltersSlugEnum.legalEntitiesPrice,
];

const getFastLinkSearch = (query?: ParsedUrlQuery): Nullable<string> => {
  if (!query) {
    return null;
  } else {
    const queryKeys = Object.keys(query);
    const searchQuery = notFastLinkQueryParams.reduce(
      (acc: ParsedUrlQuery, queryParam) => {
        if (queryKeys.some((key) => key === queryParam)) {
          acc[queryParam] = query[queryParam];
        }

        return acc;
      },
      {}
    );

    return !objectService.isEmptyObject(searchQuery)
      ? objectService.getQueryStringFromObject(searchQuery)
      : '';
  }
};

export const getFastLinkHref = (
  fastLink: string,
  path: string,
  query?: ParsedUrlQuery
): string => {
  const search = getFastLinkSearch(query);

  return urlService.normalizeUrl(
    `${path}/${fastLink}${!!search ? `?${search}` : ''}`
  );
};

export const allCategoryFastLink = (title: string): FastLinkModel => ({
  title,
  slug: '',
  options: null,
  isVisible: true,
});

export const getActiveLinkTitle = (
  links: FastLinkWithActiveModel[],
  fastLinkQuery: string
): string | null => {
  const activeLink = links.find((link) => link.slug === fastLinkQuery);

  return activeLink ? activeLink.title : null;
};

const excludeNotFastLinkParams = (query: ParsedUrlQuery): ParsedUrlQuery =>
  Object.keys(query).reduce((acc: ParsedUrlQuery, queryKey) => {
    if (notFastLinkQueryParams.every((key) => key !== queryKey)) {
      acc[queryKey] = query[queryKey];
    }

    return acc;
  }, {});

export const getCompositeFastLink = (
  fastLinks: FastLinkModel[],
  _query: ParsedUrlQuery
): Nullable<FastLinkModel> => {
  const query = excludeNotFastLinkParams(_query);

  return (
    fastLinks.find((fastLink) => {
      if (
        !fastLink.options ||
        Object.keys(fastLink.options).length !== Object.keys(query).length
      ) {
        return;
      }

      return Object.keys(query).every((key) => {
        const queryArray = (query[key] as string).split(',');
        const fastLinkOptions = fastLink.options?.[key as FiltersSlugEnum];

        if (fastLinkOptions?.length !== queryArray.length) {
          return false;
        }

        return queryArray.every((value: string) => {
          if (fastLinkOptions) {
            return fastLinkOptions.some((optionValue) => optionValue === value);
          }

          return false;
        });
      });
    }) || null
  );
};
