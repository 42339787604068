import { fontSettings, transition } from '@lerna-core';
import styled, { css } from 'styled-components';
import { ListEmptyStyledProps } from './listEmpty.model';

export const ListEmptyStyled = styled.div<ListEmptyStyledProps>`
  width: 100%;
  padding: 64px 16px;
  margin-bottom: 48px;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 16px;
  background: ${({ theme }): string => theme.COLOR.WHITE};

  & > * {
    ${transition('opacity')}
    ${({ isRouting }): ReturnType<typeof css> | string =>
      isRouting
        ? css`
            opacity: 0.4;
            pointer-events: none;
            user-select: none;
          `
        : ''}
  }
`;

export const TitleStyled = styled.div<{ hasSubtitle: boolean }>`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_24, theme.FONT)};
  max-width: 650px;
  font-weight: 600;
  margin-bottom: ${({ hasSubtitle }) => (hasSubtitle ? '16px' : '24px')};
`;

export const SubtitleStyled = styled.div`
  ${(props): string =>
    fontSettings(props.theme.FONT.SIZE_17, props.theme.FONT)};
  color: ${(props): string => props.theme.COLOR.TEXT_LIGHT_COLOR};
  margin-bottom: 24px;
  font-weight: 400;
`;
