import React, { ReactElement } from 'react';
import { useTranslations } from '@lerna-core';
import { ContentLeftStyled, TitleStyled } from './contentLeft.styled';
import { LeftSubscriptionInfoContainer } from './leftSubscriptionInfo';
import { LeftAdvantagesContainer } from './leftAdvantages';

export const ContentLeftContainer = (): ReactElement => {
  const translations = useTranslations();

  return (
    <ContentLeftStyled>
      <TitleStyled>
        {translations?.course_free_access_by_subscription}
      </TitleStyled>
      <LeftAdvantagesContainer />
      <LeftSubscriptionInfoContainer />
    </ContentLeftStyled>
  );
};
