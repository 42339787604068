import {
  BonusesModel,
  CurrencyModel,
  getBonusPointsLabel,
  Nullable,
  setPriceValue,
  TariffSlugEnum,
  TranslationModel,
  UserAggregateModel,
} from '@lerna-core';
import { EMAIL, telegramHref } from './bonusesPopup.meta';

export const canShowBonusesPopup = (
  user: Nullable<UserAggregateModel>
): boolean => {
  return (
    !!user?.career?.companyId &&
    !(
      user.career.isBonusPointsHiddenOnListing ||
      user.career.isPricesHiddenOnListingCards ||
      user.career.isWithoutPromoCards ||
      user.career.isPersonalDataFillOptional ||
      user.career.tariff?.slug === TariffSlugEnum.prime
    )
  );
};

export const getTelegramHref = (email: string) =>
  telegramHref.replace(EMAIL, email);

export const getBonusesPriceConversion = (
  bonuses: BonusesModel,
  translations: TranslationModel,
  currencies: CurrencyModel[]
): string =>
  `${getBonusPointsLabel(bonuses.bonusPriceConversion.points, [
    translations.points_label_1,
    translations.points_label_2,
    translations.points_label_many,
  ])} = ${setPriceValue(bonuses.bonusPriceConversion.money, currencies)}`;
