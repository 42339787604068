import React, { ReactElement, useMemo } from 'react';
import { PriceTitleContainerStyled } from './priceTitle.styled';
import { PriceTitleContainerProps } from './priceTitle.model';
import { useShowLegalEntitiesPriceHook } from '@hooks';
import { getCurrencies, setPriceValue, useTranslations } from '@lerna-core';
import { useSelector } from 'react-redux';

export const PriceTitleContainer = ({
  price,
  hasDiscount,
  isFreeCourse,
  isFreeCourseByDiscount,
}: PriceTitleContainerProps): ReactElement => {
  const translations = useTranslations();
  const currencies = useSelector(getCurrencies);
  const showLegalEntitiesPrice = useShowLegalEntitiesPriceHook();

  const lineThrough =
    (hasDiscount && !showLegalEntitiesPrice && !isFreeCourse) ||
    (isFreeCourseByDiscount && !showLegalEntitiesPrice);

  const title = useMemo(() => {
    if (showLegalEntitiesPrice) {
      return translations.legal_entities_price_label;
    }

    if (!hasDiscount || isFreeCourse) {
      return translations.item_price_label;
    }

    return setPriceValue(
      price.startPriceCustomer || price.startPrice,
      currencies
    );
  }, [showLegalEntitiesPrice]);

  return (
    <PriceTitleContainerStyled lineThrough={lineThrough}>
      {title}
    </PriceTitleContainerStyled>
  );
};
