import React, { ReactElement, useMemo } from 'react';
import { TopDocumentsContainerProps } from './topDocuments.model';
import { FooterTopLinksStyled } from '../footerTop.styled';
import { FooterLinkContainer } from '../../footerLink';
import { publicConfig, staticSrc, urlService } from '../../../../services';
import { Nullable, RegionsEnum } from '../../../../models';
import { useUser } from '../../../user';
import { FooterAnalyticsEvents } from '../../footer.analytics';
import { ManagementButtonContainer as CookieManagementButton } from '../../../cookiesNotification';
import { projectSubDomains } from '../../../../constants';
import { useTranslations } from '../../../translations';

export const TopDocumentsContainer = ({
  hasFastLinks,
  staticLinks,
}: TopDocumentsContainerProps): Nullable<ReactElement> => {
  const translations = useTranslations();
  const { user } = useUser();

  const hasDocs = useMemo(
    () =>
      Boolean(translations?.public_contract) ||
      Boolean(translations?.public_contract_org) ||
      Boolean(translations?.privacy_policy) ||
      Boolean(translations?.bonuses_rules) ||
      Boolean(translations?.terms_use) ||
      Boolean(translations?.user_policy) ||
      Boolean(translations?.software_policy) ||
      Boolean(translations?.cost_software_policy) ||
      Boolean(translations?.platform_usage_rules) ||
      Boolean(translations?.company_information) ||
      Boolean(translations?.consent_refusal) ||
      Boolean(staticLinks),
    []
  );

  const bonusesRulesStaticSrc = useMemo(
    () =>
      user?.career?.companyId ? staticSrc.b2bBonusesPdf : staticSrc.bonusesPdf,
    [user]
  );
  const bonusesRules = useMemo((): Nullable<ReactElement> => {
    const isB2BUser = user?.career?.companyId;
    const isRuRegion = publicConfig?.REGION === RegionsEnum.ru;
    const isHiddenBonusesRules = isRuRegion && !isB2BUser;

    if (
      !translations?.bonuses_rules ||
      isHiddenBonusesRules ||
      user?.career?.isListingHidden ||
      user?.career?.isListingWithBenefitsOnly
    ) {
      return null;
    }

    return (
      <FooterLinkContainer
        link={{
          link: urlService.getUrlToFile(bonusesRulesStaticSrc),
          label: translations?.bonuses_rules,
        }}
        analyticsEvent={FooterAnalyticsEvents.clickLinkBonusesRules}
        {...urlService.setTargetBlankOptions()}
      />
    );
  }, []);

  if (!hasDocs && !staticLinks) {
    return null;
  }

  return (
    <FooterTopLinksStyled hasFastLinks={hasFastLinks}>
      {Boolean(translations?.public_contract) && (
        <FooterLinkContainer
          link={{
            link: urlService.getUrlToFile(staticSrc.ofertaPdf),
            label: translations?.public_contract,
          }}
          analyticsEvent={FooterAnalyticsEvents.clickLinkOferta}
          {...urlService.setTargetBlankOptions()}
        />
      )}
      {Boolean(translations?.public_contract_org) && (
        <FooterLinkContainer
          link={{
            link: urlService.getUrlToFile(staticSrc.ofertaOrgPdf),
            label: translations?.public_contract_org,
          }}
          {...urlService.setTargetBlankOptions()}
        />
      )}
      {Boolean(translations?.privacy_policy) && (
        <FooterLinkContainer
          link={{
            link: urlService.getUrlToFile(staticSrc.privacyPdf),
            label: translations?.privacy_policy,
          }}
          analyticsEvent={FooterAnalyticsEvents.clickLinkPrivacyPolicy}
          {...urlService.setTargetBlankOptions()}
        />
      )}
      {bonusesRules}
      {Boolean(translations?.terms_use) && (
        <FooterLinkContainer
          link={{
            link: urlService.getUrlToFile(staticSrc.termsUsePdf),
            label: translations?.terms_use,
          }}
          {...urlService.setTargetBlankOptions()}
        />
      )}
      {Boolean(translations?.consent_refusal) && (
        <FooterLinkContainer
          link={{
            link: urlService.getFullUrlToSubDomain(
              projectSubDomains.corp,
              staticSrc.consentRefusal
            ),
            label: translations?.consent_refusal,
          }}
          {...urlService.setTargetBlankOptions()}
        />
      )}
      {Boolean(translations?.platform_usage_rules) && (
        <FooterLinkContainer
          link={{
            link: urlService.getUrlToFile(staticSrc.platformUsageRules),
            label: translations?.platform_usage_rules,
          }}
          analyticsEvent={FooterAnalyticsEvents.clickLinkPlatformUsage}
          {...urlService.setTargetBlankOptions()}
        />
      )}
      {Boolean(translations?.user_policy) && (
        <FooterLinkContainer
          link={{
            link: urlService.getUrlToFile(staticSrc.userPolicyPdf),
            label: translations?.user_policy,
          }}
          analyticsEvent={FooterAnalyticsEvents.clickLinkUserPolicy}
          {...urlService.setTargetBlankOptions()}
        />
      )}
      {Boolean(translations?.software_policy) && (
        <FooterLinkContainer
          link={{
            link: urlService.getUrlToFile(staticSrc.softwarePolicyPdf),
            label: translations?.software_policy,
          }}
          analyticsEvent={FooterAnalyticsEvents.clickLinkSoftwareSpecifications}
          {...urlService.setTargetBlankOptions()}
        />
      )}
      {Boolean(translations?.cost_software_policy) && (
        <FooterLinkContainer
          link={{
            link: urlService.getUrlToFile(staticSrc.costSoftwarePolicyPdf),
            label: translations?.cost_software_policy,
          }}
          analyticsEvent={FooterAnalyticsEvents.clickLinkSoftwareCost}
          {...urlService.setTargetBlankOptions()}
        />
      )}
      {Boolean(translations?.company_information) && (
        <FooterLinkContainer
          link={{
            link: urlService.getUrlToFile(staticSrc.companyInformationPdf),
            label: translations?.company_information,
          }}
          {...urlService.setTargetBlankOptions()}
        />
      )}
      {Boolean(translations?.promotion_rules) && (
        <FooterLinkContainer
          link={{
            link: urlService.getUrlToFile(staticSrc.promotionRulesPdf),
            label: translations?.promotion_rules,
          }}
          {...urlService.setTargetBlankOptions()}
        />
      )}
      {!!staticLinks &&
        staticLinks.map(
          (link) =>
            Boolean(link.label) && (
              <FooterLinkContainer
                key={link.link}
                link={{
                  link: urlService.normalizeUrl(link.link),
                  label: link.label,
                }}
                {...urlService.setTargetBlankOptions()}
              />
            )
        )}
      {translations.cookie_settings && (
        <CookieManagementButton title={translations.cookie_settings} />
      )}
    </FooterTopLinksStyled>
  );
};
