import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  FilterModel,
  FilterOptionModel,
  filterOptionsByTerm,
  FiltersSlugEnum,
  FilterTypes,
  Nullable,
  useTranslations,
} from '@lerna-core';
import { FilterDescriptionContainer } from '../filterDescription';
import { FilterOption } from '../filterOption';
import { FilterTitleContainer } from '../filterTitle';
import {
  FilterOptionsContainerTypes,
  filtersWithSearch,
  optionsCollapseLimit,
  optionsSearchLimit,
} from './filterOptions.meta';
import {
  FilterOptionsEmptySubTitleStyled,
  FilterOptionsEmptyTitleStyled,
  FilterOptionsItemsStyled,
  FilterOptionsStyled,
} from './filterOptions.styled';
import { FilterOptionsSearchContainer } from './filterOptionsSearch';
import { FilterOptionsShowAllContainer } from './filterOptionsShowAll';

const getFilterOptions = (
  options: FilterOptionModel[],
  filter: FilterModel,
  showAll: boolean,
  defaultPathname: string,
  optionCheckedColor?: Nullable<string>,
  analyticsType?: keyof typeof FilterTypes
): ReactElement[] => {
  const visibleOptions = showAll
    ? options
    : options.slice(0, optionsCollapseLimit);

  return visibleOptions.map((option) => (
    <FilterOption
      key={option.slug}
      option={option}
      filter={filter}
      defaultPathname={defaultPathname}
      optionCheckedColor={optionCheckedColor}
      analyticsType={analyticsType}
    />
  ));
};

export const FilterOptionsContainer = ({
  filter,
  withTitle,
  withDescription = true,
  withSearch,
  isCollapsed,
  withTooltip,
  defaultPathname,
  searchColors,
  icon,
  filterColor,
  analyticsType,
}: FilterOptionsContainerTypes): Nullable<ReactElement> => {
  const translations = useTranslations();
  const optionsRef = useRef<Nullable<HTMLDivElement>>(null);
  const [term, setTerm] = useState<string>('');
  const [options, setOptions] = useState<FilterOptionModel[]>(filter.options);
  const [showAllFilterOptions, setShowAllFilterOptions] = useState<boolean>(
    !isCollapsed
  );

  useEffect(() => {
    const filteredOptions = filterOptionsByTerm(term, filter.options);
    setOptions(filteredOptions);
  }, [term, filter]);

  const hasDescription =
    withDescription && !!filter.description && !withTooltip;

  const handleShowAll = (): void => {
    setShowAllFilterOptions(!showAllFilterOptions);
  };

  const checkShowSearch = (): boolean | undefined =>
    filtersWithSearch.includes(filter.slug as FiltersSlugEnum) &&
    withSearch &&
    showAllFilterOptions &&
    filter.options.length > optionsSearchLimit;

  if (!filter.isVisible) return null;

  return (
    <FilterOptionsStyled data-instance="option">
      {withTitle && (
        <FilterTitleContainer
          title={filter.title}
          withTooltip={withTooltip}
          tooltip={filter.description}
          icon={icon}
        />
      )}
      {hasDescription && (
        <FilterDescriptionContainer text={filter.description} />
      )}
      {checkShowSearch() && (
        <FilterOptionsSearchContainer
          searchColors={searchColors}
          onChange={setTerm}
          placeholder={filter.title}
          term={term}
        />
      )}
      <FilterOptionsItemsStyled
        ref={optionsRef}
        bordered={showAllFilterOptions}
        showAllFilterOptions={showAllFilterOptions}
        isEmpty={!options.length}
      >
        {options.length ? (
          <>
            {getFilterOptions(
              options,
              filter,
              showAllFilterOptions,
              defaultPathname,
              filterColor,
              analyticsType
            )}
          </>
        ) : (
          <>
            <FilterOptionsEmptyTitleStyled>
              {translations?.filter_options_empty_title}
            </FilterOptionsEmptyTitleStyled>
            <FilterOptionsEmptySubTitleStyled>
              {translations?.filter_options_empty_sub_title}
            </FilterOptionsEmptySubTitleStyled>
          </>
        )}
      </FilterOptionsItemsStyled>
      {isCollapsed && (
        <FilterOptionsShowAllContainer
          handleClick={handleShowAll}
          showAllFilterOptions={showAllFilterOptions}
          optionsLength={options.length}
          filterColor={filterColor}
        />
      )}
    </FilterOptionsStyled>
  );
};
