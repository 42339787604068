import { CourseModel, getCourseVendorById, VendorModel } from '@lerna-core';

export const getAdvantages = (
  course: CourseModel,
  vendors: VendorModel[]
): string[] => {
  const vendor = getCourseVendorById(vendors, course.vendorUuid);
  const advantages = [];
  //todo: Вынести цены в хук формы
  const programDiscount =
    course?.price?.priceWithProgramDiscount?.discount?.amount;
  const hasProgramDiscount = Boolean(
    programDiscount && parseInt(programDiscount)
  );

  if (
    course.uniqueSellingPropositions &&
    !course.isFreeByVendor &&
    !course.isFreeByVendorDiscount
  ) {
    advantages.push(...course.uniqueSellingPropositions);
  }
  if (course.isFreeByVendor && !hasProgramDiscount) {
    const baseValue = 'Бесплатный курс';
    const freeCourseAdvantage = vendor?.title
      ? `${baseValue} от ${vendor.title}`
      : baseValue;

    advantages.push(freeCourseAdvantage);
  }
  if (course.isFreeByVendorDiscount && !hasProgramDiscount) {
    const baseValue = `Курс со скидкой ${course?.price?.price?.discount?.value}`;
    const freeCourseByDiscountAdvantage = vendor?.title
      ? `${baseValue} от ${vendor.title}`
      : baseValue;

    advantages.push(freeCourseByDiscountAdvantage);
  }

  return advantages;
};
