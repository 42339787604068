import { CourseModel, Nullable, pushDataLayer, VendorModel } from '@lerna-core';
import { getCourseEventData } from '@features/course';

export const EVENT_TYPE = '{{type}}';
export const EVENT_VALUE = '{{value}}';

export enum OrderFormEventsValues {
  consultation = 'consultation',
  freeCourse = 'free_course',
  paidCourse = 'paid_course',
  withTabs = 'with_tabs',
  noTabs = 'no_tabs',
  priceNull = 'price_null',
  discount100 = 'discount_100',
  discountPoints = 'discount_points',
  granulePaid = 'granule_paid',
  granuleFree = 'granule_free',
  granuleSubscription = 'granule_subscription',
  subscription = 'subscription',
  paymentOnline = 'payment_online',
  default = 'default',
  noHomework = 'no_homework',
  withHomework = 'with_homework',
  freeCompensation = 'free_compensation',
  addOnCompensation = 'add-on_compensation',
  withCompensation = 'with_compensation',
}

export enum OrderFormEvents {
  formEnrollClick = 'click_button_enroll course',
  formEnrollOpen = 'form_enroll course_open',
  formEnrollClose = 'form_enroll course_close',
  formEnrollPromoCode = 'form_enroll course_add_promocode',
  formEnrollPromoCodeError = 'form_enroll course_add_promocode_error',
  formEnrollStatusValue = 'form_enroll course_submit_{{type}}:{{value}}',
  formEnrollSelectSelfPay = 'form_enroll course_click_personal_payment:{{value}}',
  formEnrollSelectCompanyPay = 'form_enroll course_click_company_payment:{{value}}',
  formEnrollSelectConsultation = 'form_enroll course_select_request_for_consultation',
  formEnrollSelectOnlinePay = 'form_enroll course_select_request_for_payment',
  formEnrollGoToOnlinePay = 'form_enroll course_go_to_payment:{{value}}',
  clickButtonMyCourses = 'click_button_my_courses',
  formEnrollLegalName = 'form_enroll course_add_legal_name',
  formEnrollMoreDetails = 'form_enroll course_more_details',
  formEnrollCourseClickButtonNext = 'form_enroll course_click_next:{{value}}',
  formEnrollCourseAddUnrequiredMotivationLetter = 'form_enroll course_add_unrequired_motivation_letter',
  formEnrollCourseAddRequiredMotivationLetter = 'form_enroll course_add_required_motivation_letter',
  formEnrollCourseSkipUnrequiredMotivationLetter = 'form_enroll course_skip_unrequired_motivation_letter',
  formEnrollCourseCloseMotivationLetter = 'form_enroll course_close_motivation_letter',
  formEnrollCommentError = 'form_enroll course_motivation_letter_error',
  formEnrollCourseClickAddOnCompensation = 'form_enroll course_click_add-on_compensation',
  formEnrollCourseOpenPromptAboutMotivation = 'form_enroll course_open_prompt_about_motivation',
}

export enum OrderFormRequestTypes {
  b2b = 'b2b',
  b2c = 'b2c',
}

export const orderFormPushDataLayer = (
  eventName: string,
  eventType: string,
  eventPosition: string,
  course: Nullable<CourseModel>,
  vendors: VendorModel[],
  courseIndex?: Nullable<number>,
  orderId?: string,
  requestType?: string
): void => {
  if (course) {
    pushDataLayer(
      getCourseEventData(
        eventName,
        eventType,
        eventPosition,
        course,
        vendors,
        courseIndex,
        orderId,
        requestType
      )
    );
  }
};

export const orderFormEvent = (
  eventName: string,
  eventNameOptions: OrderFormEventsValues[],
  eventType: string,
  eventPosition: string,
  course: Nullable<CourseModel>,
  vendors: VendorModel[],
  courseIndex?: Nullable<number>,
  orderId?: string,
  requestType?: string
): void => {
  orderFormPushDataLayer(
    eventName.replace(EVENT_VALUE, eventNameOptions.join(',')),
    eventType,
    eventPosition,
    course,
    vendors,
    courseIndex,
    orderId,
    requestType
  );
};
