import {
  CatalogPageTypes,
  checkUserRole,
  CompanyUuidEnum,
  MenuSwitcherEvents,
  MenuSwitcherLinkModel,
  Nullable,
  personalPath,
  projectSubDomains,
  TranslationModel,
  urlService,
  UserAggregateModel,
  UserRole,
} from '@lerna-core';
import { pageUrls } from '@constants';
import { getHeaderEventPositionByPageName } from '../header.analytics';

const getMenuSwitcherLinks = (
  translations: TranslationModel,
  coursesCount = 0,
  pageName: Nullable<CatalogPageTypes>
) => ({
  courses: {
    title: translations.catalog,
    href: urlService.getFullUrlFromToPage(pageUrls.courses),
    isActive: pageName === CatalogPageTypes.courses,
    analyticsEvent: MenuSwitcherEvents.goToCatalog,
    analyticsEventPosition: getHeaderEventPositionByPageName(pageName),
  },
  hr: {
    title: translations.hr_cabinet,
    href: urlService.getFullUrlToSubDomain(
      projectSubDomains.hr,
      personalPath.customers
    ),
    isActive: false,
    analyticsEvent: MenuSwitcherEvents.goToHR,
    analyticsEventPosition: getHeaderEventPositionByPageName(pageName),
  },
  customer: {
    title: translations.my_education,
    count: coursesCount,
    href: urlService.getFullUrlToSubDomain(
      projectSubDomains.personal,
      personalPath.courses
    ),
    isActive: false,
    analyticsEvent: MenuSwitcherEvents.goToPersonal,
    analyticsEventPosition: getHeaderEventPositionByPageName(pageName),
  },
});

export const getHeaderDesktopTopLinks = (
  user: Nullable<UserAggregateModel>,
  userCoursesCount: number,
  translations: TranslationModel,
  pageName: Nullable<CatalogPageTypes>
): MenuSwitcherLinkModel[] => {
  const isManager = checkUserRole(UserRole.manager, user?.career);
  const { courses, hr, customer } = getMenuSwitcherLinks(
    translations,
    userCoursesCount,
    pageName
  );

  switch (true) {
    case user?.career?.companyId === CompanyUuidEnum.wildberries:
      return [customer];
    case isManager: {
      return [courses, hr, customer];
    }
    default:
      return [courses, customer];
  }
};
