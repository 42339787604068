import React, { ReactElement, useContext, useEffect, useState } from 'react';
import {
  clearAcademikaCoursesVendor,
  CourseModel,
  CoursesModel,
  logger,
  MetaPaginationModel,
  Nullable,
  useUser,
} from '@lerna-core';
import { CoursesContextModel, CoursesProviderProps } from './courses.model';
import { coursesClientProvider } from './courses.clientProvider';
import { useRouter } from 'next/router';

const CoursesContext = React.createContext<Nullable<CoursesContextModel>>(null);

export const CoursesContextProvider = ({
  children,
  ...props
}: CoursesProviderProps): ReactElement => {
  const router = useRouter();
  const { user } = useUser();

  const [courses, setCourses] = useState<{
    data: CourseModel[];
    meta: MetaPaginationModel;
  }>({
    data: clearAcademikaCoursesVendor(props.courses.data),
    meta: props.courses.meta,
  });

  const handleSetCourses = (courses: CoursesModel) => {
    setCourses({
      data: clearAcademikaCoursesVendor(courses.data),
      meta: courses.meta,
    });
  };

  const requestCourses = async (requestParams = {}): Promise<void> => {
    return coursesClientProvider(
      {
        ...router.query,
        ...requestParams,
      },
      user
    )
      .then((courses) => {
        handleSetCourses(courses);
      })
      .catch((error) => {
        logger.error('[ERROR]: can not get courses', error);
      });
  };

  const requestMoreCourses = async (requestParams = {}): Promise<void> => {
    return coursesClientProvider(
      {
        ...router.query,
        ...requestParams,
      },
      user
    )
      .then((response) => {
        handleSetCourses({
          data: [...courses.data, ...response.data],
          meta: response.meta,
        });
      })
      .catch((error) => {
        logger.error('[ERROR]: can not get more courses', error);
      });
  };

  useEffect(() => {
    handleSetCourses({
      data: clearAcademikaCoursesVendor(props.courses.data),
      meta: props.courses.meta,
    });
  }, [props.courses]);

  const context = {
    courses,
    requestCourses,
    requestMoreCourses,
  };

  return (
    <CoursesContext.Provider value={context}>
      {children}
    </CoursesContext.Provider>
  );
};

export const useCoursesContext = (): CoursesContextModel => {
  const value = useContext(CoursesContext);

  if (!value) {
    logger.error(
      '[ERROR]: CoursesContext: you cannot use context without a provider'
    );

    return {} as CoursesContextModel;
  }

  return value;
};
