import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { SearchAllocatedContainerProps } from './searchAllocated.model';
import {
  requestTransferCoursesAllocated,
  setTransferCoursesAllocatedFilters,
} from '../../coursesTransfer.actions';
import { TableSearchContainer } from '../../../../table';
import { useSearch } from '../../../../../hooks';
import {
  getCoursesTransferAllocatedFiltersSelector,
  getCoursesTransferSelector,
} from '../../coursesTransfer.selectors';
import { useCoursesTransferContext } from '../../coursesTransfer.context';
import {
  getSearchAllocatedMinLength,
  getSearchAllocatedPlaceholder,
} from './searchAllocated.helper';

export const SearchAllocatedContainer = ({
  translations,
  handleSearchCancel,
}: SearchAllocatedContainerProps): ReactElement => {
  const { id, coursesProvider, dispatch, courseExclusionCompanyId } =
    useCoursesTransferContext();
  const {
    added,
    removed,
    allocated: { filters },
  } = useSelector(getCoursesTransferSelector);
  const { search: initialSearch, searchTerm } = useSelector(
    getCoursesTransferAllocatedFiltersSelector
  );

  const onSearch = (search: string): void => {
    dispatch(setTransferCoursesAllocatedFilters({ search }));
    dispatch(
      requestTransferCoursesAllocated(coursesProvider, {
        ...filters,
        id,
        relatedWithCourseExclusionsForCompanyId: courseExclusionCompanyId,
        added,
        removed,
        search,
        searchTerm,
      })
    );
  };

  const { search, setSearch } = useSearch({
    onSearch,
    initialSearch,
    minSearchLength: getSearchAllocatedMinLength(searchTerm),
  });

  return (
    <TableSearchContainer
      search={search}
      placeholder={getSearchAllocatedPlaceholder(translations, searchTerm)}
      onChange={setSearch}
      cancelText={translations.cancel}
      onCancelClick={handleSearchCancel}
    />
  );
};
