import React, { ReactElement } from 'react';
import { FiltersPopupDesktopContainerStyled } from './filtersPopupDesktop.styled';
import { FiltersPopupDesktopContainerProps } from './filtersPopupDesktop.model';
import { FiltersModel } from '@lerna-core';
import { DesktopFilterContainer } from './desktopFilter';

export const FiltersPopupDesktopContainer = ({
  filters,
  customFilterColor,
}: FiltersPopupDesktopContainerProps): ReactElement => {
  return (
    <FiltersPopupDesktopContainerStyled>
      {filters.map(
        (filter: FiltersModel): ReactElement => (
          <DesktopFilterContainer
            key={filter.slug}
            filter={filter}
            customFilterColor={customFilterColor}
          />
        )
      )}
    </FiltersPopupDesktopContainerStyled>
  );
};
