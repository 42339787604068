import styled from 'styled-components';

import { ITheme } from '../../themes';

const getIconActiveStyles = (theme: ITheme, selected: boolean): string =>
  selected
    ? `
  &::before {
    content: '';
    position: absolute;
    right: -1px;
    top: -1px;
    width: 5px;
    height: 5px;
    background: ${theme.COLOR.RED};
    border: 1px solid ${theme.COLOR.WHITE};
    border-radius: 50%;
  }
  `
    : '';

export const TableDateFilterStyled = styled.div`
  cursor: pointer;
  position: relative;
  max-width: max-content;
`;

export const TableFilterButtonStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const TableFilterIconStyled = styled.div<{ selected: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  margin-left: 8px;
  margin-top: 2px;

  ${({ theme, selected }): string => getIconActiveStyles(theme, selected)}
`;
