import React, { ReactElement } from 'react';
import {
  ButtonColor,
  ButtonContainer,
  Nullable,
  useTranslations,
} from '@lerna-core';
import {
  SubscriptionBadgeContainer,
  SubscriptionBadgeStatuses,
} from '../subscriptionBadge';
import { AgreementStyled, ButtonStyled } from './subscriptionButtons.styled';
import { getSubscriptionButtonsMeta } from './subscriptionButtons.meta';
import { SubscriptionButtonsModel } from './subscriptionButtons.model';

export const SubscriptionButtonsContainer = ({
  subscriptionAvailability,
  handleSignUp,
  isSignUpDisabled,
}: SubscriptionButtonsModel): Nullable<ReactElement> => {
  const translations = useTranslations();

  if (subscriptionAvailability?.isCourseClaimed) {
    return (
      <SubscriptionBadgeContainer
        {...getSubscriptionButtonsMeta(
          SubscriptionBadgeStatuses.success,
          translations,
          subscriptionAvailability.coursesClaimed,
          subscriptionAvailability.subscriptionAccess.subscription.title,
          subscriptionAvailability.nextClaimPeriodStart
        )}
      />
    );
  }

  if (subscriptionAvailability?.isClaimAvailable) {
    return (
      <>
        <ButtonStyled>
          <ButtonContainer
            onClick={handleSignUp}
            color={ButtonColor.purple}
            borderRadius={8}
            title={translations?.sign_up_course_button_title}
            disabled={isSignUpDisabled}
          />
        </ButtonStyled>
        <AgreementStyled
          dangerouslySetInnerHTML={{
            __html: translations?.public_contract_text,
          }}
        />
      </>
    );
  }

  if (subscriptionAvailability) {
    return (
      <SubscriptionBadgeContainer
        {...getSubscriptionButtonsMeta(
          SubscriptionBadgeStatuses.warn,
          translations,
          subscriptionAvailability.coursesClaimed,
          subscriptionAvailability.subscriptionAccess.subscription.title,
          subscriptionAvailability.nextClaimPeriodStart
        )}
      />
    );
  }

  return null;
};
