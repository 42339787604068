import React, { ReactElement } from 'react';

import { InfoLabelContainer } from './infoLabel';
import { UserAvatarContainer, UserAvatarSize, useUser } from '../../../../user';
import {
  UserInfoAvatarStyled,
  UserInfoNameStyled,
  UserInfoStyled,
  UserInfoWrapperStyled,
} from './userInfo.styled';
import { Nullable } from '../../../../../models';
import { getUserName } from './userInfo.helpers';
import { useTranslations } from '../../../../translations';

export const UserInfoContainer = (): Nullable<ReactElement> => {
  const translations = useTranslations();
  const { user } = useUser();

  if (!user) return null;

  const userName = getUserName(user.personal) || translations.user_profile;

  return (
    <UserInfoStyled>
      <UserInfoAvatarStyled>
        <UserAvatarContainer
          size={UserAvatarSize.big}
          personal={user.personal}
          isRound={true}
        />
      </UserInfoAvatarStyled>
      <UserInfoWrapperStyled>
        <UserInfoNameStyled>{userName}</UserInfoNameStyled>
        <InfoLabelContainer career={user.career} bonuses={user.bonuses} />
      </UserInfoWrapperStyled>
    </UserInfoStyled>
  );
};
