import { createSelector } from 'reselect';

import { RootState } from '../../../index';
import { UserState } from './user.model';

export const getUser = createSelector(
  (state: RootState<UserState>) => state.user,
  (reducer: UserState) => reducer.user
);

export const getUserStatus = createSelector(
  (state: RootState<UserState>) => state.user,
  (reducer: UserState) => reducer.status
);

export const getUserCareer = createSelector(
  (state: RootState<UserState>) => state.user,
  (reducer: UserState) => reducer.user?.career
);

export const getUserCoursesCount = createSelector(
  (state: RootState<UserState>) => state.user,
  (reducer: UserState) => {
    if (reducer.user?.courses) {
      const { grantAccesses, courses } = reducer.user?.courses;

      return (
        courses.corporate.length +
        courses.personal.length +
        grantAccesses.personal.length +
        grantAccesses.corporate.length
      );
    }

    return 0;
  }
);
