import { ReactElement } from 'react';
import { PricesCustomerContainer } from './pricesСustomer';
import { PricesB2B2CContainer } from './pricesB2B2C';
import { CourseModel } from '@lerna-core';
import { UserOrderFormPaymentContentTypes } from '../../../../userOrderForm.model';

export const getPricesContent = (
  paymentContent: UserOrderFormPaymentContentTypes,
  course: CourseModel,
  isLernaPrime?: boolean
): ReactElement => {
  const Price = course.price?.priceCustomerB2B2C
    ? PricesB2B2CContainer
    : PricesCustomerContainer;

  switch (paymentContent) {
    case UserOrderFormPaymentContentTypes.onlinePay:
      return (
        <Price
          course={course}
          withInstallment={false}
          isLernaPrime={isLernaPrime}
        />
      );
    case UserOrderFormPaymentContentTypes.consultation:
    default:
      return <Price course={course} isLernaPrime={isLernaPrime} />;
  }
};
