import React, { ReactElement, useMemo } from 'react';
import { PointsBoxMediumContainerProps } from './pointsBoxMedium.model';
import { getBonusPointsLabel } from '../user';
import {
  PointsBoxMediumLabelStyled,
  PointsBoxMediumLegendStyled,
  PointsBoxMediumStyled,
  PointsBoxMediumValueStyled,
} from './pointsBoxMedium.styled';
import { setPriceValue } from '../../adapters';
import { useSelector } from 'react-redux';
import { getCurrencies } from '../currencies';
import { useTranslations } from '../translations';

export const PointsBoxMediumContainer = ({
  bonuses,
}: PointsBoxMediumContainerProps): ReactElement | null => {
  const currencies = useSelector(getCurrencies);
  const translations = useTranslations();
  const bonusLabel = useMemo(() => {
    if (!bonuses) {
      return null;
    }
    return getBonusPointsLabel(bonuses.points, [
      translations.points_label_1,
      translations.points_label_2,
      translations.points_label_many,
    ]);
  }, [bonuses?.points]);

  const bonusPriceLabel = useMemo(() => {
    if (!bonuses) {
      return null;
    }
    return `${getBonusPointsLabel(bonuses.bonusPriceConversion.points, [
      translations.points_label_1,
      translations.points_label_2,
      translations.points_label_many,
    ])} = ${setPriceValue(bonuses.bonusPriceConversion.money, currencies)}`;
  }, []);

  if (!bonuses) {
    return null;
  }

  return (
    <PointsBoxMediumStyled>
      <PointsBoxMediumLabelStyled>
        {translations.banner_bonuses_points_label}:
      </PointsBoxMediumLabelStyled>
      <PointsBoxMediumValueStyled>{bonusLabel}</PointsBoxMediumValueStyled>
      <PointsBoxMediumLegendStyled>
        {bonusPriceLabel}
      </PointsBoxMediumLegendStyled>
    </PointsBoxMediumStyled>
  );
};
