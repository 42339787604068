import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';

import { SphereAllocatedFilterContainerProps } from './sphereUnallocatedFilter.model';
import {
  requestTransferCoursesUnallocated,
  setTransferCoursesUnallocatedFilters,
} from '../../coursesTransfer.actions';
import { useCoursesTransferContext } from '../../coursesTransfer.context';
import { getCoursesTransferSelector } from '../../coursesTransfer.selectors';
import { getCoursesFilters } from '../../../coursesFilters';
import { TableFilterContainer } from '../../../../table';

export const SphereUnallocatedFilterContainer = ({
  translations,
}: SphereAllocatedFilterContainerProps): ReactElement => {
  const { id, coursesProvider, courseExclusionCompanyId, dispatch } =
    useCoursesTransferContext();
  const {
    added,
    removed,
    unallocated: { filters },
  } = useSelector(getCoursesTransferSelector);
  const { sphere: filterOptions } = useSelector(getCoursesFilters);

  const handleApplyFilter = (sphere: string[]): void => {
    dispatch(setTransferCoursesUnallocatedFilters({ sphere }));
  };
  const handleRequest = (sphere: string[]): void => {
    dispatch(
      requestTransferCoursesUnallocated(coursesProvider, {
        ...filters,
        removed,
        added,
        id,
        sphere,
        notRelatedWithCourseExclusionsForCompanyId: courseExclusionCompanyId,
      })
    );
  };

  return (
    <TableFilterContainer
      filterOptions={filterOptions}
      filters={filters.sphere}
      handleApplyFilter={handleApplyFilter}
      handleRequest={handleRequest}
      translations={translations}
      searchPlaceholder={translations.search}
      buttonTitle={translations.sphere}
    />
  );
};
