import React, { ReactElement } from 'react';
import { GalleryItemContainerProps } from './galleryItem.model';
import { GalleryItemContainerStyled } from './galleryItem.styled';
import {
  SimilarItemContainer,
  SimilarItemEmptyContainer,
} from '../../courseSimilar';

export const GalleryItemContainer = ({
  course,
  itemWidth,
  spaceBetweenItems,
  isMobile,
  isPricesHiddenOnListingCards,
}: GalleryItemContainerProps): ReactElement => {
  return (
    <GalleryItemContainerStyled
      itemWidth={itemWidth}
      spaceBetweenItems={spaceBetweenItems}
      isMobile={isMobile}
    >
      {course ? (
        <SimilarItemContainer
          course={course}
          isPricesHiddenOnListingCards={isPricesHiddenOnListingCards}
        />
      ) : (
        <SimilarItemEmptyContainer />
      )}
    </GalleryItemContainerStyled>
  );
};
