import React, { ReactElement, useCallback } from 'react';
import {
  AgreementStyled,
  ButtonStyled,
  ContentRightStyled,
} from './contentRight.styled';
import {
  ButtonColor,
  ButtonContainer,
  CommonEventTypes,
  getVendors,
  useTranslations,
} from '@lerna-core';
import { ContentRightContainerProps } from './contentRight.model';
import { RightHeadContainer } from './rightHead';
import { RightDescriptionContainer } from './rightDescription';
import {
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';
import { useSelector } from 'react-redux';

export const ContentRightContainer = ({
  formGranuleData,
}: ContentRightContainerProps): ReactElement => {
  const translations = useTranslations();
  const vendors = useSelector(getVendors);
  const { handleSubmit, isSubmitting, course, isMobile, eventPosition } =
    formGranuleData;

  const handleDescriptionCollapseClick = useCallback(() => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollMoreDetails,
      CommonEventTypes.coursesEvents,
      eventPosition,
      course,
      vendors
    );
  }, []);

  return (
    <ContentRightStyled>
      <RightHeadContainer course={course} isMobile={isMobile} />
      {course.description && (
        <RightDescriptionContainer
          description={course.description}
          isMobile={isMobile}
          handleCollapseClick={handleDescriptionCollapseClick}
        />
      )}
      <ButtonStyled>
        <ButtonContainer
          onClick={handleSubmit}
          color={ButtonColor.purple}
          borderRadius={8}
          title={translations?.sign_up_course_button_title}
          disabled={isSubmitting}
        />
      </ButtonStyled>
      <AgreementStyled
        dangerouslySetInnerHTML={{ __html: translations?.public_contract_text }}
      />
    </ContentRightStyled>
  );
};
