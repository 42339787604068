import {
  CoursesModel,
  coursesResponseAdapter,
  CoursesResponseApiModel,
  mapShowcaseDefaultRequestProps,
  Nullable,
  publicConfig,
  QueryParams,
  RequestClient,
  RequestHeader,
  serverConfig,
  ShowcaseAggregatePathEnum,
  ShowcaseApiModel,
  ShowcaseErrorModel,
  ShowcaseRequestProps,
  UserAggregateModel,
} from '@lerna-core';
import { CoursesPageModel } from './courses.model';
import { coursesAdapter } from './courses.adapter';
import { getCoursesRequestPath } from './courses.helper';

export class CoursesPageClient extends RequestClient<
  ShowcaseRequestProps,
  ShowcaseApiModel,
  CoursesPageModel | ShowcaseErrorModel
> {
  protected path = `${ShowcaseAggregatePathEnum.aggregate}`;
  protected requestParameters: ShowcaseRequestProps;
  protected adapter = coursesAdapter;

  constructor(
    params: ShowcaseRequestProps,
    headers?: RequestHeader,
    user?: UserAggregateModel
  ) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      headers
    );
    const token = user?.career?.companyId;
    this.requestParameters = mapShowcaseDefaultRequestProps(params);
    if (!!user) {
      if (!!token) {
        this.path = `${ShowcaseAggregatePathEnum.aggregateAuthWithToken}/${token}`;
      } else {
        this.path = ShowcaseAggregatePathEnum.aggregateAuth;
      }
    }
  }
}

export class CoursesPageLazyClient extends CoursesPageClient {
  protected path = '';
  protected requestParameters: ShowcaseRequestProps;
  protected adapter = coursesAdapter;

  constructor(path: string, headers?: RequestHeader) {
    super({}, headers);
    this.apiUrl = '';
    this.path = path;
    this.requestParameters = {};
  }
}

export class CoursesClient extends RequestClient<
  ShowcaseRequestProps,
  CoursesResponseApiModel,
  CoursesModel
> {
  protected path = '';
  protected requestParameters: ShowcaseRequestProps;
  protected adapter = coursesResponseAdapter;

  constructor(
    params: ShowcaseRequestProps,
    user: Nullable<UserAggregateModel>,
    requestHeaders?: RequestHeader
  ) {
    super(
      publicConfig.API_GATEWAY_URL,
      serverConfig?.PRIVATE_API_GATEWAY_INTERNAL_URL,
      requestHeaders
    );

    this.requestParameters = mapShowcaseDefaultRequestProps(params);
    this.path = getCoursesRequestPath(
      user,
      params?.[QueryParams.discountProgram] as string
    );
  }
}
