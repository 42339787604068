import { Nullable } from '../../models';
import { CompanyUuidEnum } from '../company';
import { ITheme } from '../themes';
import { UserAggregateModel } from '../user';
import { IframeInitiator } from './iframe.model';

export const getIframeTheme = (
  theme: ITheme,
  iframeInitiator: Nullable<IframeInitiator>,
  user: Nullable<UserAggregateModel>
): ITheme => {
  switch (iframeInitiator) {
    case IframeInitiator.edstein:
      return getEdsteinTheme(theme, user);
    case IframeInitiator.finincubator:
      return getFinincubatorTheme(theme);
    default:
      return theme;
  }
};

export const getEdsteinTheme = (
  defaultTheme: ITheme,
  user: Nullable<UserAggregateModel>
): ITheme => {
  const companyId = user?.career?.companyId;
  switch (true) {
    case companyId === CompanyUuidEnum.cdek:
      return {
        ...defaultTheme,
        COLOR: {
          ...defaultTheme.COLOR,
          PURPLE: '#3F9497',
          PURPLE_SUBSCRIPTION: '#3F9497',
          PRIMARY: {
            ...defaultTheme.COLOR.PRIMARY,
            500: '#3F9497',
          },
        },
      };
    case companyId === CompanyUuidEnum.severstal:
      return {
        ...defaultTheme,
        COLOR: {
          ...defaultTheme.COLOR,
          PURPLE: '#002F6C',
          PURPLE_SUBSCRIPTION: '#002F6C',
          PRIMARY: {
            ...defaultTheme.COLOR.PRIMARY,
            500: '#002F6C',
          },
        },
      };
    case companyId === CompanyUuidEnum.uzumMarket:
      return {
        ...defaultTheme,
        COLOR: {
          ...defaultTheme.COLOR,
          PURPLE: '#7000FF',
          PURPLE_SUBSCRIPTION: '#7000FF',
          PRIMARY: {
            ...defaultTheme.COLOR.PRIMARY,
            500: '#7000FF',
          },
        },
      };
    case companyId === CompanyUuidEnum.ifcm:
      return {
        ...defaultTheme,
        COLOR: {
          ...defaultTheme.COLOR,
          PURPLE: '#2D2A5C',
          PURPLE_SUBSCRIPTION: '#2D2A5C',
          PRIMARY: {
            ...defaultTheme.COLOR.PRIMARY,
            500: '#2D2A5C',
          },
        },
      };
    default:
      return defaultTheme;
  }
};

export const getFinincubatorTheme = (defaultTheme: ITheme): ITheme => ({
  ...defaultTheme,
  COLOR: {
    ...defaultTheme.COLOR,
    PURPLE: '#435BBE',
    PURPLE_SUBSCRIPTION: '#435BBE',
    PRIMARY: {
      ...defaultTheme.COLOR.PRIMARY,
      500: '#435BBE',
    },
  },
});
