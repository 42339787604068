import React, { ReactElement } from 'react';
import {
  FooterTopLeftColumnStyled,
  FooterTopRightColumnStyled,
  FooterTopStyled,
  FooterTopTextStyled,
  FooterTopTextWrapperStyled,
} from './footerTop.styled';
import { FooterTopContainerProps } from './footerTop.model';
import { FooterFastLinksContainer } from '../footerFastLinks';
import { TopDocumentsContainer } from './topDocuments';
import { TopContactsContainer } from './topContacts';
import { useTranslations } from '../../translations';

export const FooterTopContainer = ({
  fastLinks,
  staticLinks,
  socialLinks,
}: FooterTopContainerProps): ReactElement => {
  const translations = useTranslations();
  const hasFastLinks = Boolean(fastLinks?.length);

  return (
    <FooterTopStyled hasFastLinks={hasFastLinks}>
      <FooterTopLeftColumnStyled>
        <TopContactsContainer
          socialLinks={socialLinks}
          hasFastLinks={hasFastLinks}
        />
        <TopDocumentsContainer
          hasFastLinks={hasFastLinks}
          staticLinks={staticLinks}
        />
      </FooterTopLeftColumnStyled>
      {hasFastLinks && <FooterFastLinksContainer fastLinks={fastLinks || []} />}
      <FooterTopRightColumnStyled hasFastLinks={hasFastLinks}>
        <FooterTopTextWrapperStyled>
          <FooterTopTextStyled>
            {translations?.about_company_label}
          </FooterTopTextStyled>
          <FooterTopTextStyled>
            {translations?.company_address_label}
          </FooterTopTextStyled>
          {Boolean(translations?.company_address_label_2) && (
            <FooterTopTextStyled>
              {translations?.company_address_label_2}
            </FooterTopTextStyled>
          )}
          <FooterTopTextStyled>
            {translations?.company_registration_info_part2}
            {translations?.company_registration_info_part3 && (
              <>
                <br />
                {translations?.company_registration_info_part3}
              </>
            )}
          </FooterTopTextStyled>
          {Boolean(translations?.company_software_license) && (
            <FooterTopTextStyled>
              {translations?.company_software_license}
            </FooterTopTextStyled>
          )}
        </FooterTopTextWrapperStyled>
      </FooterTopRightColumnStyled>
    </FooterTopStyled>
  );
};
