import React, { ReactElement, useMemo } from 'react';
import * as S from './leftPrices.styled';
import { PricesTotalDiscountContainer } from './pricesTotalDiscount';
import { useGuestOrderFormContext } from '../../../guestOrderForm.context';
import { PricesInstallmentContainer } from './pricesInstallment';
import { PricesTotalContainer } from './pricesTotal';
import { PricesCashBackContainer } from './pricesCashBack';
import { PricesPromoContainer } from './pricesPromo';
import { getB2CPrices } from '@features/common/course';
import { useSelector } from 'react-redux';
import { getCurrencies } from '@lerna-core';

export const LeftPricesContainer = (): ReactElement => {
  const { course } = useGuestOrderFormContext();
  const currencies = useSelector(getCurrencies);
  const {
    totalDiscount,
    hasInstallment,
    hasOneOfDiscounts,
    startPrice,
    totalPrice,
    cashBackPercent,
    cashBackPoints,
    promoCodeDiscountAmount,
  } = useMemo(() => getB2CPrices(course, currencies), [course.price]);

  const showTotalDiscount = !!totalDiscount && !course.isFree;
  const showInstallment =
    hasInstallment && !course.isFree && !course.isFreeByDiscount;
  const showTotalPrice =
    !hasInstallment || course.isFree || course.isFreeByDiscount;
  const showCashBack =
    !!cashBackPercent &&
    !!cashBackPoints &&
    !course.isFree &&
    !course.isFreeByDiscount;

  return (
    <S.LeftPricesWrapper>
      <S.LeftPrices>
        {showTotalDiscount && (
          <PricesTotalDiscountContainer
            discount={totalDiscount}
            isMegaDiscount={course.megaDiscount}
          />
        )}
        {showInstallment && (
          <PricesInstallmentContainer
            installment={course.price?.installment}
            hasDiscounts={hasOneOfDiscounts}
          />
        )}
        {showTotalPrice && (
          <PricesTotalContainer
            value={startPrice}
            valueWithDiscount={totalPrice}
            hasDiscounts={hasOneOfDiscounts}
            isFreeCourse={course.isFree}
          />
        )}
      </S.LeftPrices>
      {promoCodeDiscountAmount && (
        <PricesPromoContainer promoAmount={promoCodeDiscountAmount} />
      )}
      {showCashBack && (
        <PricesCashBackContainer
          cashBack={{ points: cashBackPoints, percent: cashBackPercent }}
        />
      )}
    </S.LeftPricesWrapper>
  );
};
