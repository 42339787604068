import { useContext, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  browserService,
  DeviceInfoContext,
  getFilters,
  useToggle,
} from '@lerna-core';
import {
  getVisibleFilters,
  getVisibleFiltersWithoutTabs,
} from './filters.helper';
import { useCoursesPageContext } from '../../coursesPage';
import { VISIBLE_FILTERS_LIMIT } from './filters.constants';
import { UseFiltersHookModel } from './filters.model';

export const useFilters = (): UseFiltersHookModel => {
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);
  const coursesContext = useCoursesPageContext();
  const filtersFromStore = useSelector(getFilters);

  const [showAllFilters, toggleShowAllFilters] = useToggle(false);

  const filters = !!filtersFromStore.length
    ? filtersFromStore
    : coursesContext.filters;
  const visibleFiltersWithoutTabs = useMemo(
    () => getVisibleFiltersWithoutTabs(filters),
    [filters]
  );
  const visibleFilters = useMemo(
    () => getVisibleFilters(visibleFiltersWithoutTabs, showAllFilters),
    [filters, showAllFilters]
  );

  const showFiltersShowAllButton =
    visibleFiltersWithoutTabs.length > VISIBLE_FILTERS_LIMIT && !showAllFilters;

  return {
    visibleFilters,
    showFiltersShowAllButton,
    toggleShowAllFilters,
    isMobile,
  };
};
