import React, { ReactElement } from 'react';
import { RightPricesStyled } from './rightPrices.styled';
import { getPricesContent } from './rightPrice.meta';
import { useFormSubscriptionContext } from '../../../formSubscription.context';

export const RightPricesContainer = (): ReactElement => {
  const { paymentContent, course, isLernaPrime } = useFormSubscriptionContext();

  return (
    <RightPricesStyled>
      {getPricesContent(paymentContent, course, isLernaPrime)}
    </RightPricesStyled>
  );
};
