import React, { ReactElement } from 'react';
import { CommonEventPositions, Nullable, useUser } from '@lerna-core';
import {
  GuestOrderFormContainer,
  UserOrderFormContainer,
} from '@features/common/forms';
import { useCoursePageContext } from '@features/course/course.context';

export const CourseSignUpContainer = (): Nullable<ReactElement> => {
  const { course } = useCoursePageContext();
  const { user } = useUser();

  if (user) {
    return (
      <UserOrderFormContainer
        course={course}
        handleFormSent={() => Promise.resolve()}
        eventPosition={CommonEventPositions.coursePagePrice}
      />
    );
  }

  return (
    <GuestOrderFormContainer
      course={course}
      handleFormSent={() => Promise.resolve()}
      eventPosition={CommonEventPositions.coursePagePrice}
    />
  );
};
