import styled, { css } from 'styled-components';
import { CoursesByTitleStyledModel } from './coursesByTitle.model';
import { fontSettings } from '@lerna-core';

export const CoursesByTitleContainerStyled = styled.div`
  width: 100%;
  margin-bottom: 48px;
`;

export const CoursesByTitleTitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_24, theme.FONT)}
  font-weight: 600;
  margin-bottom: 24px;
`;

export const CoursesByTitlePaginationStyled = styled.div`
  button {
    margin: 0 auto;
  }
`;

export const CoursesByTitleStyled = styled.div<CoursesByTitleStyledModel>`
  flex-wrap: wrap;
  display: flex;
  margin: 0 -10px;
  padding-bottom: 20px;

  & > * {
    ${({ isRouting }): ReturnType<typeof css> | string =>
      isRouting
        ? css`
            opacity: 0.4;
            pointer-events: none;
            user-select: none;
          `
        : ''}
    transition: opacity 500ms;
  }
`;
