import React, { ReactElement } from 'react';
import {
  ContentInfoStyled,
  SwitcherStyled,
  TextStyled,
} from './contentInfo.styled';
import { useCompensationFormContext } from '../../compensationForm.context';
import {
  orderFormEvent,
  OrderFormEvents,
  OrderFormEventsValues,
} from '@features/common/forms';
import { CommonEventTypes, getVendors, useTranslations } from '@lerna-core';
import { CompensationFormContent } from '../../compensationForm.model';
import { UserOrderFormPaymentContentTypes } from '../../../../userOrderForm.model';
import { useSelector } from 'react-redux';

export const ContentInfoContainer = (): ReactElement => {
  const {
    course,
    formContent,
    paymentContent,
    showPaymentTabs,
    isCourseWithCompensationContent,
    handleChangeFormContent,
    eventPosition,
  } = useCompensationFormContext();
  const translations = useTranslations();
  const vendors = useSelector(getVendors);

  const infoText = isCourseWithCompensationContent
    ? `${translations?.compensation_b2b_form_info_1}.`
    : `${translations?.compensation_b2b_form_info_2}.`;
  const switcherText = isCourseWithCompensationContent
    ? translations?.compensation_b2b_form_switcher_text_1
    : translations?.compensation_b2b_form_switcher_text_2;

  const handleClickSwitcher = (): void => {
    let event: string;
    const eventValueOptions = [OrderFormEventsValues.addOnCompensation];

    switch (formContent) {
      case CompensationFormContent.courseWithCompensation:
        event = `${OrderFormEvents.formEnrollSelectSelfPay}`;
        handleChangeFormContent(
          CompensationFormContent.courseWithoutCompensation
        );
        eventValueOptions.push(OrderFormEventsValues.noTabs);
        eventValueOptions.push(OrderFormEventsValues.withCompensation);
        break;
      case CompensationFormContent.courseWithoutCompensation:
        eventValueOptions.push(
          showPaymentTabs
            ? OrderFormEventsValues.withTabs
            : OrderFormEventsValues.noTabs
        );
        event = `${OrderFormEvents.formEnrollSelectCompanyPay}`;
        switch (paymentContent) {
          case UserOrderFormPaymentContentTypes.consultation:
            eventValueOptions.push(OrderFormEventsValues.consultation);
            break;
          case UserOrderFormPaymentContentTypes.onlinePay:
            eventValueOptions.push(OrderFormEventsValues.paymentOnline);
            break;
        }

        handleChangeFormContent(CompensationFormContent.courseWithCompensation);
        break;
    }

    orderFormEvent(
      event,
      eventValueOptions,
      CommonEventTypes.coursesEvents,
      eventPosition,
      course,
      vendors
    );
  };

  return (
    <ContentInfoStyled>
      <TextStyled>{infoText}</TextStyled>
      <SwitcherStyled onClick={handleClickSwitcher}>
        <span>{switcherText}</span>
      </SwitcherStyled>
    </ContentInfoStyled>
  );
};
