import { orderFormPushDataLayer } from '@features/common/forms';
import {
  CommonEventPositions,
  CommonEventTypes,
  CourseModel,
  VendorModel,
} from '@lerna-core';
import { ListItemButtonsEvent } from '../coursesList';

export const handleCoursesListDataLayer = (
  course: CourseModel,
  vendors: VendorModel[],
  activeCourseIndex: number
): void => {
  orderFormPushDataLayer(
    ListItemButtonsEvent.buttonEnroll,
    CommonEventTypes.coursesEvents,
    CommonEventPositions.coursesPageList,
    course,
    vendors,
    activeCourseIndex
  );
};
