import React, { ReactElement } from 'react';
import { AdvantagesItem, LeftAdvantagesStyled } from './leftAdvantages.styled';
import { getAdvantages } from './leftAdvantages.helper';
import { useFormSubscriptionContext } from '../../../formSubscription.context';
import { getVendors, Nullable, useTranslations } from '@lerna-core';
import { IconsCheckComponent } from '@features/common/forms/components/icons';
import { useSelector } from 'react-redux';

export const LeftAdvantagesContainer = (): Nullable<ReactElement> => {
  const translations = useTranslations();
  const vendors = useSelector(getVendors);
  const { course, showAdvantages } = useFormSubscriptionContext();

  const advantages = getAdvantages(
    course,
    vendors,
    translations,
    showAdvantages
  );

  if (!advantages.length) return null;

  return (
    <LeftAdvantagesStyled>
      {advantages.map(
        (advantage) =>
          !!advantage && (
            <AdvantagesItem key={advantage}>
              <IconsCheckComponent width={24} height={24} />
              {advantage}
            </AdvantagesItem>
          )
      )}
    </LeftAdvantagesStyled>
  );
};
