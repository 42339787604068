import React, { ElementType, ReactElement } from 'react';
import { HeaderGroup, Row } from 'react-table';

export const getTableRows = (
  rows: Row[],
  prepareRow: (row: Row) => void,
  headerGroups: HeaderGroup[],
  TableBodyRow: ElementType,
  TableBodyData: ElementType,
  hasLeftOverscroll: boolean,
  hasRightOverscroll: boolean,
  handleRowClick?: (row: Row) => void
): ReactElement[] => {
  return rows.map((row, rowIndex) => {
    prepareRow(row);
    const { key, ...rowProps } = row.getRowProps();
    const canClick = typeof handleRowClick === 'function' && !row.isDisabled;
    const onClick = (): void => {
      if (typeof handleRowClick === 'function' && !row.isDisabled) {
        handleRowClick(row);
      }
    };

    return (
      <TableBodyRow
        hasHover={canClick}
        key={key}
        {...rowProps}
        onClick={onClick}
      >
        {row.cells.map((cell, index, list) => {
          const { key, ...cellProps } = cell.getCellProps();
          const headerSticky = headerGroups[0].headers[index].sticky;
          let sticky;

          if (index === 0 || index + 1 === list.length) {
            switch (headerSticky) {
              case 'left':
                sticky = hasLeftOverscroll ? headerSticky : undefined;
                break;
              case 'right':
                sticky = hasRightOverscroll ? headerSticky : undefined;
                break;
              default:
                break;
            }
          }

          return (
            <TableBodyData
              key={key}
              rowIndex={rowIndex}
              sticky={sticky}
              disabled={row.isDisabled}
              {...cellProps}
            >
              {cell.render('Cell')}
            </TableBodyData>
          );
        })}
      </TableBodyRow>
    );
  });
};
