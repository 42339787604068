import styled, { css } from 'styled-components';

export const FilterDatePopupContainerStyled = styled.div``;

export const FilterDatePopupBodyStyled = styled.div`
  display: flex;
  gap: 8px;
  padding: 20px;
`;

export const FilterDatePopupDropdownStyles = css`
  padding: 0;
  min-width: 350px;
`;
