import React, { ReactElement } from 'react';
import { ResetAllItemStyled } from './resetAllItem.styled';
import { IconCloseComponent, useTheme, useTranslations } from '@lerna-core';
import { IconStyled } from '../resetItem';
import { ResetAllItemContainerProps } from './resetAllItem.model';

export const ResetAllItemContainer = ({
  handleClearAllFilters,
}: ResetAllItemContainerProps): ReactElement => {
  const translations = useTranslations();
  const theme = useTheme();

  return (
    <ResetAllItemStyled onClick={handleClearAllFilters}>
      {translations?.clear_all}
      <IconStyled>
        <IconCloseComponent
          width={14}
          height={14}
          strokeColor={theme.COLOR.WHITE}
          strokeWidth="3"
        />
      </IconStyled>
    </ResetAllItemStyled>
  );
};
