import React, { ReactElement } from 'react';
import {
  GuestOrderFormContextProvider,
  useGuestOrderFormContext,
} from './guestOrderForm.context';
import { Nullable, useTheme } from '@lerna-core';
import { GuestOrderFormContainerProps } from './guestOrderForm.model';
import { CenteredPopupContainer } from '@features/common/popups/popup';
import { FormContentContainer } from './formContent';
import { GoogleSheetLoggerProvider } from '@features/common/forms';

const GuestOrderFormContent = (): ReactElement => {
  const { currentPopup, handlePopupClose } = useGuestOrderFormContext();
  const theme = useTheme();

  return (
    <CenteredPopupContainer
      isOpen={!!currentPopup}
      handleClose={handlePopupClose}
      styles={{
        scrollbar: {
          marginTop: 20,
          marginBottom: 20,
        },
        borderRadius: 20,
        backgroundColor: theme.COLOR.WHITE,
      }}
    >
      <FormContentContainer />
    </CenteredPopupContainer>
  );
};

export const GuestOrderFormContainer = (
  props: GuestOrderFormContainerProps
): Nullable<ReactElement> => (
  <GoogleSheetLoggerProvider course={props.course}>
    <GuestOrderFormContextProvider props={props}>
      <GuestOrderFormContent />
    </GuestOrderFormContextProvider>
  </GoogleSheetLoggerProvider>
);
