import React, { ReactElement, useMemo } from 'react';
import {
  ButtonColor,
  ButtonContainer,
  getBonusPointsLabel,
  IconInfoWithoutBgComponent,
  mapPriceValue,
  Nullable,
  personalPath,
  projectSubDomains,
  RegisterPositions,
  urlService,
  useRegisterPopupContext,
  useTheme,
  useTranslations,
} from '@lerna-core';
import {
  CustomerBannerContainerStyled,
  CustomerBannerLabelStyled,
} from '../advantagesCustomerBanners.styled';
import {
  BannerBonusesButtonsStyled,
  BannerBonusesHeadStyled,
  BannerBonusesLegendStyled,
  BannerBonusesPointsStyled,
  BannerBonusesPointsValueStyled,
} from './bannerBonuses.styled';
import { BannerBonusesContainerProps } from './bannerBonuses.model';
import { BannerContainer } from '../banner';
import { useRouter } from 'next/router';

export const BannerBonusesContainer = ({
  bonuses,
  currencies,
  handleClickActionButton,
  handleClickMoreButton,
}: BannerBonusesContainerProps): Nullable<ReactElement> => {
  const theme = useTheme();
  const router = useRouter();
  const translations = useTranslations();
  const { handleAction } = useRegisterPopupContext();

  const bonusLabel = useMemo(() => {
    if (!bonuses) {
      return null;
    }

    return getBonusPointsLabel(bonuses.points, [
      translations.points_label_1,
      translations.points_label_2,
      translations.points_label_many,
    ]);
  }, [bonuses?.points]);
  const bonusHref = useMemo(
    () =>
      urlService.getFullUrlToSubDomain(
        projectSubDomains.personal,
        personalPath.bonuses
      ),
    []
  );
  const bonusPriceLabel = useMemo(() => {
    if (!bonuses) {
      return null;
    }

    return `${getBonusPointsLabel(bonuses.bonusPriceConversion.points, [
      translations.points_label_1,
      translations.points_label_2,
      translations.points_label_many,
    ])} = ${mapPriceValue(bonuses.bonusPriceConversion.money, currencies)}`;
  }, []);

  const handleBonusesLinkClick = () => {
    typeof handleClickMoreButton === 'function' && handleClickMoreButton();
    handleAction(
      {
        onSuccess: () => {
          router.push(bonusHref);
        },
      },
      RegisterPositions.bannerOnCatalog
    );
  };

  if (!bonuses) {
    return null;
  }

  return (
    <BannerContainer>
      <CustomerBannerContainerStyled>
        <BannerBonusesHeadStyled>
          <BannerBonusesPointsStyled>
            <CustomerBannerLabelStyled>
              {translations.banner_bonuses_points_label}:
            </CustomerBannerLabelStyled>
            <BannerBonusesPointsValueStyled>
              {bonusLabel}
            </BannerBonusesPointsValueStyled>
          </BannerBonusesPointsStyled>
          <BannerBonusesLegendStyled>
            {bonusPriceLabel}
          </BannerBonusesLegendStyled>
        </BannerBonusesHeadStyled>
        <BannerBonusesButtonsStyled>
          <ButtonContainer
            color={ButtonColor.white}
            title={translations.banner_bonuses_button_points}
            onClick={handleClickActionButton}
          />
          <ButtonContainer
            color={ButtonColor.transparent}
            borderColor={theme.COLOR.WHITE_32}
            textColor={theme.COLOR.WHITE}
            onClick={handleBonusesLinkClick}
          >
            <>
              <IconInfoWithoutBgComponent fillColor={theme.COLOR.WHITE} />
              {translations.banner_bonuses_button_more}
            </>
          </ButtonContainer>
        </BannerBonusesButtonsStyled>
      </CustomerBannerContainerStyled>
    </BannerContainer>
  );
};
