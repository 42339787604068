import styled, { css } from 'styled-components';
import {
  breakpoint,
  FormAgreementStyled,
  FormAgreementTree,
  TextAreaControlComponent,
} from '@lerna-core';

export const TextAreaStyled = styled(TextAreaControlComponent)`
  textarea {
    height: 120px;
  }
`;

export const ContactUsFormContentStyled = styled.div`
  ${breakpoint(
    'mediumDown',
    css`
      padding-bottom: calc(180px + env(safe-area-inset-bottom));
    `
  )}
`;

export const ContactUsFormFooterStyled = styled.div`
  ${FormAgreementTree} {
    margin: -16px -10px 8px;

    ${breakpoint(
      'mediumDown',
      css`
        margin: -6px -6px 8px;
      `
    )}
  }

  ${FormAgreementStyled} {
    margin: -8px 0 16px 0;

    ${breakpoint(
      'mediumDown',
      css`
        margin: 0 0 16px 0;
      `
    )}
  }
`;
