import React, { ReactElement } from 'react';
import {
  ButtonStyled,
  ContentStatusStyled,
  IconStyled,
  SubtitleStyled,
  TitleStyled,
} from './contentStatus.styled';
import { getStatusIcon } from './contentStatus.meta';
import { getStatusMessage } from './contentStatus.helper';
import {
  ButtonColor,
  ButtonContainer,
  CommonEventPositions,
  CommonEventTypes,
  getSimpleEvent,
  IframeInitiator,
  personalPath,
  projectSubDomains,
  pushDataLayer,
  QueryParams,
  urlService,
  useTranslations,
} from '@lerna-core';
import { useRouter } from 'next/router';
import { OrderFormEvents } from '@features/common/forms';
import { useCustomCompensationFormContext } from '../../customCompensationForm.context';

export const ContentStatusContainer = (): ReactElement => {
  const router = useRouter();
  const translations = useTranslations();
  const { isSuccessDisplayed, isErrorDisplayed, isExistOrder, isFreeCourse } =
    useCustomCompensationFormContext();

  const statusIcon = getStatusIcon(
    isSuccessDisplayed,
    isErrorDisplayed,
    isExistOrder
  );
  const statusMessage = getStatusMessage(
    isSuccessDisplayed,
    isErrorDisplayed,
    isExistOrder,
    translations,
    isFreeCourse
  );

  const showStatusButton = (isSuccessDisplayed || isExistOrder) && isFreeCourse;

  const handleStatusButtonClick = (): void => {
    let path = personalPath.courses;
    const iframeInitiator = router.query[
      QueryParams.initiator
    ] as IframeInitiator;

    if (iframeInitiator) {
      path = personalPath.iframeCourses.replace(
        `[${QueryParams.initiator}]`,
        iframeInitiator
      ) as personalPath;
    }

    pushDataLayer(
      getSimpleEvent(
        OrderFormEvents.clickButtonMyCourses,
        CommonEventTypes.overallEvents,
        CommonEventPositions.formOrderStatus
      )
    );

    router.push(
      urlService.getFullUrlToSubDomain(projectSubDomains.personal, path)
    );
  };

  return (
    <ContentStatusStyled>
      <IconStyled>{statusIcon}</IconStyled>
      <TitleStyled>{statusMessage.title}</TitleStyled>
      {statusMessage.subtitle && (
        <SubtitleStyled>{statusMessage.subtitle}</SubtitleStyled>
      )}
      {showStatusButton && (
        <ButtonStyled>
          <ButtonContainer
            onClick={handleStatusButtonClick}
            color={ButtonColor.transparent}
            borderRadius={8}
            title={translations?.my_education}
          />
        </ButtonStyled>
      )}
    </ContentStatusStyled>
  );
};
