import { useRouter } from 'next/router';
import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { FilterTabStyled } from './filterTab.styled';
import { FilterTabModel } from './filterTab.model';
import {
  filterRouterService,
  getCompositeFastLink,
  getFastLinkHref,
  getFilterOptionValueSelector,
  getFilters,
  urlService,
  useFastLinksContext,
} from '@lerna-core';

export const FilterTab = ({
  option,
  filter,
  filterColor,
  defaultPathname,
}: FilterTabModel): ReactElement => {
  const router = useRouter();
  const fastLinks = useFastLinksContext();
  const filters = useSelector(getFilters);
  const isSelected = useSelector(
    getFilterOptionValueSelector(filter.slug, option.slug)
  );

  const handleClick = (): void => {
    const newQuery = filterRouterService.getNewRouteTab(
      {
        ...router,
        query: filterRouterService.getNewRouteFromFilters(router, filters),
      },
      filter.slug,
      option.slug
    );

    const fastLink = getCompositeFastLink(fastLinks, newQuery);

    if (fastLink) {
      router.push(
        getFastLinkHref(fastLink.slug, defaultPathname, newQuery),
        undefined,
        {
          scroll: false,
        }
      );
    } else {
      router.push(
        urlService.createUrlFromPathAndQuery(defaultPathname, router, newQuery),
        undefined,
        { scroll: false }
      );
    }
  };

  return (
    <FilterTabStyled
      activeColor={filterColor}
      onClick={handleClick}
      isActive={isSelected}
    >
      {option.value}
    </FilterTabStyled>
  );
};
