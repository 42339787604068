import React, { ReactElement } from 'react';
import { useTheme } from '../../hooks';
import { IconCloseComponent, IconLernaLogoComponent } from '../icons';
import { LogoContainerProps } from './logo.model';
import {
  LogoCollaborateWithLerna,
  LogoContainerStyled,
  LogoWrapperStyled,
} from './logo.styled';
import { LogoCompanyContainer } from './logoCompany';

export const LogoContainer = ({
  href = '/',
  withCompany = true,
  height,
  width,
  preset,
  logo,
  callback,
  isCollaborateWithLerna,
}: LogoContainerProps): ReactElement => {
  const { COLOR } = useTheme();
  const logoComponent = withCompany ? (
    <LogoCompanyContainer
      logo={logo}
      preset={preset}
      width={width}
      height={height}
    />
  ) : (
    <IconLernaLogoComponent width={width} height={height} />
  );

  const handleLogoClick = (): void => {
    callback && callback();
  };

  return (
    <LogoContainerStyled onClick={handleLogoClick}>
      <a href={href}>
        <LogoWrapperStyled>
          {logoComponent}
          {isCollaborateWithLerna && (
            <LogoCollaborateWithLerna>
              <IconCloseComponent strokeColor={COLOR.TEXT_LIGHT_COLOR} />
              <IconLernaLogoComponent />
            </LogoCollaborateWithLerna>
          )}
        </LogoWrapperStyled>
      </a>
    </LogoContainerStyled>
  );
};
