import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import {
  getCoursesTableColumns,
  getCoursesTableRows,
  TabCoursesTransferComponents,
} from './coursesTransfer.meta';
import { CoursesTransferProps } from './coursesTransfer.model';
import {
  CoursesTransferPendingStyled,
  CoursesTransferStyled,
} from './coursesTransfer.styled';
import {
  SearchAllocatedContainer,
  SearchUnallocatedContainer,
} from './transferSearch';
import { getCoursesTransferSelector } from './coursesTransfer.selectors';
import { LocationType, TransferContainer } from '../../transfer';
import { CoursesTransferContextProvider } from './coursesTransfer.context';
import { SpinnerContainer } from '../../spinner';
import { useTheme } from '../../../hooks';
import {
  useCoursesTransfer,
  useCoursesTransferSearch,
} from './coursesTransfer.hooks';
import { getVendors } from '../../vendors';
import { getCurrencies } from '../../currencies';

/*
  1. Родитель должен иметь 'position: relative', что бы корректно отображать лоадер.
  2. Если провайдеру необходимы параметры подефолту использовать setUnallocatedCoursesFilters/setAllocatedCoursesFilters или обертку
    Example:
        const coursesProvider: CoursesTransferProvider = async (
          params,
        ) => {
          return await coursesTableClientProvider(
            {
              ...params,
              isActive: true,
            },
          );
        };
 */
/**
 * @param translations
 * @param dispatch - передавать dispatch из useAppDispatch
 * @param coursesProvider
 * @param coursesAllProvider
 * @param accessors - принимает массив аксессоров, title - дефолтный аксессор, прокидывать его не надо
 * @param baseDomain - для трансферов в админке нудно передавать этот параметр, брать из useRegionDomainHook
 * @param id - передавать для трансферов редактирования объектов (для трансферов создания не надо)
 * @param subscriptionId - передавать для трансферов редактирования объектов (для трансферов создания не надо)
 * @param isRadioButton - передавать для выбора 1 курса
 */

export const CoursesTransferContainer = ({
  translations,
  dispatch,
  coursesProvider,
  coursesAllProvider,
  accessors,
  baseDomain,
  id: benefitId = '',
  subscriptionId = '',
  courseExclusionCompanyId = '',
  isRadioButton = false,
  getCustomRows,
  getCustomColumns,
}: CoursesTransferProps): ReactElement => {
  const theme = useTheme();
  const id = benefitId || subscriptionId || courseExclusionCompanyId;
  const { unallocated, allocated, added } = useSelector(
    getCoursesTransferSelector
  );

  const vendors = useSelector(getVendors);
  const currencies = useSelector(getCurrencies);
  const { handleCheckedLeftCallback, handleCheckedRightCallback } =
    useCoursesTransfer(
      dispatch,
      coursesProvider,
      id,
      subscriptionId,
      benefitId,
      courseExclusionCompanyId
    );
  const {
    isAllocatedSearchOpen,
    handleAllocatedSearchCancel,
    handleUnallocatedSearchCancel,
    isUnallocatedSearchOpen,
    changeUnallocatedSearch,
    changeAllocatedSearch,
  } = useCoursesTransferSearch(
    dispatch,
    coursesProvider,
    id,
    subscriptionId,
    benefitId,
    courseExclusionCompanyId
  );

  const isPending = unallocated.isPending || allocated.isPending;
  const disabledTransferControl = unallocated.isLoading || allocated.isLoading;
  const disabledLeftTransferControl =
    unallocated.isLoading ||
    allocated.isLoading ||
    (isRadioButton && added.length > 0);

  const providerProps = {
    coursesProvider,
    coursesAllProvider,
    accessors,
    dispatch,
    baseDomain,
    id,
    isRadioButton,
    courseExclusionCompanyId,
  };

  const getRows =
    getCustomRows && typeof getCustomRows === 'function'
      ? getCustomRows
      : getCoursesTableRows;

  const getColumns =
    getCustomColumns && typeof getCustomColumns === 'function'
      ? getCustomColumns
      : getCoursesTableColumns;

  return (
    <CoursesTransferStyled isPending={isPending}>
      {isPending && (
        <CoursesTransferPendingStyled>
          <SpinnerContainer strokeWidth="6" strokeColor={theme.COLOR.WHITE} />
        </CoursesTransferPendingStyled>
      )}
      <CoursesTransferContextProvider {...providerProps}>
        <TransferContainer
          disabledLeft={disabledLeftTransferControl}
          disabledRight={disabledTransferControl}
          translations={translations}
          leftRows={getRows(
            unallocated.data,
            translations,
            vendors,
            currencies,
            baseDomain
          )}
          rightRows={getRows(
            allocated.data,
            translations,
            vendors,
            currencies,
            baseDomain
          )}
          leftColumns={getColumns(
            translations,
            accessors,
            LocationType.unallocated,
            changeUnallocatedSearch
          )}
          rightColumns={getColumns(
            translations,
            accessors,
            LocationType.allocated,
            changeAllocatedSearch
          )}
          Components={TabCoursesTransferComponents}
          handleCheckedLeftCallback={handleCheckedLeftCallback}
          handleCheckedRightCallback={handleCheckedRightCallback}
          leftHeaderComponent={
            isUnallocatedSearchOpen && (
              <SearchUnallocatedContainer
                translations={translations}
                handleSearchCancel={handleUnallocatedSearchCancel}
              />
            )
          }
          rightHeaderComponent={
            isAllocatedSearchOpen && (
              <SearchAllocatedContainer
                translations={translations}
                handleSearchCancel={handleAllocatedSearchCancel}
              />
            )
          }
        />
      </CoursesTransferContextProvider>
    </CoursesTransferStyled>
  );
};
