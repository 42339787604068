import {
  BonusesApiModel,
  CareerApiModel,
  PersonalApiModel,
  UserAggregateApiModel,
  UserAggregateModel,
} from '../user';
import { mapBonuses, mapCareer, mapPersonal } from '../../adapters';
import { CompanyApi, mapCompany } from '../company';
import {
  customerCoursesAdapter,
  CustomerCoursesResponseApiModel,
} from '../customer';
import { Nullable } from '../../models';

export const mapUser = (
  personal: PersonalApiModel,
  bonuses: BonusesApiModel,
  career?: CareerApiModel,
  company?: CompanyApi,
  courses?: CustomerCoursesResponseApiModel['data']
): UserAggregateModel => {
  const user: UserAggregateModel = {
    personal: mapPersonal(personal),
    bonuses: mapBonuses(bonuses),
    company: company ? mapCompany(company) : null,
    courses: courses ? customerCoursesAdapter(courses) : null,
  };

  if (!!career) {
    user.career = mapCareer(career);
  }

  return user;
};

export const userAdapter = (
  response: UserAggregateApiModel
): Nullable<UserAggregateModel> => {
  const { data } = response;

  return !!data.personal && !!data.bonuses
    ? mapUser(
        data.personal,
        data.bonuses,
        data?.career,
        data.company,
        data.me_courses
      )
    : null;
};
