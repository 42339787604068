import { ReactElement } from 'react';
import { PricesContainer } from './prices';
import { CourseModel } from '@lerna-core';
import { UserOrderFormPaymentContentTypes } from '../../../../userOrderForm.model';

export const getPricesContent = (
  paymentContent: UserOrderFormPaymentContentTypes,
  course: CourseModel,
  isLernaPrime?: boolean
): ReactElement => {
  switch (paymentContent) {
    case UserOrderFormPaymentContentTypes.onlinePay:
      return (
        <PricesContainer
          course={course}
          withInstallment={false}
          isLernaPrime={isLernaPrime}
        />
      );
    case UserOrderFormPaymentContentTypes.consultation:
    default:
      return <PricesContainer course={course} isLernaPrime={isLernaPrime} />;
  }
};
