import { usePageRouter } from '@lerna-core';
import React, { ReactElement } from 'react';
import { EmptyClearButtonContainer } from './emptyClearButton';
import {
  ListEmptyStyled,
  SubtitleStyled,
  TitleStyled,
} from './listEmpty.styled';
import { ListEmptyModel } from './listEmpty.model';

export const ListEmptyContainer = ({
  title,
  subtitle,
}: ListEmptyModel): ReactElement => {
  const isRouting = usePageRouter();

  return (
    <ListEmptyStyled isRouting={isRouting}>
      <TitleStyled hasSubtitle={!!subtitle}>{title}</TitleStyled>
      {subtitle && <SubtitleStyled>{subtitle}</SubtitleStyled>}
      <EmptyClearButtonContainer />
    </ListEmptyStyled>
  );
};
