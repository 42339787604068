import { DateFormatEnum, dateService } from '../../services';

export const getFilterDateToMinValue = (from: string): string | undefined => {
  if (from !== '') {
    return dateService.formatDate(new Date(from), DateFormatEnum.YYYYMMDD, '-');
  }

  return undefined;
};

export const getFilterDateFromMaxValue = (to: string): string | undefined => {
  if (to !== '') {
    return dateService.formatDate(new Date(to), DateFormatEnum.YYYYMMDD, '-');
  }

  return undefined;
};
