import { ReactElement } from 'react';
import * as S from './suggestShowAll.styled';
import { SuggestShowAllModel } from './suggestShowAll.model';
import { IconDotsComponent, useTranslations } from '../../../../features';

export const SuggestShowAllContainer = ({
  handleClickSuggest,
  term,
  accentColor,
}: SuggestShowAllModel): ReactElement => {
  const translations = useTranslations();

  translations.show_all = 'Показать все';
  return (
    <S.SuggestShowAll
      accentColor={accentColor}
      onClick={handleClickSuggest.bind(null, term)}
    >
      <IconDotsComponent height={20} width={20} />
      <span>{translations.show_all}</span>
    </S.SuggestShowAll>
  );
};
