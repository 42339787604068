import { CourseModel, CurrencyModel, setPriceValue } from '@lerna-core';
import { CustomerB2B2CPricesModel } from './coursePrices.model';

export const getCustomerB2B2CPrices = (
  { price }: CourseModel,
  currencies: CurrencyModel[]
): CustomerB2B2CPricesModel => {
  const startPrice = setPriceValue(
    price.startPriceCustomerB2B2C || price.startPrice,
    currencies
  );

  const corporateDiscountAmount = price?.priceCustomerB2B2C
    ?.vendorWithPartnershipDiscount
    ? setPriceValue(
        price.priceCustomerB2B2C.vendorWithPartnershipDiscount,
        currencies
      )
    : '';
  const corporateDiscountValue =
    price?.priceCustomerB2B2C?.vendorWithPartnershipDiscount?.value;
  const hasCorporateDiscount = Boolean(
    corporateDiscountValue && parseInt(corporateDiscountValue)
  );

  const bonusPointsDiscount = price?.priceCustomerB2B2C?.discountBonuses
    ? setPriceValue(price?.priceCustomerB2B2C?.discountBonuses, currencies)
    : '';
  const hasBonusPointsDiscount = Boolean(
    bonusPointsDiscount && parseInt(bonusPointsDiscount)
  );

  const totalPrice = price.priceCustomerB2B2C
    ? setPriceValue(price.priceCustomerB2B2C, currencies)
    : '';

  const totalDiscount = price.priceCustomerB2B2C?.totalDiscount?.value;
  const hasTotalDiscount = Boolean(totalDiscount && parseInt(totalDiscount));

  const hasInstallment = !!price?.priceCustomerB2B2C?.installment;

  const hasDiscounts = hasBonusPointsDiscount && hasCorporateDiscount;
  const hasOneOfDiscounts = hasBonusPointsDiscount || hasCorporateDiscount;

  return {
    startPrice,
    hasCorporateDiscount,
    corporateDiscountAmount,
    corporateDiscountValue,
    hasBonusPointsDiscount,
    bonusPointsDiscount,
    totalPrice,
    hasTotalDiscount,
    totalDiscount,
    hasDiscounts,
    hasOneOfDiscounts,
    hasInstallment,
  };
};
