import React, { ReactElement } from 'react';
import {
  ButtonColor,
  ButtonContainer,
  CheckboxControlComponent,
  CommonEventTypes,
  getVendors,
  IconInfoCircledComponent,
  TextControlComponent,
  useTheme,
  useTranslations,
} from '@lerna-core';
import {
  ButtonStyled,
  ContentRightStyled,
  CustomCompensationCheckboxStyled,
} from './contentRight.styled';
import { RightPricesContainer } from './rightPrices';
import { RightHeadContainer } from './rightHead';
import { useCustomCompensationFormContext } from '../../customCompensationForm.context';
import { AgreementStyled } from '../../customCompensationForm.styled';
import { TooltipContainer } from '@features/common/tooltip';
import {
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';
import { useSelector } from 'react-redux';

export const ContentRightContainer = (): ReactElement => {
  const theme = useTheme();
  const translations = useTranslations();
  const vendors = useSelector(getVendors);
  const {
    handleFormButtonClick,
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    isSubmitting,
    submitButtonTitle,
    isOnlinePaySelected,
    isFreeCourse,
    course,
    eventPosition,
  } = useCustomCompensationFormContext();

  const showFormInputs = !isFreeCourse && !isOnlinePaySelected;

  const handleWithCustomCompensationTooltipClick = (): void => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollCourseOpenPromptAboutMotivation,
      CommonEventTypes.coursesEvents,
      eventPosition,
      course,
      vendors
    );
  };

  return (
    <ContentRightStyled>
      <RightHeadContainer />
      <RightPricesContainer />
      {showFormInputs && (
        <>
          <CheckboxControlComponent
            name="withPromoCode"
            checked={values.withPromoCode}
            onChange={handleChange}
            children={translations?.i_have_promo_code}
            disabled={isSubmitting}
            margin="0 0 16px 0"
          />
          {values.withPromoCode && (
            <TextControlComponent
              type="text"
              name="promoCode"
              value={values.promoCode}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={translations?.promo_code}
              floatingPlaceholder={false}
              disabled={isSubmitting}
              touched={touched.promoCode}
              errorMessage={errors.promoCode}
              showResetButton
              setFieldValue={setFieldValue}
              margin="24px 0"
            />
          )}
          <CustomCompensationCheckboxStyled>
            <CheckboxControlComponent
              name="withCustomCompensation"
              checked={values.withCustomCompensation}
              onChange={handleChange}
              children={translations?.custom_compensation_checkbox_text}
              disabled={isSubmitting}
              margin="0 0 24px 0"
            />
            <TooltipContainer
              content={translations?.order_form_payments_tooltip_text_1}
              contentPosition="center"
              triggerHoverOpacity={0.64}
              contentMargin="25px 165px 0 0"
              triggerMargin="4px 0 0 5px"
              onOpen={handleWithCustomCompensationTooltipClick}
            >
              <IconInfoCircledComponent
                width={16}
                height={16}
                strokeColor={theme.COLOR.TEXT_DARK_COLOR}
                fillColor={theme.COLOR.TEXT_DARK_COLOR}
              />
            </TooltipContainer>
          </CustomCompensationCheckboxStyled>
        </>
      )}
      <ButtonStyled>
        <ButtonContainer
          onClick={handleFormButtonClick}
          color={ButtonColor.purple}
          borderRadius={8}
          title={submitButtonTitle}
          disabled={isSubmitting}
        />
      </ButtonStyled>
      {!values.withCustomCompensation && (
        <AgreementStyled
          dangerouslySetInnerHTML={{
            __html: translations?.public_contract_text,
          }}
        />
      )}
    </ContentRightStyled>
  );
};
