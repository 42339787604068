import React, { ReactElement, useMemo } from 'react';

import { getDefaultColWidth } from '../coursesTransfer.service';
import { useSelector } from 'react-redux';

import {
  requestTransferCoursesAllAllocated,
  requestTransferCoursesAllocated,
} from '../coursesTransfer.actions';
import { getAllocatedEmptyContent } from './transferRight.service';
import { TransferRightStyled } from './transferRight.styled';
import {
  LocationType,
  TransferRightProps,
  TransferTitleContainer,
} from '../../../transfer';
import {
  getCoursesTransferAllocatedFiltersSelector,
  getCoursesTransferSelector,
} from '../coursesTransfer.selectors';
import { useCoursesTransferContext } from '../coursesTransfer.context';
import { CoursesTransferTableContainer } from '../transferTable';

export const TransferRightContainer = ({
  getSelectedRows,
  rightRows,
  rightColumns,
  handleCheck,
  headerComponent,
  translations,
}: TransferRightProps): ReactElement => {
  const courses = useSelector(getCoursesTransferSelector);
  const {
    added,
    removed,
    allocated: {
      filters,
      meta,
      isLoading,
      isPaginating,
      isSelectedAll,
      isPending: isPendingAllocatedCourses,
    },
    unallocated: { isPending: isPendingUnallocatedCourses },
  } = courses;
  const {
    id,
    courseExclusionCompanyId,
    coursesAllProvider,
    accessors,
    coursesProvider,
    dispatch,
    isRadioButton,
  } = useCoursesTransferContext();
  const coursesAllocatedFilters = useSelector(
    getCoursesTransferAllocatedFiltersSelector
  );
  const isPending = isPendingUnallocatedCourses || isPendingAllocatedCourses;

  const handlePagination = (): void => {
    dispatch(
      requestTransferCoursesAllocated(
        coursesProvider,
        {
          ...filters,
          id,
          relatedWithCourseExclusionsForCompanyId: courseExclusionCompanyId,
          added,
          removed,
          page: meta.currentPage + 1,
        },
        true
      )
    );
  };

  const handleCheckAll = (): void => {
    if (meta.totalPages > meta.currentPage) {
      dispatch(
        requestTransferCoursesAllAllocated(coursesAllProvider, {
          ...filters,
          id,
          relatedWithCourseExclusionsForCompanyId: courseExclusionCompanyId,
          added,
          removed,
          limit: 500,
          pages: { current: 0, last: Math.ceil(meta.total / 500) },
        })
      );
    }
  };

  const title = isRadioButton
    ? translations.selected_course
    : translations.selected_courses;

  const emptyContent = useMemo(
    () =>
      getAllocatedEmptyContent(translations, coursesAllocatedFilters, courses),
    [coursesAllocatedFilters, courses]
  );

  const totalCourses = useMemo(
    () => (isRadioButton ? 1 : courses.totalCourses),
    [courses.totalCourses]
  );

  return (
    <TransferRightStyled>
      <TransferTitleContainer
        translations={translations}
        title={title}
        selected={courses.allocated.meta.total}
        amount={totalCourses}
      />
      <CoursesTransferTableContainer
        defaultColWidth={getDefaultColWidth(accessors.length + 1, 2)}
        handleCheck={handleCheck}
        handleCheckAll={handleCheckAll}
        tableData={{ rows: rightRows, columns: rightColumns }}
        getSelectedRows={getSelectedRows}
        handlePagination={handlePagination}
        meta={meta}
        isLoading={isLoading && !isPending}
        isPaginating={isPaginating}
        headerComponent={headerComponent}
        emptyContent={emptyContent}
        isSelectedAll={isSelectedAll}
        isRadioButton={isRadioButton}
        locationType={LocationType.allocated}
      />
    </TransferRightStyled>
  );
};
