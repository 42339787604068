import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { useTheme } from '../../../hooks';
import { PRESETS, urlService } from '../../../services';
import { Nullable } from '../../../models';
import { useUser } from '../../user';
import { useTranslations } from '../../translations';
import { LogoContainer } from '../../logo';
import { MenuSwitcherContainer } from '../../menu';
import { getHeaderLink, getMenuSwitcherLinks } from './iHeader.helper';
import * as S from './iHeader.styled';
import { IHeaderLink } from './iHeader.styled';
import { IHeaderModel } from './iHeader.model';
import { getCoursesPath } from '../../courses';
import { IUserBarContainer } from '../iUserBar';
import { RegisterWarningContainer } from '../../register';

export const IHeaderContainer = ({
  headerBreadcrumbs = null,
  pageName = null,
}: IHeaderModel): Nullable<ReactElement> => {
  const router = useRouter();
  const { IS_MOBILE } = useTheme();
  const { user, userCoursesCount } = useUser();
  const translations = useTranslations();
  const coursesPath = getCoursesPath(router);
  const logoPreset = IS_MOBILE ? 'logoCompanySmall' : 'logoCompany';
  const logoSizes = {
    height: PRESETS[logoPreset].opts.h,
    width: PRESETS[logoPreset].opts.w,
  };

  const headerLink = getHeaderLink(coursesPath, translations);

  if (!user) {
    return null;
  }

  const switcherLinks = getMenuSwitcherLinks(
    coursesPath,
    translations,
    userCoursesCount,
    pageName
  );

  return (
    <>
      <S.IHeader>
        <S.IHeaderTop>
          <S.IHeaderLogo>
            <LogoContainer
              href={urlService.getFullUrlFromToPage(coursesPath)}
              logo={user?.company?.logo}
              preset={logoPreset}
              {...logoSizes}
            />
          </S.IHeaderLogo>
          {headerLink && (
            <IHeaderLink href={headerLink.href}>{headerLink.title}</IHeaderLink>
          )}
          <MenuSwitcherContainer
            hiddenAfter="mediumDown"
            links={switcherLinks}
          />
          <IUserBarContainer />
        </S.IHeaderTop>
        <RegisterWarningContainer />
      </S.IHeader>
      <S.IHeaderBreadcrumbs>{headerBreadcrumbs}</S.IHeaderBreadcrumbs>
    </>
  );
};
