import React, { ReactElement } from 'react';
import { TextControlComponent, useTranslations } from '@lerna-core';
import { PromoCodeInputModel } from './promoCodeInput.model';

export const PromoCodeInputContainer = ({
  values,
  errors,
  touched,
  onChange,
  onBlur,
  setFieldValue,
  disabled,
}: PromoCodeInputModel): ReactElement => {
  const translations = useTranslations();

  return (
    <TextControlComponent
      type="text"
      name="promoCode"
      value={values.promoCode}
      onChange={onChange}
      onBlur={onBlur}
      placeholder={translations?.promo_code}
      floatingPlaceholder={false}
      disabled={disabled}
      touched={touched.promoCode}
      errorMessage={errors.promoCode}
      showResetButton
      setFieldValue={setFieldValue}
      margin="0 0 20px 0"
    />
  );
};
