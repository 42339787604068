import React, { ReactElement } from 'react';
import { ContentLeftContainer } from './contentLeft';
import { ContentRightContainer } from './contentRight';
import { FormContentStyled } from './formContent.styled';
import { FormContentContainerProps } from './formContent.model';
import { ContentStatusContainer } from './contentStatus';

export const FormContentContainer = ({
  formGranuleData,
}: FormContentContainerProps): ReactElement => (
  <FormContentStyled>
    <ContentLeftContainer course={formGranuleData.course} />
    <ContentRightContainer formGranuleData={formGranuleData} />
    {formGranuleData.showFormStatus && (
      <ContentStatusContainer {...formGranuleData} />
    )}
  </FormContentStyled>
);
