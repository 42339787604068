import React, { ReactElement, useMemo } from 'react';
import {
  FilterModel,
  FilterTypeEnum,
  getCoursesPath,
  InputSizes,
  Nullable,
} from '@lerna-core';
import {
  FilterIconContainer,
  FilterToggleContainer,
  FilterTypeItem,
  isCustomCheckboxFilter,
} from '@features/common/filters';
import { DesktopFilterContainerProps } from './desktopFilter.model';
import {
  FiltersPopupDesktopFilterStyled,
  FiltersPopupDesktopToggleFilterStyled,
} from './desktopFilter.styled';

import { useRouter } from 'next/router';

export const DesktopFilterContainer = ({
  filter,
  customFilterColor,
}: DesktopFilterContainerProps): Nullable<ReactElement> => {
  const router = useRouter();
  const defaultPathname = getCoursesPath(router);
  const isToggle = filter.type === FilterTypeEnum.toggle;
  const filterColor =
    customFilterColor || (filter as FilterModel).activeButtonColor;
  const filterBackgroundColor = (filter as FilterModel).backgroundColor;
  const filterIconColor = (filter as FilterModel).iconColor;
  const isCustomFilter = useMemo(
    () => isCustomCheckboxFilter(filter.slug),
    [filter.slug]
  );
  const filterIcon = (
    <FilterIconContainer
      filterSlug={filter.slug}
      background={filterIconColor}
    />
  );

  if (!filter.isVisible) {
    return null;
  }

  return isToggle ? (
    <FiltersPopupDesktopToggleFilterStyled isCustomFilter={isCustomFilter}>
      <FilterToggleContainer
        filter={filter as FilterModel}
        withTooltip={false}
        analyticsType="fixed"
      />
    </FiltersPopupDesktopToggleFilterStyled>
  ) : (
    <FiltersPopupDesktopFilterStyled
      isCustomFilter={isCustomFilter}
      background={filterBackgroundColor}
    >
      <FilterTypeItem
        rangeInputSize={InputSizes.small}
        filter={filter}
        withTitle={true}
        withSearch={false}
        isCollapsed={true}
        withTooltip={false}
        defaultPathname={defaultPathname}
        icon={filterIcon}
        filterColor={filterColor}
        analyticsType="fixed"
      />
    </FiltersPopupDesktopFilterStyled>
  );
};
