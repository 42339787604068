import React, { ReactElement, useContext, useMemo } from 'react';
import {
  CourseHeadContainerStyled,
  CourseHeadFormButtonStyled,
  CourseHeadRowStyled,
  CourseHeadTitleWrapStyled,
} from './courseHead.styled';
import { CoursePropsContainer } from '../courseProps';
import { CourseSubTitleContainer } from '../courseSubTitle';
import { CourseTitleContainer, CourseTitleType } from '../courseTitle';
import {
  browserService,
  DeviceInfoContext,
  getCourseVendorById,
  getVendors,
  Nullable,
} from '@lerna-core';
import {
  CourseLogoContainer,
  CourseLogoSizeEnum,
  getCourseLogoProps,
} from '../courseLogo';
import { CoursePaperStyled } from '../coursePaper';
import { CourseHeadContainerProps } from './courseHeader.model';
import { CourseFormButtonContainer } from '../courseFormButton';
import { useCoursePageContext } from '../course.context';
import { useSelector } from 'react-redux';

export const CourseHeadContainer = ({
  hideSidebar,
}: CourseHeadContainerProps): Nullable<ReactElement> => {
  const { course } = useCoursePageContext();
  const vendors = useSelector(getVendors);
  const vendor = getCourseVendorById(vendors, course.vendorUuid);
  const deviceContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceContext);

  const logoProps = useMemo(
    () =>
      getCourseLogoProps(
        isMobile ? CourseLogoSizeEnum.small : CourseLogoSizeEnum.big
      ),
    []
  );

  return (
    <CoursePaperStyled>
      <CourseHeadContainerStyled>
        <CourseHeadRowStyled>
          <CourseHeadTitleWrapStyled>
            <CourseTitleContainer
              title={course.title}
              type={CourseTitleType.h1}
            />
            {!!vendor?.title && (
              <CourseSubTitleContainer subtitle={vendor.title} />
            )}
          </CourseHeadTitleWrapStyled>
          {course.logo && (
            <CourseLogoContainer {...logoProps} logo={course.logo} />
          )}
        </CourseHeadRowStyled>
        <CoursePropsContainer />
        {hideSidebar && (
          <CourseHeadFormButtonStyled>
            <CourseFormButtonContainer />
          </CourseHeadFormButtonStyled>
        )}
      </CourseHeadContainerStyled>
    </CoursePaperStyled>
  );
};
