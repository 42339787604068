import React, { ReactElement } from 'react';
import { FiltersShowAllContainerProps } from './filtersShowAll.model';
import { ArrowStyled, FiltersShowAllStyled } from './filtersShowAll.styled';
import { IconArrowLongComponent, useTheme, useTranslations } from '@lerna-core';
import { useCoursesPageContext } from '../../../coursesPage';

export const FiltersShowAllContainer = ({
  handleClick,
}: FiltersShowAllContainerProps): ReactElement => {
  const translations = useTranslations();
  const theme = useTheme();

  const {
    additionalColors: { filtersAccentColor },
  } = useCoursesPageContext();

  return (
    <FiltersShowAllStyled
      customColor={filtersAccentColor}
      onClick={handleClick}
    >
      {translations?.show_all_filters}
      <ArrowStyled>
        <IconArrowLongComponent
          width={16}
          height={16}
          strokeColor={filtersAccentColor || theme.COLOR.PRIMARY[500]}
        />
      </ArrowStyled>
    </FiltersShowAllStyled>
  );
};
