import React, { ReactElement } from 'react';
import {
  getVendors,
  IconInfoCircledComponent,
  Nullable,
  TagLabelColor,
  TagLabelContainer,
  TagLabelIconPosition,
  useTheme,
  useTranslations,
} from '@lerna-core';
import { TooltipContainer } from '@features/common/tooltip';
import { CompanyCompensationTagProps } from './companyCompensationTag.model';
import { CompanyCompensationTagStyled } from './companyCompensationTag.styled';
import { useCompanyCompensationTag } from './companyCompensationTag.hook';
import { useSelector } from 'react-redux';

export const CompanyCompensationTagContainer = ({
  price,
  size,
  course,
  courseIndex,
  borderRadius = 100,
}: CompanyCompensationTagProps): Nullable<ReactElement> => {
  const theme = useTheme();
  const translations = useTranslations();
  const vendors = useSelector(getVendors);
  const { showTag, tagLabel, tooltipContent, handleTooltipOpen } =
    useCompanyCompensationTag(
      price,
      translations,
      course,
      vendors,
      courseIndex
    );

  if (!showTag) return null;

  return (
    <TooltipContainer content={tooltipContent} onOpen={handleTooltipOpen}>
      <CompanyCompensationTagStyled>
        <TagLabelContainer
          noWrap={false}
          label={tagLabel}
          color={TagLabelColor.green}
          size={size}
          iconPosition={TagLabelIconPosition.right}
          borderRadius={borderRadius}
          icon={
            <IconInfoCircledComponent
              fillColor={theme.COLOR.INFO_GREEN_COLOR}
              strokeColor={theme.COLOR.INFO_GREEN_COLOR}
            />
          }
        />
      </CompanyCompensationTagStyled>
    </TooltipContainer>
  );
};
