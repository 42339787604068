import React, { ReactElement, useMemo } from 'react';

import { getDefaultColWidth } from '../coursesTransfer.service';
import { useSelector } from 'react-redux';
import {
  requestTransferCoursesAllUnallocated,
  requestTransferCoursesUnallocated,
} from '../coursesTransfer.actions';
import { getUnallocatedEmptyContent } from './transferLeft.service';
import { TransferLeftStyled } from './transferLeft.styled';

import { CoursesTransferTableContainer } from '../transferTable';
import {
  getCoursesTransferSelector,
  getCoursesTransferUnallocatedFiltersSelector,
} from '../coursesTransfer.selectors';
import { useCoursesTransferContext } from '../coursesTransfer.context';
import {
  LocationType,
  TransferLeftProps,
  TransferTitleContainer,
} from '../../../transfer';

export const TransferLeftContainer = ({
  getSelectedRows,
  leftRows,
  leftColumns,
  handleCheck,
  headerComponent,
  translations,
}: TransferLeftProps): ReactElement => {
  const courses = useSelector(getCoursesTransferSelector);
  const {
    added,
    removed,
    unallocated: {
      filters,
      meta,
      isLoading,
      isPaginating,
      isSelectedAll,
      isPending: isPendingUnallocatedCourses,
    },
    allocated: { isPending: isPendingAllocatedCourses },
  } = courses;
  const isPending = isPendingUnallocatedCourses || isPendingAllocatedCourses;
  const {
    id,
    courseExclusionCompanyId,
    isRadioButton,
    accessors,
    coursesAllProvider,
    coursesProvider,
    dispatch,
  } = useCoursesTransferContext();
  const coursesUnallocatedFilters = useSelector(
    getCoursesTransferUnallocatedFiltersSelector
  );

  const handlePagination = (): void => {
    dispatch(
      requestTransferCoursesUnallocated(
        coursesProvider,
        {
          ...filters,
          id,
          notRelatedWithCourseExclusionsForCompanyId: courseExclusionCompanyId,
          added,
          removed,
          page: meta.currentPage + 1,
        },
        true
      )
    );
  };

  const handleCheckAll = (): void => {
    if (meta.totalPages > meta.currentPage) {
      dispatch(
        requestTransferCoursesAllUnallocated(coursesAllProvider, {
          ...filters,
          id,
          notRelatedWithCourseExclusionsForCompanyId: courseExclusionCompanyId,
          added,
          removed,
          limit: 500,
          pages: { current: 0, last: Math.ceil(meta.total / 500) },
        })
      );
    }
  };

  const title = isRadioButton
    ? translations.select_course
    : translations.select_courses;

  const emptyContent = useMemo(
    () =>
      getUnallocatedEmptyContent(
        translations,
        coursesUnallocatedFilters,
        courses
      ),
    [coursesUnallocatedFilters, courses]
  );

  return (
    <TransferLeftStyled>
      <TransferTitleContainer translations={translations} title={title} />
      <CoursesTransferTableContainer
        defaultColWidth={getDefaultColWidth(accessors.length + 1, 2)}
        handleCheck={handleCheck}
        handleCheckAll={handleCheckAll}
        tableData={{ rows: leftRows, columns: leftColumns }}
        getSelectedRows={getSelectedRows}
        handlePagination={handlePagination}
        isRadioButton={isRadioButton}
        meta={meta}
        isLoading={isLoading && !isPending}
        isPaginating={isPaginating}
        headerComponent={headerComponent}
        emptyContent={emptyContent}
        isSelectedAll={isSelectedAll}
        locationType={LocationType.unallocated}
      />
    </TransferLeftStyled>
  );
};
