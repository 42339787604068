import styled from 'styled-components';

import { TableHeaderStyledProps } from './tableHeader.model';
import { BORDER } from '../table.styled';
import { fontSettings } from '../../../styles';
import { getStickyColumnStyles } from '../table.mixin';
import { ColumnStickyModel } from 'react-table';

export const TableHeaderStyled = styled.thead<TableHeaderStyledProps>``;

export const TableHeaderRowStyled = styled.tr`
  display: flex;
  align-items: stretch;
  flex: 1 0 auto;

  & > * {
    border-bottom: ${BORDER};
  }
`;

export const TableHeaderHeadStyled = styled.th<{ sticky?: ColumnStickyModel }>`
  box-sizing: border-box;
  padding: 20px 10px;
  text-align: left;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  color: ${({ theme }): string => theme.COLOR.TEXT_DARK_COLOR};
  font-weight: 500;

  ${({ sticky }) => getStickyColumnStyles(sticky)};

  &:first-child {
    padding-left: 20px;
  }

  &:last-child {
    padding-right: 20px;
  }

  .resizer {
    display: inline-block;
    background: ${({ theme }) => theme.COLOR.BLACK_08};
    width: 1px;

    // нужно что бы увеличить ширину ресайзера
    border-right: 2px solid ${({ theme }) => theme.COLOR.WHITE};
    border-left: 2px solid ${({ theme }) => theme.COLOR.WHITE};

    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    transform: translateX(50%);
    z-index: 1;
    touch-action: none;
  }
`;

export const TableHeaderCellStyled = styled.th`
  width: 100%;
  align-self: center;
  padding: 0;
`;

export const TableHeaderCellCentered = styled.div`
  text-align: center;
`;

export const CustomHeaderCellStyled = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
`;
