import { NextRouter } from 'next/router';
import {
  CompanyUuidEnum,
  IconNames,
  Nullable,
  publicConfig,
  QueryParams,
  TranslationModel,
  urlService,
  UserAggregateModel,
  UserMenuLink,
} from '@lerna-core';
import { pageUrls } from '@constants';
import { getB2BLandingLink } from '@features/common/b2bLandingLink';
import { HeaderColorTypes, HeaderLinkTypes } from './header.model';
import { getDiscountProgramPageUrl } from '../discountProgram';

export const getHomePageLink = (router: NextRouter): string => {
  const discountProgramQuery = router.query[
    QueryParams.discountProgram
  ] as string;

  if (discountProgramQuery) {
    return getDiscountProgramPageUrl(router, discountProgramQuery);
  }

  return urlService.getFullUrlToPage(pageUrls.courses, router);
};

export const getHeaderMobileStaticLinks = (
  translations: TranslationModel,
  headerLinkType?: HeaderLinkTypes
): Nullable<UserMenuLink[]> => {
  if (
    headerLinkType &&
    headerLinkType === HeaderLinkTypes.b2bLanding &&
    publicConfig?.HAS_B2B_LANDING === 'true'
  ) {
    const b2bLandingLink = getB2BLandingLink();

    return [
      {
        title: translations?.b2b_link_label,
        link: b2bLandingLink,
        icon: IconNames.portfolio,
      },
    ];
  }

  return null;
};

export const getHeaderColorType = (
  user: Nullable<UserAggregateModel>,
  isDiscountProgramPage: boolean,
  withBreadcrumbs: boolean
): HeaderColorTypes => {
  switch (true) {
    case Boolean(user?.career?.companyId) ||
      isDiscountProgramPage ||
      withBreadcrumbs:
      return HeaderColorTypes.white;
    default:
      return HeaderColorTypes.default;
  }
};

export const isCollaborateWithLerna = (
  user: Nullable<UserAggregateModel>,
  isDiscountProgramPage: boolean
): boolean =>
  user?.career?.companyId === CompanyUuidEnum.wildberries &&
  !isDiscountProgramPage;
