import React, { ReactElement, useMemo } from 'react';
import { PricesInfoContainerProps } from './customerPriceInfo.model';
import { CustomerPriceInfoStyled } from './customerPriceInfo.styled';
import { PricesInstallmentContainer } from '../../pricesInstallment';
import { PricesTotalDiscountContainer } from '../../pricesTotalDiscount';
import { PricesTotalContainer } from '../../pricesTotal';
import { getCustomerPrices } from '@features/common/course';
import { getCurrencies, useTranslations } from '@lerna-core';
import { useSelector } from 'react-redux';

export const CustomerPriceInfoContainer = ({
  course,
  withInstallment,
}: PricesInfoContainerProps): ReactElement => {
  const translations = useTranslations();
  const currencies = useSelector(getCurrencies);
  const {
    hasTotalDiscount,
    totalDiscount,
    totalPrice,
    hasOneOfDiscounts,
    hasInstallment,
  } = useMemo(() => getCustomerPrices(course, currencies), []);

  const showInstallment =
    withInstallment &&
    hasInstallment &&
    !course.isFree &&
    !course.isFreeByDiscount;
  const showTotalPrice =
    !withInstallment ||
    !hasInstallment ||
    course.isFree ||
    course.isFreeByDiscount;
  const showTotalDiscount = hasTotalDiscount && !course.isFree;
  const totalPriceTitle =
    hasOneOfDiscounts && !course.isFree
      ? translations?.total_price_label
      : translations?.item_price_label;

  return (
    <CustomerPriceInfoStyled>
      {showInstallment && (
        <PricesInstallmentContainer
          installment={course.price?.priceCustomer?.installment}
          hasDiscounts={hasOneOfDiscounts}
        />
      )}
      {showTotalPrice && (
        <PricesTotalContainer title={totalPriceTitle} value={totalPrice} />
      )}
      {showTotalDiscount && (
        <PricesTotalDiscountContainer
          discount={totalDiscount}
          isMegaDiscount={course.megaDiscount}
        />
      )}
    </CustomerPriceInfoStyled>
  );
};
