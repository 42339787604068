import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { Nullable, useToggle, useTranslations } from '@lerna-core';
import {
  CollapseButtonStyled,
  LeftDescriptionStyled,
  TextStyled,
} from './leftDescription.styled';
import { LeftDescriptionContainerProps } from './leftDescription.model';
import { needToCollapse } from './leftDescription.helper';

export const LeftDescriptionContainer = ({
  description,
  isMobile,
  handleCollapseClick,
}: LeftDescriptionContainerProps): ReactElement => {
  const translations = useTranslations();
  const descriptionRef = useRef<Nullable<HTMLDivElement>>(null);

  const [descriptionHeight, setDescriptionHeight] =
    useState<Nullable<number>>(null);
  const [isCollapsed, toggleCollapsed, setIsCollapsed] = useToggle(false);

  const handleClick = (): void => {
    handleCollapseClick && handleCollapseClick();

    toggleCollapsed();
  };

  useEffect(() => {
    descriptionRef.current &&
      setDescriptionHeight(descriptionRef?.current?.clientHeight);
  }, [descriptionRef.current]);

  useEffect(() => {
    if (descriptionHeight && needToCollapse(descriptionHeight, isMobile)) {
      setIsCollapsed(true);
    }
  }, [descriptionHeight]);

  return (
    <LeftDescriptionStyled ref={descriptionRef} isCollapsed={isCollapsed}>
      <TextStyled dangerouslySetInnerHTML={{ __html: description }} />
      {isCollapsed && (
        <CollapseButtonStyled onClick={handleClick}>
          {translations?.more_details}
        </CollapseButtonStyled>
      )}
    </LeftDescriptionStyled>
  );
};
