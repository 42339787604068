import {
  CourseTypes,
  getDurationsString,
  Nullable,
  TagLabelSize,
  useTranslations,
} from '@lerna-core';
import React, { ReactElement } from 'react';
import {
  ListItemDurationsBulletStyled,
  ListItemDurationsStyled,
  ListItemLabelStyled,
  ListItemLabelValueStyled,
} from '../listItem.styled';
import { GranuleTagContainer } from '../listItemTags';
import { ListItemPropsContainerProp } from './listItemProps.model';
import { ListItemPropsContainerStyled } from './listItemProps.styled';

export const ListItemPropsContainer = ({
  course,
}: ListItemPropsContainerProp): Nullable<ReactElement> => {
  const translations = useTranslations();
  const isGranule = course.type === CourseTypes.granule;

  if (
    !(course.startDate && !course.subscriptionAccess) &&
    !course.durations &&
    !isGranule
  ) {
    return null;
  }

  return (
    <ListItemPropsContainerStyled>
      {(isGranule || !!course.durations) && (
        <ListItemLabelStyled>
          {isGranule && (
            <GranuleTagContainer
              thin
              label={translations?.mini_course}
              size={TagLabelSize.normal}
            />
          )}
          {isGranule && !!course.durations && (
            <ListItemDurationsBulletStyled>
              {'\u2022'}
            </ListItemDurationsBulletStyled>
          )}
          {!!course.durations && (
            <ListItemDurationsStyled>
              {getDurationsString(course.durations)}
            </ListItemDurationsStyled>
          )}
        </ListItemLabelStyled>
      )}
      {!!(course.startDate && !course.subscriptionAccess) && (
        <ListItemLabelStyled>
          <ListItemLabelValueStyled>
            {translations.item_start_label}
            {': '}
            {course.startDate}
          </ListItemLabelValueStyled>
        </ListItemLabelStyled>
      )}
    </ListItemPropsContainerStyled>
  );
};
