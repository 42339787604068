import React, { ReactElement } from 'react';
import {
  AgreementStyled,
  ButtonStyled,
  ContentRightStyled,
} from './contentRight.styled';
import { RightPricesContainer } from './rightPrices';
import { RightHeadContainer } from './rightHead';
import { useFormB2BWithoutCompensationContext } from '../../formB2BWithoutCompensation.context';
import {
  ButtonColor,
  ButtonContainer,
  CheckboxControlComponent,
  TextControlComponent,
  useTranslations,
} from '@lerna-core';

export const ContentRightContainer = (): ReactElement => {
  const translations = useTranslations();
  const {
    handleFormSubmit,
    values,
    touched,
    errors,
    setFieldValue,
    handleChange,
    handleBlur,
    isSubmitting,
    submitButtonTitle,
    isOnlinePaySelected,
    isFreeCourse,
  } = useFormB2BWithoutCompensationContext();

  const showPromoCodeInputs = !isFreeCourse && !isOnlinePaySelected;

  return (
    <ContentRightStyled>
      <RightHeadContainer />
      <RightPricesContainer />
      {showPromoCodeInputs && (
        <>
          <CheckboxControlComponent
            name="withPromoCode"
            checked={values.withPromoCode}
            onChange={handleChange}
            children={translations?.i_have_promo_code}
            disabled={isSubmitting}
          />
          {values.withPromoCode && (
            <TextControlComponent
              type="text"
              name="promoCode"
              value={values.promoCode}
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder={translations?.promo_code}
              floatingPlaceholder={false}
              disabled={isSubmitting}
              touched={touched.promoCode}
              errorMessage={errors.promoCode}
              showResetButton
              setFieldValue={setFieldValue}
            />
          )}
        </>
      )}
      <ButtonStyled>
        <ButtonContainer
          onClick={handleFormSubmit}
          color={ButtonColor.purple}
          borderRadius={8}
          title={submitButtonTitle}
          disabled={isSubmitting}
        />
      </ButtonStyled>
      <AgreementStyled
        dangerouslySetInnerHTML={{ __html: translations?.public_contract_text }}
      />
    </ContentRightStyled>
  );
};
