import React, { ReactElement } from 'react';
import {
  CourseDurationStyled,
  CourseStartDate,
  CourseTitleStyled,
  LeftCourseInfoStyled,
  WrapperStyled,
} from './leftCourseInfo.styled';
import { LeftCourseInfoContainerProps } from './leftCourseInfo.model';
import { getDurationsString, useTranslations } from '@lerna-core';

export const LeftCourseInfoContainer = ({
  course,
}: LeftCourseInfoContainerProps): ReactElement => {
  const translations = useTranslations();
  const showMoreInfo = course.durations || !!course.startDate;

  return (
    <LeftCourseInfoStyled>
      <CourseTitleStyled>{course.title}</CourseTitleStyled>
      {showMoreInfo && (
        <WrapperStyled>
          {course.durations && (
            <CourseDurationStyled>
              {translations?.item_duration_label}:{' '}
              {getDurationsString(course.durations)}
            </CourseDurationStyled>
          )}

          {!!course.startDate && (
            <CourseStartDate>
              {translations?.course_start}: {course.startDate}
            </CourseStartDate>
          )}
        </WrapperStyled>
      )}
    </LeftCourseInfoStyled>
  );
};
