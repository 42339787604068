import React, { ReactElement } from 'react';
import { ContentLeftStyled, TitleStyled } from './contentLeft.styled';
import { LeftCourseInfoContainer } from './leftCourseInfo';
import { useGuestOrderFormContext } from '../../guestOrderForm.context';
import { LeftPricesContainer } from './leftPrices';
import { LeftAdvantagesContainer } from './leftAdvantages';
import { useTranslations } from '@lerna-core';

export const ContentLeftContainer = (): ReactElement => {
  const { course } = useGuestOrderFormContext();
  const translations = useTranslations();

  return (
    <ContentLeftStyled>
      <TitleStyled>{translations?.sign_up_for_course}</TitleStyled>
      <LeftPricesContainer />
      <LeftAdvantagesContainer course={course} />
      <LeftCourseInfoContainer course={course} />
    </ContentLeftStyled>
  );
};
