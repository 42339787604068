import React, { ReactElement } from 'react';
import { AdvantagesItem, LeftAdvantagesStyled } from './leftAdvantages.styled';
import { getAdvantages } from './leftAdvantages.helper';
import { getVendors, Nullable } from '@lerna-core';
import { IconsCheckComponent } from '@features/common/forms/components/icons';
import { LeftAdvantagesContainerProps } from './leftAdvantages.model';
import { useSelector } from 'react-redux';

export const LeftAdvantagesContainer = ({
  course,
}: LeftAdvantagesContainerProps): Nullable<ReactElement> => {
  const vendors = useSelector(getVendors);
  const advantages = getAdvantages(course, vendors);

  if (!advantages.length) return null;

  return (
    <LeftAdvantagesStyled>
      {advantages.map(
        (advantage) =>
          !!advantage && (
            <AdvantagesItem key={advantage}>
              <IconsCheckComponent width={24} height={24} />
              {advantage}
            </AdvantagesItem>
          )
      )}
    </LeftAdvantagesStyled>
  );
};
