import React, { ReactElement, useCallback, useContext, useMemo } from 'react';
import {
  browserService,
  DeviceInfoContext,
  SliderContainer,
} from '@lerna-core';
import { CourseGalleryContainerProps } from './courseGallery.model';
import {
  getCurrentPageBySlider,
  getGalleryProps,
  needToLoadAdditionalCourses,
  setGalleryItems,
} from './courseGallery.helper';
import { CourseGalleryContainerStyled } from './courseGallery.styled';
import { GalleryItemContainer } from './galleryItem';

export const CourseGalleryContainer = ({
  similarCourses,
  showMore,
  additionalCoursesState,
  additionalCourses,
  isPricesHiddenOnListingCards,
}: CourseGalleryContainerProps): ReactElement => {
  const deviceContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceContext);
  const galleryProps = getGalleryProps(isMobile);
  const sliderItemsArr = useMemo(
    () =>
      setGalleryItems(similarCourses.pagination.total, [
        ...similarCourses.data,
        ...additionalCourses,
      ]),
    [additionalCourses, similarCourses]
  );

  const handleSliderScroll = useCallback(
    (activeSlideIndex: number): void => {
      const currentPageBySlider = getCurrentPageBySlider(
        similarCourses.pagination,
        activeSlideIndex
      );
      const needShowMore = needToLoadAdditionalCourses(
        similarCourses.pagination,
        activeSlideIndex,
        additionalCoursesState
      );

      if (needShowMore) {
        showMore(currentPageBySlider);
      }
    },
    [additionalCoursesState]
  );

  const sliderItems = sliderItemsArr.map((course, idx) => (
    <GalleryItemContainer
      key={idx}
      course={course}
      itemWidth={galleryProps.itemWidth}
      spaceBetweenItems={galleryProps.spaceBetweenItems}
      isMobile={isMobile}
      isPricesHiddenOnListingCards={isPricesHiddenOnListingCards}
    />
  ));

  return (
    <CourseGalleryContainerStyled>
      <SliderContainer
        isMobile={isMobile}
        items={sliderItems}
        spaceBetweenItems={galleryProps.spaceBetweenItems}
        itemWidth={galleryProps.itemWidth}
        handleSliderScroll={handleSliderScroll}
      />
    </CourseGalleryContainerStyled>
  );
};
