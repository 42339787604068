import React, { ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { SearchUnallocatedContainerProps } from './searchUnallocated.model';
import {
  requestTransferCoursesUnallocated,
  setTransferCoursesUnallocatedFilters,
} from '../../coursesTransfer.actions';
import { useSearch } from '../../../../../hooks';
import { TableSearchContainer } from '../../../../table';
import {
  getCoursesTransferSelector,
  getCoursesTransferUnallocatedFiltersSelector,
} from '../../coursesTransfer.selectors';
import { useCoursesTransferContext } from '../../coursesTransfer.context';
import {
  getSearchUnallocatedMinLength,
  getSearchUnallocatedPlaceholder,
} from './searchUnallocated.helper';

export const SearchUnallocatedContainer = ({
  translations,
  handleSearchCancel,
}: SearchUnallocatedContainerProps): ReactElement => {
  const { id, courseExclusionCompanyId, coursesProvider, dispatch } =
    useCoursesTransferContext();
  const {
    added,
    removed,
    unallocated: { filters },
  } = useSelector(getCoursesTransferSelector);
  const { search: initialSearch, searchTerm } = useSelector(
    getCoursesTransferUnallocatedFiltersSelector
  );

  const onSearch = (search: string): void => {
    dispatch(setTransferCoursesUnallocatedFilters({ search }));
    dispatch(
      requestTransferCoursesUnallocated(coursesProvider, {
        ...filters,
        notRelatedWithCourseExclusionsForCompanyId: courseExclusionCompanyId,
        id,
        added,
        removed,
        search,
        searchTerm,
      })
    );
  };

  const { search, setSearch } = useSearch({
    onSearch,
    initialSearch,
    minSearchLength: getSearchUnallocatedMinLength(searchTerm),
  });

  return (
    <TableSearchContainer
      search={search}
      placeholder={getSearchUnallocatedPlaceholder(translations, searchTerm)}
      onChange={setSearch}
      cancelText={translations.cancel}
      onCancelClick={handleSearchCancel}
    />
  );
};
