import React, { ReactElement } from 'react';
import { CheckboxControlComponent, useTranslations } from '@lerna-core';
import { useDiscountProgram } from '@features/common/discountProgram';
import { FormPromoCodeModel } from './formPromoCode.model';
import { PromoCodeInputContainer } from './promoCodeInput';
import { PromoCodeControlledInputContainer } from './promoCodeControledInput';

export const FormPromoCode = (props: FormPromoCodeModel): ReactElement => {
  const { discountProgramQuery } = useDiscountProgram();
  const translations = useTranslations();

  return (
    <>
      <CheckboxControlComponent
        name="withPromoCode"
        checked={props.values.withPromoCode}
        onChange={props.onChange}
        children={translations?.i_have_promo_code}
        disabled={props.disabled}
        margin="0 0 20px 0"
      />
      {props.values.withPromoCode &&
        (!!discountProgramQuery ? (
          <PromoCodeControlledInputContainer {...props} />
        ) : (
          <PromoCodeInputContainer {...props} />
        ))}
    </>
  );
};
