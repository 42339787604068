import {
  AggregateResponseApiErrorModel,
  bannerAdapter,
  checkError,
  courseAdapter,
  discountProgramAdapter,
  fastLinksAdapter,
  filtersAdapter,
  mapUser,
  mapVendors,
  ShowcaseApiModel,
  showcaseDefaultAdapter,
  ShowcaseErrorModel,
} from '@lerna-core';
import { COURSES_PAGE_AGGREGATE_REQUIRED_KEYS } from './courses.constants';
import { CoursesPageModel } from './courses.model';

export const coursesAdapter = (
  response: ShowcaseApiModel
): CoursesPageModel | ShowcaseErrorModel => {
  const { data } = response;
  const translations = data.statics?.data.translations;

  checkError(
    data as unknown as AggregateResponseApiErrorModel,
    COURSES_PAGE_AGGREGATE_REQUIRED_KEYS
  );

  const filters = data.filters?.data
    ? filtersAdapter(data.filters.data, translations)
    : [];

  //todo: вынести showcaseDefaultAdapter отдельно по каталогам (убрать из либы)
  return {
    ...showcaseDefaultAdapter(response, COURSES_PAGE_AGGREGATE_REQUIRED_KEYS),
    filters,
    fastLinks: response.data.fastlinks
      ? fastLinksAdapter(response.data.fastlinks.data)
      : null,
    user:
      !!data?.personal && !!data?.bonuses
        ? mapUser(
            data?.personal,
            data.bonuses,
            data?.career,
            data?.companies?.data,
            data?.me_courses
          )
        : null,
    discountProgram: data?.discount_programs
      ? discountProgramAdapter(data.discount_programs)
      : null,
    banners: data?.banners ? data.banners.data.map(bannerAdapter) : null,
    selectedCourse: data?.selected_course
      ? courseAdapter(data.selected_course.data)
      : null,
    vendors: mapVendors(data.vendors?.data || []),
  };
};
