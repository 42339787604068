import styled from 'styled-components';
import { customizeScrollBar, fontSettings } from '../../../styles';
import { TableBodyRowProps, TableBodyStyledProps } from './tableBody.model';
import { getStickyColumnStyles } from '../table.mixin';
import { ColumnStickyModel } from 'react-table';

const getTableBodyScrollStyles = (scroll?: boolean): string =>
  scroll ? 'overflow: hidden;' : '';

export const TableBodyStyled = styled.tbody<TableBodyStyledProps>`
  position: relative;
  min-height: 500px;
  ${customizeScrollBar(20, 20, 4, false)}
  ${({ scroll }): string => getTableBodyScrollStyles(scroll)}
    height: ${({ height }): string => (height ? `${height}px` : 'auto')};
  border-radius: 0 0 16px 16px;
  overflow: ${({ hasStickyColumns }) =>
    hasStickyColumns ? 'visible' : 'hidden auto'};

  tr:last-child td:after {
    content: none;
  }
`;

const getHoverStyles = (hasHover: boolean): string => {
  if (hasHover) {
    const hover = 'rgb(245 245 245)';

    return `
      cursor: pointer;
      &:hover {
        background-color: ${hover};
        
        ${TableBodyDataStyled} {
          background-color: ${hover};
        }
      }
    `;
  }

  return '';
};

export const TableBodyDataStyled = styled.td<TableBodyDataStyledProps>`
  position: relative;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 20px 10px;
  text-align: left;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  color: ${({ theme, disabled }): string =>
    Boolean(disabled)
      ? theme.COLOR.TEXT_LIGHT_COLOR
      : theme.COLOR.TEXT_DARK_COLOR};
  font-weight: normal;

  ${({ sticky, rowIndex }) => getStickyColumnStyles(sticky, rowIndex)};

  &:first-child {
    padding-left: 20px;

    &:after {
      left: 20px;
    }
  }

  &:last-child {
    padding-right: 20px;

    &:after {
      right: 20px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: ${({ theme }) => theme.COLOR.BLACK_08};
  }
`;

export const TableBodyRowStyled = styled.tr<TableBodyRowProps>`
  display: flex;
  align-items: stretch;
  flex: 1;
  position: relative;

  ${({ hasHover }): string => getHoverStyles(hasHover)}
  &:last-child:not(:first-child) {
    ${TableBodyDataStyled} {
      &:after {
        content: none;
      }
    }
  }
`;

export type TableBodyDataStyledProps = {
  sticky?: ColumnStickyModel;
  disabled?: boolean;
  rowIndex: number;
};
