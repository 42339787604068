import React, { ReactElement } from 'react';
import { CoursePropsContainerStyled } from './courseProps.styled';
import { PropsItemContainer } from './propsItem';
import {
  CourseOptionLogicTypes,
  CourseOptionsSlug,
  coursePropAdapter,
  CoursePropModel,
  getDurationsString,
  Nullable,
  useTranslations,
} from '@lerna-core';
import { useCoursePageContext } from '@features/course/course.context';

export const CoursePropsContainer = (): Nullable<ReactElement> => {
  const { course } = useCoursePageContext();
  const translations = useTranslations();

  const getProps = (): CoursePropModel[] => {
    const props = [];
    const optionDifficultyType = course.options.find(
      (option) => option.logicType === CourseOptionLogicTypes.difficulty
    );
    if (optionDifficultyType) {
      props.push(
        coursePropAdapter(
          translations?.item_difficulty_label,
          optionDifficultyType.value,
          CourseOptionsSlug.difficulty
        )
      );
    }

    if (course.durations) {
      props.push(
        coursePropAdapter(
          translations?.item_duration_label,
          getDurationsString(course.durations),
          CourseOptionsSlug.duration
        )
      );
    }

    const optionLogicType = course.options.find(
      (option) => option.logicType === CourseOptionLogicTypes.types
    );
    if (optionLogicType) {
      props.push(
        coursePropAdapter(
          translations?.item_type_label,
          optionLogicType.value,
          CourseOptionsSlug.type
        )
      );
    }

    return props;
  };

  const props = getProps();

  if (props.length === 0) {
    return null;
  }

  return (
    <CoursePropsContainerStyled count={props.length}>
      {props.map((prop) => (
        <PropsItemContainer key={prop.slug} prop={prop} count={props.length} />
      ))}
    </CoursePropsContainerStyled>
  );
};
