import React, { ReactElement, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  browserService,
  CommonEventTypes,
  DeviceInfoContext,
  getCurrentPopupFromOpen,
  getVendors,
  isMBMCompany,
  logger,
  Nullable,
  PopupsEnum,
  requestClosePopup,
  useUser,
} from '@lerna-core';
import {
  FormArchiveContextModel,
  FormArchiveContextProviderProps,
} from './formArchive.model';
import { subscriptionClaimProvider } from '@features/common/subscriptionClaim';
import {
  SubscriptionAvailabilityModel,
  subscriptionAvailabilityProvider,
} from '@features/common/subscriptionAvailability';
import {
  OrderFormEvents,
  orderFormPushDataLayer,
  OrderFormRequestTypes,
  useGoogleSheetLoggerContext,
} from '@features/common/forms';
import { useCourseActiveContext } from '@features/courses/coursesActive';
import { useAppDispatch } from '@store/store.hooks';
import {
  getFormArchiveSubmitStatusEvent,
  pushFormArchiveSubmitStatusEvent,
} from './formArchive.helper';
import { UserOrderFormSubmitStatusTypes } from '../userOrderForm.model';

const FormArchiveContext =
  React.createContext<Nullable<FormArchiveContextModel>>(null);

export const FormArchiveContextProvider = ({
  props,
  children,
}: FormArchiveContextProviderProps): ReactElement => {
  const { user } = useUser();
  const vendors = useSelector(getVendors);
  const dispatch = useAppDispatch();
  const currentPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.userOrderFormPopup)
  );
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);
  const { removeNumericId } = useCourseActiveContext();
  const { googleSheetLogger } = useGoogleSheetLoggerContext();

  const [subscriptionAvailability, setSubscriptionAvailability] =
    useState<Nullable<SubscriptionAvailabilityModel>>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isSuccessDisplayed, setIsSuccessDisplayed] = useState<boolean>(false);
  const [isErrorDisplayed, setIsErrorDisplayed] = useState<boolean>(false);

  const showFormStatus = isSuccessDisplayed || isErrorDisplayed;
  const hasSubscription = !!props.course.subscriptionAccess;
  const showAdvantages = !isMBMCompany(user?.career?.companyId);

  const handlePopupClose = async (): Promise<void> => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollClose,
      CommonEventTypes.coursesEvents,
      props.eventPosition,
      props.course,
      vendors,
      props.courseIndex
    );

    await dispatch(requestClosePopup(PopupsEnum.userOrderFormPopup)).then(
      () => {
        if (typeof removeNumericId === 'function') {
          removeNumericId();
        }
      }
    );
    clearData();
  };

  const clearData = (): void => {
    setIsLoading(true);
    setSubscriptionAvailability(null);
    isSuccessDisplayed && setIsSuccessDisplayed(false);
    isErrorDisplayed && setIsErrorDisplayed(false);
  };

  const handleSuccess = (): void => {
    props.handleFormSent();
    pushFormArchiveSubmitStatusEvent(
      UserOrderFormSubmitStatusTypes.success,
      props.course,
      vendors,
      props.eventPosition,
      undefined,
      OrderFormRequestTypes.b2b
    );
    setIsSuccessDisplayed(true);
  };

  const handleError = (): void => {
    pushFormArchiveSubmitStatusEvent(
      UserOrderFormSubmitStatusTypes.error,
      props.course,
      vendors,
      props.eventPosition
    );
    setIsErrorDisplayed(true);
  };

  const handleSubmit = (): void => {
    setIsLoading(true);

    const formEventValues = getFormArchiveSubmitStatusEvent(props.course);

    googleSheetLogger(formEventValues);
    subscriptionClaimProvider(props.course.uuid)
      .then(handleSuccess)
      .catch((error): void => {
        handleError();
        logger.error(`[ERROR]: error sending request. ${error.response?.data}`);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (currentPopup && !subscriptionAvailability) {
      subscriptionAvailabilityProvider(props.course.uuid)
        .then((data) => {
          setSubscriptionAvailability(data);
        })
        .catch((err) => {
          logger.error('[ERROR]: can not get subscription availability', err);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [currentPopup]);

  const context = {
    ...props,
    currentPopup,
    handleSubmit,
    clearData,
    handlePopupClose,
    showFormStatus,
    showAdvantages,
    hasSubscription,
    isSuccessDisplayed,
    isErrorDisplayed,
    isLoading,
    isMobile,
    subscriptionAvailability,
  };

  return (
    <FormArchiveContext.Provider value={context}>
      {children}
    </FormArchiveContext.Provider>
  );
};

export const useFormArchiveContext = (): FormArchiveContextModel => {
  const value = useContext(FormArchiveContext);

  if (!value) {
    logger.error('[ERROR]: you cannot use context without a provider');

    return {} as FormArchiveContextModel;
  }

  return value;
};
