import { ApiCashBackModel } from '../../../models';
import { ApiCoursePricesModel, CoursePricesModel } from './coursePrices.model';
import {
  installmentAdapter,
  mapCashBack,
  priceCustomerAdapter,
  priceWithDiscountAdapter,
  priceWithProgramDiscountAdapter,
  priceWithPromoCodeDiscountAdapter,
} from '../../../adapters';

export const coursePricesAdapter = (
  priceData: ApiCoursePricesModel,
  bonuses?: ApiCashBackModel
): CoursePricesModel => {
  const price: CoursePricesModel = {
    price: priceWithDiscountAdapter(priceData.price),
    startPrice: {
      amount: priceData.start_price.amount,
      currency: priceData.start_price.currency,
    },
    startPriceCustomer: priceData.start_price_customer
      ? {
          amount: priceData.start_price_customer.amount,
          currency: priceData.start_price_customer.currency,
        }
      : null,
    startPriceCustomerB2B: priceData.start_price_customer_b2b
      ? {
          amount: priceData.start_price_customer_b2b.amount,
          currency: priceData.start_price_customer_b2b.currency,
        }
      : null,
    startPriceCustomerB2B2C: priceData.start_price_customer_b2b2c
      ? {
          amount: priceData.start_price_customer_b2b2c.amount,
          currency: priceData.start_price_customer_b2b2c.currency,
        }
      : null,
    priceWithVendorDiscount: !!priceData?.price_with_vendor_discount
      ? priceWithDiscountAdapter(priceData.price_with_vendor_discount)
      : null,
    priceWithBonuses: !!priceData?.price_with_bonuses
      ? priceWithDiscountAdapter(priceData.price_with_bonuses)
      : null,
    priceWithProgramDiscount: !!priceData?.price_with_program_discount
      ? priceWithProgramDiscountAdapter(priceData.price_with_program_discount)
      : null,
    priceWithPromoCodeDiscount: !!priceData?.price_with_promo_code_discount
      ? priceWithPromoCodeDiscountAdapter(
          priceData.price_with_promo_code_discount
        )
      : null,
    installment: !!priceData?.installment?.duration
      ? installmentAdapter(priceData.installment)
      : null,
    priceCustomer: !!priceData?.price_customer
      ? priceCustomerAdapter(priceData.price_customer)
      : null,
    priceCustomerB2B: !!priceData?.price_customer_b2b
      ? priceCustomerAdapter(priceData.price_customer_b2b)
      : null,
    priceCustomerB2B2C: !!priceData?.price_customer_b2b2c
      ? priceCustomerAdapter(priceData.price_customer_b2b2c)
      : null,
  };

  if (bonuses) {
    price.cashBack = mapCashBack(bonuses);
  }

  return price;
};
