import React, { ReactElement, useEffect, useRef, useState } from 'react';
import {
  CollapseButtonStyled,
  RightDescriptionStyled,
  TextStyled,
} from './rightDescription.styled';
import { RightDescriptionContainerProps } from './rightDescription.model';
import { Nullable, useToggle, useTranslations } from '@lerna-core';
import { needToCollapse } from './rightDescription.helper';

export const RightDescriptionContainer = ({
  description,
  isMobile,
  handleCollapseClick,
}: RightDescriptionContainerProps): ReactElement => {
  const translations = useTranslations();
  const descriptionRef = useRef<Nullable<HTMLDivElement>>(null);

  const [descriptionHeight, setDescriptionHeight] =
    useState<Nullable<number>>(null);
  const [isCollapsed, toggleCollapsed, setIsCollapsed] = useToggle(false);

  const handleClick = (): void => {
    handleCollapseClick && handleCollapseClick();

    toggleCollapsed();
  };

  useEffect(() => {
    descriptionRef.current &&
      setDescriptionHeight(descriptionRef?.current?.clientHeight);
  }, [descriptionRef.current]);

  useEffect(() => {
    if (descriptionHeight && needToCollapse(descriptionHeight, isMobile)) {
      setIsCollapsed(true);
    }
  }, [descriptionHeight]);

  return (
    <RightDescriptionStyled ref={descriptionRef} isCollapsed={isCollapsed}>
      <TextStyled dangerouslySetInnerHTML={{ __html: description }} />
      {isCollapsed && (
        <CollapseButtonStyled onClick={handleClick}>
          {translations?.more_details}
        </CollapseButtonStyled>
      )}
    </RightDescriptionStyled>
  );
};
