import { NextRouter } from 'next/router';
import {
  FastLinkModel,
  FilterModel,
  FilterOptionModel,
  filterRouterService,
  FiltersModel,
  FiltersSlugEnum,
  getCompositeFastLink,
  getFastLinkHref,
  urlService,
} from '@lerna-core';

export const isCustomCheckboxFilter = (slug: FiltersSlugEnum): boolean => {
  const customFilterSlugs = [
    FiltersSlugEnum.subscription,
    FiltersSlugEnum.wb,
    FiltersSlugEnum.onlyMiniCourses,
  ];

  return customFilterSlugs.includes(slug);
};

export const selectFilterOption = (
  router: NextRouter,
  isSelected: boolean,
  filter: FilterModel,
  option: FilterOptionModel,
  filters: FiltersModel[],
  fastLinks: FastLinkModel[],
  defaultPathname: string,
  callback?: () => void
): void => {
  const newQuery = filterRouterService.getNewRouteCheckbox(
    {
      ...router,
      query: filterRouterService.getNewRouteFromFilters(router, filters),
    },
    isSelected,
    filter.slug,
    option.slug
  );

  const fastLink = getCompositeFastLink(fastLinks, newQuery);

  if (fastLink) {
    router
      .push(
        getFastLinkHref(fastLink.slug, defaultPathname, newQuery),
        undefined,
        {
          scroll: false,
        }
      )
      .then(() => {
        if (!!callback) {
          callback();
        }
      });
  } else {
    router
      .push(
        urlService.createUrlFromPathAndQuery(defaultPathname, router, newQuery),
        undefined,
        { scroll: false }
      )
      .then(() => {
        if (!!callback) {
          callback();
        }
      });
  }
};
