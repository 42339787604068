import { ApiCourseModel, courseAdapter, CourseModel } from '../course';
import { CoursesModel, CoursesResponseApiModel } from './courses.model';
import { mapMetaPaginationAdapter } from '../../adapters';

export const coursesAdapter = (apiCourses: ApiCourseModel[]): CourseModel[] =>
  apiCourses.map((course) => courseAdapter(course));

export const coursesResponseAdapter = ({
  data,
}: CoursesResponseApiModel): CoursesModel => ({
  data: coursesAdapter(data.data),
  meta: mapMetaPaginationAdapter(data.meta.pagination),
});
