import { CourseItemProgressItemModel } from './courseItemProgressItem';
import { Nullable, TranslationModel } from '../../../../models';
import { CourseItemProgressModel } from '../courseItem.model';
import { VendorEnum } from '../../../vendors';

export const getProgressItems = (
  vendorSlug: Nullable<VendorEnum>,
  progress: CourseItemProgressModel,
  translations: TranslationModel
): CourseItemProgressItemModel[] => {
  switch (vendorSlug) {
    case VendorEnum.skillfactory:
    case VendorEnum.geekbrains:
      return [
        {
          title: translations?.progress_lessons,
          count: progress.total.total,
          countDone: progress.total.done,
        },
      ];
    default: {
      return [
        {
          title: translations.progress_modules,
          count: progress.modules.total,
          countDone: progress.modules.done,
        },
        {
          title: translations.progress_videos,
          count: progress.videos.total,
          countDone: progress.videos.done,
        },
        {
          title: translations.progress_practical_works,
          count: progress.practical.total,
          countDone: progress.practical.done,
        },
        {
          title: translations.progress_tests,
          count: progress.tests.total,
          countDone: progress.tests.done,
        },
        {
          title: translations.progress_final_works,
          count: progress.diplomas.total,
          countDone: progress.diplomas.done,
        },
      ];
    }
  }
};
