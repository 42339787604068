import React, { ReactElement, useState } from 'react';
import { ListItemStyled } from './listItem.styled';
import { ListItemContainerProps } from './listItem.model';
import { ItemPopupContainer } from './itemPopup';
import { ItemContentContainer } from './itemContent';

export const ListItemContainer = ({
  title,
  subtitle,
  popup,
}: ListItemContainerProps): ReactElement => {
  const [isOpenedPopup, setIsOpenedPopup] = useState(false);

  const handleOpenPopup = (): void => {
    setIsOpenedPopup(true);
  };
  const handleClose = (): void => {
    setIsOpenedPopup(false);
  };

  return (
    <ListItemStyled>
      <ItemContentContainer
        title={title}
        subtitle={subtitle}
        showPopupTrigger={!!popup}
        handleOpenPopup={handleOpenPopup}
      />
      {!!popup && (
        <ItemPopupContainer
          isOpened={isOpenedPopup}
          handleClose={handleClose}
          popup={popup}
        />
      )}
    </ListItemStyled>
  );
};
