import React, { ReactElement, useMemo } from 'react';
import { useRouter } from 'next/router';
import {
  CommonEventTypes,
  getCoursePageHref,
  getVendors,
  pushDataLayer,
} from '@lerna-core';
import { SimilarItemContainerProps } from './similarItem.model';
import {
  GoToCourseEvent,
  ListItemButtonsEvent,
  ListItemContainer,
} from '@features/courses/coursesList';
import { getCourseEventData } from '@features/course/course.analytics';
import {
  CourseSimilarEvent,
  CourseSimilarEventPosition,
} from '../courseSimilar.analytics';
import { SimilarItemContainerStyled } from './similarItem.styled';
import { useSelector } from 'react-redux';

export const SimilarItemContainer = ({
  course,
  isPricesHiddenOnListingCards,
}: SimilarItemContainerProps): ReactElement => {
  const router = useRouter();
  const vendors = useSelector(getVendors);
  const courseHref = useMemo(
    () =>
      getCoursePageHref({
        course,
        router,
        urlParams: {},
      }),
    [course]
  );

  const handleGoToCourse = (
    eventName: GoToCourseEvent | ListItemButtonsEvent
  ): void => {
    switch (eventName) {
      case GoToCourseEvent.goToCourseButton:
      case GoToCourseEvent.goToCourseCard: {
        pushDataLayer(
          getCourseEventData(
            CourseSimilarEvent.goToCoursePageBlockSimilarCourses,
            CommonEventTypes.coursesEvents,
            CourseSimilarEventPosition.coursePageBlockSimilarCourses,
            course,
            vendors
          )
        );
        break;
      }
    }
  };

  return (
    <SimilarItemContainerStyled>
      <ListItemContainer
        courseHref={courseHref}
        course={course}
        withButtons={false}
        handleGoToCourse={handleGoToCourse}
        withSphereFilter={false}
        isPricesHiddenOnListingCards={isPricesHiddenOnListingCards}
      />
    </SimilarItemContainerStyled>
  );
};
