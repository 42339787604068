import { IncomingMessage } from 'http';

export enum QueryParams {
  token = 'token',
  page = 'page',
  company = 'company',
  hash = 'hash',
  site = 'site',
  email = 'email',
  phone = 'phone',
  promoCode = 'promo_code',
  iframeInitiator = 'iframeInitiator',
  initiator = 'initiator',
  discountProgram = 'discount',
  referral = 'referral',
  redirectUrl = 'redirectUrl',
  showform = 'show_form',
  fullform = 'fullform',
  gauth = 'gauth',
  firstName = 'firstName',
  lastName = 'lastName',
  department = 'department',
  disableEmail = 'disable_email',
  numericId = 'numeric_id',
  tariff = 'tariff',
  title = 'title',
}

export enum LinkTargetEnum {
  blank = '_blank',
  self = '_self',
  top = '_top',
}

export type ExpandedReqModel = IncomingMessage & {
  originalUrl?: string;
};

export type TargetBlankOptionsProps = {
  rel: string;
  target: LinkTargetEnum;
};
