import {
  CommonEventTypes,
  getSimpleEvent,
  hasBonusPoints,
  Nullable,
  pushDataLayer,
  useUser,
} from '@lerna-core';
import React, { ReactElement } from 'react';
import { useCoursesPageContext } from '../../coursesPage';
import {
  CustomerBannerEvent,
  CustomerBannerEventPosition,
} from './advantagesCustomerBanners.analytics';
import { AdvantagesCustomerBannersContainerProps } from './advantagesCustomerBanners.model';
import { AdvantagesCustomerBannersContainerStyled } from './advantagesCustomerBanners.styled';
import { BannerBonusesContainer } from './bannerBonuses';
import { BannerCoursesContainer } from './bannerCourses';

export const AdvantagesCustomerBannersContainer = ({
  handleScrollToCourses,
}: AdvantagesCustomerBannersContainerProps): Nullable<ReactElement> => {
  const { currencies } = useCoursesPageContext();
  const { user, userCoursesCount } = useUser();

  if (!user) {
    return null;
  }

  const showBonuses = hasBonusPoints(user.career);
  const showBannerCourses = userCoursesCount > 0;

  const handleSpendPoints = (): void => {
    handleScrollToCourses();
    pushDataLayer(
      getSimpleEvent(
        CustomerBannerEvent.clickButtonSpendPoints,
        CommonEventTypes.overallEvents,
        CustomerBannerEventPosition.coursesPageBannerBonusAccount
      )
    );
  };
  const handleShowMorePoints = (): void => {
    pushDataLayer(
      getSimpleEvent(
        CustomerBannerEvent.clickButtonMoreDetails,
        CommonEventTypes.overallEvents,
        CustomerBannerEventPosition.coursesPageBannerBonusAccount
      )
    );
  };
  const handleClickGoToLms = (): void => {
    pushDataLayer(
      getSimpleEvent(
        CustomerBannerEvent.clickButtonGoToLearning,
        CommonEventTypes.overallEvents,
        CustomerBannerEventPosition.coursesPageBannerAccessToCourses
      )
    );
  };
  const handleClickGoToCourses = (): void => {
    pushDataLayer(
      getSimpleEvent(
        CustomerBannerEvent.clickButtonMyCourses,
        CommonEventTypes.overallEvents,
        CustomerBannerEventPosition.coursesPageBannerAccessToCourses
      )
    );
  };

  return (
    <AdvantagesCustomerBannersContainerStyled>
      {showBonuses && (
        <BannerBonusesContainer
          currencies={currencies}
          bonuses={user?.bonuses}
          handleClickActionButton={handleSpendPoints}
          handleClickMoreButton={handleShowMorePoints}
        />
      )}
      {showBannerCourses && (
        <BannerCoursesContainer
          handleClickGoToLms={handleClickGoToLms}
          handleClickGoToCourses={handleClickGoToCourses}
        />
      )}
    </AdvantagesCustomerBannersContainerStyled>
  );
};
