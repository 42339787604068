import { PopupStyleProps } from '@lerna-core';

export const popupStyleSettings: PopupStyleProps = {
  paddingSticky: {
    top: '20px',
    right: '20px',
    bottom: '20px',
    left: '20px',
  },
  paddingContent: {
    top: '42px',
    right: '40px',
    bottom: '32px',
    left: '40px',
  },
};
