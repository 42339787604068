import React, { ReactElement, useCallback } from 'react';
import { ContentLeftStyled, TitleStyled } from './contentLeft.styled';
import { useGranulePayFormContext } from '../../granulePayForm.context';
import { LeftHeadContainer } from './leftHead';
import { LeftDescriptionContainer } from './leftDescription';
import {
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';
import { CommonEventTypes, getVendors, useTranslations } from '@lerna-core';
import { useSelector } from 'react-redux';

export const ContentLeftContainer = (): ReactElement => {
  const translations = useTranslations();
  const vendors = useSelector(getVendors);
  const { course, eventPosition, isMobile } = useGranulePayFormContext();

  const handleDescriptionCollapseClick = useCallback(() => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollMoreDetails,
      CommonEventTypes.coursesEvents,
      eventPosition,
      course,
      vendors
    );
  }, []);

  return (
    <ContentLeftStyled>
      <TitleStyled>{translations?.sign_up_for_course}</TitleStyled>
      <LeftHeadContainer course={course} isMobile={isMobile} />
      {course.description && (
        <LeftDescriptionContainer
          description={course.description}
          handleCollapseClick={handleDescriptionCollapseClick}
          isMobile={isMobile}
        />
      )}
    </ContentLeftStyled>
  );
};
