import {
  CourseModel,
  CourseOptionLogicTypes,
  CourseOptionModel,
  getCourseVendorById,
  VendorModel,
} from '../features';
import { GTMEventProductModel, GTMProductModel } from '../models';
import { objectService } from '../services';

export const getCourseOption = (
  options: CourseOptionModel[],
  slug: CourseOptionLogicTypes
): CourseOptionModel | null => {
  const currentOption = options.find((option) => option.logicType === slug);

  if (!currentOption) {
    return null;
  }

  return currentOption;
};

export const getProductDataLayer = (
  course: CourseModel,
  vendors: VendorModel[]
): GTMProductModel => {
  const section =
    course.options &&
    getCourseOption(course.options, CourseOptionLogicTypes.spheres);

  return {
    url: course.aboutLink,
    name: course.title,
    id: course.uuid,
    price: course?.price?.price?.amount || '',
    section: section?.value || '',
    school: getCourseVendorById(vendors, course.vendorUuid)?.title || '',
  };
};

export const getCourseEventData = (
  eventName: string,
  eventType: string,
  eventPosition: string,
  course: CourseModel,
  vendors: VendorModel[]
): GTMEventProductModel => {
  return {
    event: eventName,
    event_type: eventType,
    event_position: eventPosition,
    event_description: {
      product: objectService.clearObject({
        ...getProductDataLayer(course, vendors),
      }),
    },
  };
};
