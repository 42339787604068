import React, { ReactElement } from 'react';
import {
  DurationStyled,
  PricesInstallmentStyled,
  ValueStyled,
  ValueWithDiscountStyled,
  WrapperStyled,
} from './pricesInstallment.styled';
import { PricesInstallmentContainerProps } from './pricesInstallment.model';
import {
  getCurrencies,
  getMonthlyPaymentUnits,
  Nullable,
  useTranslations,
} from '@lerna-core';
import { useSelector } from 'react-redux';

export const PricesInstallmentContainer = ({
  installment,
  hasDiscounts,
}: PricesInstallmentContainerProps): Nullable<ReactElement> => {
  const translations = useTranslations();
  const currencies = useSelector(getCurrencies);
  if (!installment) return null;

  return (
    <PricesInstallmentStyled>
      <WrapperStyled>
        <ValueWithDiscountStyled>
          {getMonthlyPaymentUnits(
            installment.priceWithTotalDiscount,
            currencies
          )}
        </ValueWithDiscountStyled>
        {hasDiscounts && (
          <ValueStyled>
            {getMonthlyPaymentUnits(installment.price, currencies)}
          </ValueStyled>
        )}
      </WrapperStyled>
      <DurationStyled>
        {translations.installment_price_label} {installment.duration}
      </DurationStyled>
    </PricesInstallmentStyled>
  );
};
