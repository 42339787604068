import {
  CoursesModel,
  Nullable,
  RequestHeader,
  ShowcaseRequestProps,
  UserAggregateModel,
} from '@lerna-core';
import {
  CoursesClient,
  CoursesPageClient,
  CoursesPageLazyClient,
} from './courses.client';
import { CoursesPageWithErrorModel } from './courses.model';

export async function coursesPageClientProvider(
  params: ShowcaseRequestProps,
  headers: RequestHeader,
  user?: UserAggregateModel
): Promise<CoursesPageWithErrorModel> {
  const response = await new CoursesPageClient(params, headers, user).get();

  return response as unknown as Promise<CoursesPageWithErrorModel>;
}

export async function coursesPageLazyClientProvider(
  path: string,
  headers: RequestHeader
): Promise<CoursesPageWithErrorModel> {
  const response = await new CoursesPageLazyClient(path, headers).get();

  return response as unknown as Promise<CoursesPageWithErrorModel>;
}

export const coursesClientProvider = async (
  params: ShowcaseRequestProps,
  user: Nullable<UserAggregateModel>,
  requestHeaders?: RequestHeader
): Promise<CoursesModel> => {
  const response = await new CoursesClient(params, user, requestHeaders).get();

  return response as unknown as Promise<CoursesModel>;
};
