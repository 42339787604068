import { CommonEventTypes, CourseModel, VendorModel } from '@lerna-core';
import {
  EVENT_TYPE,
  orderFormEvent,
  OrderFormEvents,
  OrderFormEventsValues,
} from '@features/common/forms';
import { UserOrderFormSubmitStatusTypes } from '../userOrderForm.model';

export const getFormArchiveSubmitStatusEvent = (
  course: CourseModel
): OrderFormEventsValues[] => [
  OrderFormEventsValues.subscription,
  OrderFormEventsValues.noTabs,
  course.subscriptionAccess?.withHomeworks
    ? OrderFormEventsValues.withHomework
    : OrderFormEventsValues.noHomework,
];

export const pushFormArchiveSubmitStatusEvent = (
  type: UserOrderFormSubmitStatusTypes,
  course: CourseModel,
  vendors: VendorModel[],
  eventPosition: string,
  orderId?: string,
  requestType?: string
): void => {
  const eventValueOptions = getFormArchiveSubmitStatusEvent(course);
  const eventType =
    type === UserOrderFormSubmitStatusTypes.success
      ? CommonEventTypes.orderEvents
      : CommonEventTypes.coursesEvents;
  const event = `${OrderFormEvents.formEnrollStatusValue.replace(
    EVENT_TYPE,
    type
  )}`;

  orderFormEvent(
    event,
    eventValueOptions,
    eventType,
    eventPosition,
    course,
    vendors,
    undefined,
    orderId,
    requestType
  );
};
