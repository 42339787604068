import { FooterContainer } from '@features/common/footer';
import { Nullable } from '@lerna-core';
import React, { ReactElement } from 'react';
import { useCoursesPageContext } from '../coursesPage';

export const CoursesFooterContainer = (): Nullable<ReactElement> => {
  const {
    footer: { images = [] },
  } = useCoursesPageContext();

  return <FooterContainer footerImages={images} />;
};
