import { Nullable, TranslationModel } from '../../../models';
import { MenuSwitcherEvents, MenuSwitcherLinkModel } from '../../menu';
import { publicConfig, urlService } from '../../../services';
import {
  CatalogPageTypes,
  projectSubDomains,
  projectTypes,
} from '../../../constants';
import { IHeaderLinkModel } from './iHeader.model';

export const getMenuSwitcherLinks = (
  coursesPath: string,
  translations: TranslationModel,
  userCoursesCount: number,
  pageName: Nullable<CatalogPageTypes>
): MenuSwitcherLinkModel[] => {
  return [
    {
      title: translations.catalog,
      href: urlService.getFullUrlFromToPage(coursesPath),
      isActive: pageName === CatalogPageTypes.courses,
      analyticsEvent: MenuSwitcherEvents.goToCatalog,
    },
    {
      title: translations.my_education,
      count: userCoursesCount,
      href: urlService.getFullUrlToSubDomain(
        projectSubDomains.personal,
        coursesPath
      ),
      isActive: publicConfig.APP_TYPE === projectTypes.personal,
      analyticsEvent: MenuSwitcherEvents.goToPersonal,
    },
  ];
};

export const getHeaderLink = (
  coursesPath: string,
  translations: TranslationModel
): Nullable<IHeaderLinkModel> => {
  switch (true) {
    case publicConfig.APP_TYPE === projectTypes.courses:
      return {
        title: translations.my_education,
        href: urlService.getFullUrlToSubDomain(
          projectSubDomains.personal,
          coursesPath
        ),
      };
    case publicConfig.APP_TYPE === projectTypes.personal:
      return {
        title: translations.catalog,
        href: urlService.getFullUrlFromToPage(coursesPath),
      };
    default:
      return null;
  }
};
