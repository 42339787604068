import React, { ReactElement } from 'react';
import { Label } from '../../label';
import { PasswordInput } from '../../formInputs';
import { FormControl, FormControlError } from '../../formControl';
import { PasswordControlProps } from './passwordControl.model';
import { InputColors } from '../../../../styles';
import { DescriptionComponent } from './description';

const EMPTY_STRING = '';

// TODO: Вынести компонент в папку v2
export const PasswordControlComponent = ({
  label = EMPTY_STRING,
  name,
  value,
  disabled,
  touched,
  errorMessage,
  statusMessage,
  onChange,
  onBlur,
  margin,
  color = InputColors.white,
  bordered = true,
  floatingPlaceholder = true,
}: PasswordControlProps): ReactElement => {
  const hasError = Boolean(errorMessage || statusMessage) && touched;

  return (
    <FormControl margin={margin}>
      {label && !floatingPlaceholder && <Label htmlFor={name}>{label}</Label>}
      <PasswordInput
        id={name}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        disabled={disabled}
        bordered={bordered}
        color={color}
        placeholder={floatingPlaceholder ? label : ''}
        floatingPlaceholder={floatingPlaceholder}
        hasError={hasError}
      />

      {statusMessage && touched ? (
        <FormControlError>{statusMessage}</FormControlError>
      ) : null}

      <DescriptionComponent
        value={value}
        hasError={hasError}
        touched={touched}
        errorMessage={errorMessage}
      />
    </FormControl>
  );
};
