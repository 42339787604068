import {
  CourseOptionLogicTypes,
  CourseOptionModel,
  CourseOptionsSlug,
} from '../courseOptions';
import { CoursePropModel } from './courseProps.model';
import { getDurationsString } from '../course.helper';
import { CourseDurationApiModel } from '../courseDurations';

export const coursePropAdapter = (
  title = '',
  value: string,
  slug: CourseOptionsSlug,
  lineThrough?: boolean
): CoursePropModel => {
  return {
    title,
    value,
    slug,
    lineThrough: !!lineThrough,
  };
};

export const coursePropsAdapter = (
  options: CourseOptionModel[],
  startDate: string,
  durations?: CourseDurationApiModel[]
): CoursePropModel[] => {
  const res = [];
  const optionDifficulty = options.find(
    (option) => option.logicType === CourseOptionLogicTypes.difficulty
  );

  if (optionDifficulty) {
    res.push(
      coursePropAdapter(
        'Сложность',
        optionDifficulty.value,
        CourseOptionsSlug.difficulty,
        false
      )
    );
  }

  if (startDate) {
    res.push(
      coursePropAdapter('Старт', startDate, CourseOptionsSlug.startDate, false)
    );
  }

  if (durations) {
    res.push(
      coursePropAdapter(
        'Длительность',
        getDurationsString(durations),
        CourseOptionsSlug.duration,
        false
      )
    );
  }

  return res;
};
