import { Nullable, TranslationModel } from '../../models';
import {
  ExpiredDateLimitsModel,
  MaxDateObjModel,
  MinDateObjModel,
} from './date.model';

export enum DateFormatEnum {
  'YYYYMMDD' = 'YYYYMMDD',
  'DDMMYYYY' = 'DDMMYYYY',
  'DDMMYY' = 'DDMMYY',
  'hhmm' = 'hhmm',
  'DDMMYYhhmm' = 'DDMMYYhhmm',
  'YYYMMDDhhmmss' = 'YYYMMDDhhmmss',
  'DDMMYYYYhhmmss' = 'DDMMYYYYhhmmss',
}

class DateService {
  private static instance: DateService;
  private static months: Record<number, string> = {
    0: 'Январь',
    1: 'Февраль',
    2: 'Март',
    3: 'Апрель',
    4: 'Май',
    5: 'Июнь',
    6: 'Июль',
    7: 'Август',
    8: 'Сентябрь',
    9: 'Октябрь',
    10: 'Ноябрь',
    11: 'Декабрь',
  };

  public static getInstance(): DateService {
    if (!DateService.instance) {
      DateService.instance = new DateService();
    }

    return DateService.instance;
  }

  /**
   * Method returns time from UNIX 0
   * @return {number} time in milliseconds from UNIX start date
   */
  public get timestamp(): number {
    return new Date().getTime();
  }

  /**
   * Methods returns day length in milliseconds
   * @return {number} day in milliseconds
   */
  public get dayInMilliseconds(): number {
    return 24 * 60 * 60 * 1000;
  }

  /**
   * Method returns one year length in days
   * @return {number} year length in days
   */
  public get yearInDays(): number {
    return 365;
  }

  /**
   * Method returns one month length in days
   * @return {number} month length in days
   */
  public get monthInDays(): number {
    return 30;
  }

  public get weekInDays(): number {
    return 7;
  }

  /**
   * Method will add first 0 for date if date lower then 10
   * @param date{string} - date in string format
   * @return {string} - same date with possible changes
   */
  public dateWithZero(date: string): string {
    return date.length === 1 ? `0${date}` : date;
  }

  /**
   * Method converts date in Date type to format dd.mm.yyyy
   * @param dateValue{Nullable<Date>} - date in sting format
   * @param divider{string} - divider in sting format
   * @param format{DateFormatEnum} - format of date
   * @param withToday{string} - with today flag
   * @param translations - translations
   * @param withTimezone{boolean} - include current user timezone
   * @return {string} - date
   */
  public formatDate(
    dateValue: Nullable<Date> | string,
    format = DateFormatEnum.DDMMYYYY,
    divider = '.',
    withToday = false,
    translations?: TranslationModel,
    withTimezone = false
  ): string {
    if (!dateValue) {
      return '';
    }

    const dateValueWithOrWithoutTimezone = withTimezone
      ? dateValue
      : this.getDateWithoutTimeZone(dateValue);
    const date = new Date(dateValueWithOrWithoutTimezone);
    const year = date.getFullYear();
    const month = this.dateWithZero((date.getMonth() + 1).toString());
    const day = this.dateWithZero(date.getDate().toString());
    const hours = this.dateWithZero(date.getHours().toString());
    const minutes = this.dateWithZero(date.getMinutes().toString());
    const seconds = this.dateWithZero(date.getSeconds().toString());
    const isToday = this.isToday(dateValue);

    switch (format) {
      case DateFormatEnum.YYYYMMDD:
        return `${year}${divider}${month}${divider}${day}`;
      case DateFormatEnum.YYYMMDDhhmmss:
        return `${year}${divider}${month}${divider}${day}, ${hours}:${minutes}:${seconds}`;
      case DateFormatEnum.DDMMYYYY:
        if (withToday && translations && isToday) {
          return `${translations.today}, ${hours}:${minutes}`;
        }
        return `${day}${divider}${month}${divider}${year}`;
      case DateFormatEnum.DDMMYYYYhhmmss:
        if (withToday && translations && isToday) {
          return `${translations.today}, ${hours}:${minutes}`;
        }
        return `${day}${divider}${month}${divider}${year}, ${hours}:${minutes}`;
      case DateFormatEnum.DDMMYY:
        if (withToday && translations && isToday) {
          return `${translations.today}`;
        }

        return `${day}${divider}${month}${divider}${year.toString().slice(-2)}`;
      case DateFormatEnum.hhmm:
        return `${hours}:${minutes}`;
      case DateFormatEnum.DDMMYYhhmm:
        if (withToday && translations && isToday) {
          return `${translations.today}, ${hours}:${minutes}`;
        }

        return `${day}${divider}${month}${divider}${year
          .toString()
          .slice(2)} ${hours}:${minutes}`;
    }
  }

  public convertTimeZone(date: Date | string, timeZone: string): Date {
    return new Date(
      (typeof date === 'string' ? new Date(date) : date).toLocaleString(
        'en-US',
        { timeZone }
      )
    );
  }

  protected getDateWithoutTimeZone = (date: Date | string) => {
    if (date instanceof Date) return date;

    const symbolTimeZone =
      ['Z', '+', '-'].find((s) => date.includes(s)) || null;

    if (symbolTimeZone === null) return date;

    const lastIndex = date.lastIndexOf(symbolTimeZone);
    return lastIndex !== -1 ? date.slice(0, lastIndex) : date;
  };

  protected isToday(dateValue: Nullable<Date> | string): boolean {
    if (!dateValue) {
      return false;
    }

    const nowDate = new Date();
    const nowYear = nowDate.getFullYear();
    const nowMonth = this.dateWithZero((nowDate.getMonth() + 1).toString());
    const nowDay = this.dateWithZero(nowDate.getDate().toString());

    const date = new Date(dateValue);
    const year = date.getFullYear();
    const month = this.dateWithZero((date.getMonth() + 1).toString());
    const day = this.dateWithZero(date.getDate().toString());

    return nowYear === year && nowMonth === month && nowDay === day;
  }

  /**
   * Method return user OS timezone
   * @return {string} - timezone
   */
  public UTCTimezone(): string {
    const prefix = 'UTC';
    const timezoneOffset = new Date().getTimezoneOffset();

    if (!timezoneOffset) {
      if (timezoneOffset === 0) {
        return `${prefix}+${timezoneOffset}`;
      }

      return '';
    }

    const timezoneHours = Math.floor(Math.abs(timezoneOffset / 60));
    const timezoneMinutes =
      timezoneOffset % 60 ? `:${Math.abs(timezoneOffset % 60)}` : '';

    if (timezoneOffset < 0) {
      return `${prefix}+${timezoneHours}${timezoneMinutes}`;
    } else {
      return `${prefix}-${timezoneHours}${timezoneMinutes}`;
    }
  }

  public getReadableDate(dateValue: string): string {
    const date = new Date(dateValue).toLocaleDateString('ru-RU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });

    return date.replace('г.', '');
  }

  /**
   * Метод возвращает минимальную и максимальную допустимую дату
   * @minDateObj - по дефолту минимальная дата +1 день от сегодняшнего
   * @maxDateObj - по дефолту максимальная дата +10 лет от сегодняшней
   * @return { min: string, max: sting }
   */
  public getExpiredDateLimit(
    minDateObj: MinDateObjModel = {
      minYear: 0,
      minMonth: 0,
      minDay: 1,
    },
    maxDateObj: MaxDateObjModel = {
      maxYear: 10,
      maxMonth: 0,
      maxDay: 1,
    }
  ): ExpiredDateLimitsModel {
    const minDate = new Date();
    const maxDate = new Date();

    minDate.setFullYear(
      new Date().getFullYear() + minDateObj.minYear,
      new Date().getMonth() + minDateObj.minMonth,
      new Date().getDate() + minDateObj.minDay
    );

    maxDate.setFullYear(
      new Date().getFullYear() + maxDateObj.maxYear,
      new Date().getMonth() + maxDateObj.maxMonth,
      new Date().getDate() + maxDateObj.maxDay
    );

    return {
      min: this.formatDate(minDate, DateFormatEnum.YYYYMMDD, '-'),
      max: this.formatDate(maxDate, DateFormatEnum.YYYYMMDD, '-'),
    };
  }

  public isExpiredDate(date: string | number | Date): boolean {
    return new Date(date) <= new Date();
  }

  public formattedDateStringToDate(date: string, separator = '.'): Date {
    const [day, month, year] = date?.split(separator);
    return new Date(+year, +month - 1, +day);
  }

  public formatMonthNumberToString(count: number): string {
    if (DateService.months[count]) {
      return DateService.months[count];
    }

    return DateService.months[0];
  }
}

export const dateService = DateService.getInstance();
