import React, { ReactElement } from 'react';
import {
  DotStyled,
  RightPaymentTabsStyled,
  TabStyled,
  TabWrappersStyled,
} from './rightPaymentTabs.styled';
import { useFormB2CContext } from '../../../formB2C.context';
import { TabsPaymentIconsContainer } from './tabsPaymentIcons';
import { useTranslations } from '@lerna-core';

export const RightPaymentTabsContainer = (): ReactElement => {
  const translations = useTranslations();
  const { paymentContent, handleChangePaymentContent, isSubmitting } =
    useFormB2CContext();

  return (
    <RightPaymentTabsStyled>
      <TabStyled
        isActive={paymentContent === 'consultation'}
        disabled={isSubmitting}
        onClick={handleChangePaymentContent.bind(null, 'consultation')}
      >
        <DotStyled isActive={paymentContent === 'consultation'} />
        <TabWrappersStyled>
          {translations?.leave_consultation_request}
        </TabWrappersStyled>
      </TabStyled>
      <TabStyled
        isActive={paymentContent === 'onlinePay'}
        disabled={isSubmitting}
        onClick={handleChangePaymentContent.bind(null, 'onlinePay')}
      >
        <DotStyled isActive={paymentContent === 'onlinePay'} />
        <TabWrappersStyled>
          {translations?.pay_now}
          <TabsPaymentIconsContainer />
        </TabWrappersStyled>
      </TabStyled>
    </RightPaymentTabsStyled>
  );
};
