import React, { forwardRef, ReactElement, useState } from 'react';

import { DropdownContainer } from '../dropdown';
import { FilterDatePopupProps } from './filterDatePopup.model';
import {
  FilterDatePopupBodyStyled,
  FilterDatePopupContainerStyled,
  FilterDatePopupDropdownStyles,
} from './filterDatePopup.styled';
import { PopupFooterContainer } from './popupFooter';
import { TextControlComponent } from '../form';
import { InputColors } from '../../styles';
import {
  getFilterDateFromMaxValue,
  getFilterDateToMinValue,
} from './filterDatePopup.helper';

export const FilterDatePopupContainer = forwardRef<
  HTMLElement,
  FilterDatePopupProps
>(
  (
    {
      fromTitle,
      toTitle,
      open,
      resetButtonTitle,
      applyButtonTitle,
      onApply,
      onClose,
      onReset,
      position,
    },
    ref
  ): ReactElement => {
    const [from, setFrom] = useState<string>('');
    const [to, setTo] = useState<string>('');

    const handleApply = (): void => {
      onClose();
      onApply(from, to);
    };

    const handleReset = (): void => {
      setFrom('');
      setTo('');

      onReset && onReset();
      onClose();
    };

    return (
      <FilterDatePopupContainerStyled>
        {open && (
          <DropdownContainer
            ref={ref}
            handleOutsideClick={onClose}
            top={8}
            position={position}
            extraStyles={FilterDatePopupDropdownStyles}
          >
            <>
              <FilterDatePopupBodyStyled>
                <TextControlComponent
                  type="date"
                  name="from"
                  margin="0"
                  placeholder="DD.MM.YY"
                  color={InputColors.grey}
                  label={fromTitle}
                  value={from}
                  max={getFilterDateFromMaxValue(to)}
                  onReset={() => setFrom('')}
                  onChange={(e) => {
                    setFrom(e.target.value);
                  }}
                />
                <TextControlComponent
                  type="date"
                  name="to"
                  margin="0"
                  placeholder="DD.MM.YY"
                  color={InputColors.grey}
                  label={toTitle}
                  value={to}
                  min={getFilterDateToMinValue(from)}
                  onReset={() => setTo('')}
                  onChange={(e) => {
                    setTo(e.target.value);
                  }}
                />
              </FilterDatePopupBodyStyled>
              <PopupFooterContainer
                resetButtonTitle={resetButtonTitle}
                handleReset={handleReset}
                applyButtonTitle={applyButtonTitle}
                handleApply={handleApply}
              />
            </>
          </DropdownContainer>
        )}
      </FilterDatePopupContainerStyled>
    );
  }
);

FilterDatePopupContainer.displayName = 'FilterDatePopupContainer';
