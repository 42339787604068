import styled, { css } from 'styled-components';
import { breakpoint, fontSettings } from '../../../styles';
import {
  FormAgreementStyled,
  FormAgreementTree,
  TextAreaControlComponent,
} from '../../form';

export const ContactUsFormWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const ContactUsFormContentStyled = styled.div`
  ${breakpoint(
    'mediumDown',
    css`
      padding-bottom: calc(180px + env(safe-area-inset-bottom));
    `
  )}
`;

export const ContactUsFormFooterStyled = styled.div`
  ${FormAgreementTree} {
    margin: -16px -10px 8px;

    ${breakpoint(
      'mediumDown',
      css`
        margin: -6px -6px 8px;
      `
    )}
  }

  ${FormAgreementStyled} {
    margin: -8px 0 16px 0;

    ${breakpoint(
      'mediumDown',
      css`
        margin: 0 0 16px 0;
      `
    )}
  }
`;

export const ContactUsFormHeaderStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
`;

export const HeaderTitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_28, theme.FONT)};
  text-align: center;
  font-weight: 700;
  white-space: break-spaces;
`;

export const HeaderSubtitleStyled = styled.div`
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_17, theme.FONT)};
  font-weight: 400;
  color: ${({ theme }): string => theme.COLOR.TEXT_LIGHT_COLOR};
  text-align: center;
`;

export const TextAreaStyled = styled(TextAreaControlComponent)`
  textarea {
    height: 120px;
  }
`;
