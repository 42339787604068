import React, { ReactElement } from 'react';
import { RightPaymentTabsContainer } from '../rightPaymentTabs';
import { RightHeadStyled, TitleStyled } from './rightHead.styled';
import { useFormB2CContext } from '../../../formB2C.context';
import { useTranslations } from '@lerna-core';

export const RightHeadContainer = (): ReactElement => {
  const translations = useTranslations();
  const { showPaymentTabs } = useFormB2CContext();

  return (
    <RightHeadStyled>
      {showPaymentTabs && <RightPaymentTabsContainer />}
      {!showPaymentTabs && (
        <TitleStyled>{translations?.course_price}</TitleStyled>
      )}
    </RightHeadStyled>
  );
};
