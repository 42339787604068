import {
  browserService,
  ButtonColor,
  ButtonSize,
  DeviceInfoContext,
  IconInfoWithoutBgComponent,
  useTheme,
  useTranslations,
} from '@lerna-core';
import React, { ReactElement, useContext } from 'react';
import { GoToCourseEvent } from '../listItem.analytics';
import { ListItemButtonsEvent } from './listItemButtons.analytics';
import { ListItemButtonsContainerProps } from './listItemButtons.model';
import {
  ListItemAboutButtonStyled,
  ListItemButtonsContainerStyled,
  ListItemSignUpButtonStyled,
} from './listItemButtons.styled';

export const ListItemButtonsContainer = ({
  isActive,
  isGranuleCourse,
  handleOpenSingUpCoursePopup,
  handleGoToCourse,
  courseHref,
}: ListItemButtonsContainerProps): ReactElement => {
  const theme = useTheme();
  const translations = useTranslations();
  const deviceInfoContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceInfoContext);

  const buttonSize = isMobile ? ButtonSize.small : undefined;

  const signUpButtonTitle = isGranuleCourse
    ? translations?.list_item_about_button_title
    : translations?.list_item_sign_up_button_title;

  const showAboutButton = courseHref && isActive;
  const aboutButtonTitle = !isMobile
    ? translations?.list_item_about_button_title
    : undefined;
  const aboutButtonChildren = isMobile ? (
    <IconInfoWithoutBgComponent fillColor={theme.COLOR.TEXT_DARK_COLOR} />
  ) : undefined;

  const handleClickSignUp = (): void => {
    handleOpenSingUpCoursePopup && handleOpenSingUpCoursePopup();
  };
  const handleClickMore = (): void => {
    if (handleGoToCourse) {
      const eventName = courseHref
        ? GoToCourseEvent.goToCourseButton
        : ListItemButtonsEvent.buttonMore;

      handleGoToCourse(eventName);
    }
  };

  return (
    <ListItemButtonsContainerStyled
      data-onboarding-id="iframe-course-buttons"
      isMobile={isMobile}
    >
      {showAboutButton && (
        <ListItemAboutButtonStyled
          onClick={handleClickMore}
          color={ButtonColor.transparent}
          title={aboutButtonTitle}
          size={buttonSize}
          children={aboutButtonChildren}
          href={courseHref}
        />
      )}
      <ListItemSignUpButtonStyled
        onClick={handleClickSignUp}
        color={ButtonColor.purple}
        title={signUpButtonTitle}
        data-instance="sign-up"
        size={buttonSize}
      />
    </ListItemButtonsContainerStyled>
  );
};
