import React, { ReactElement } from 'react';
import {
  getBonusPointsLabel,
  TagLabelColor,
  TagLabelContainer,
  TagLabelSize,
  useTheme,
  useTranslations,
} from '@lerna-core';
import { DiscountPointsContainerProps } from './discountPoints.model';
import { DiscountPointsContainerStyled } from './discountPoints.styled';

export const DiscountPointsContainer = ({
  cashBack,
  tagLabelBorderRadius,
}: DiscountPointsContainerProps): ReactElement => {
  const theme = useTheme();
  const translations = useTranslations();
  const cashBackLabel = (
    <>
      {`${translations.cashback_label} ${cashBack.percent}%: `}
      <b>{`${getBonusPointsLabel(Number(cashBack.points), [
        translations.points_label_1,
        translations.points_label_2,
        translations.points_label_many,
      ])}`}</b>
    </>
  );

  return (
    <DiscountPointsContainerStyled>
      <TagLabelContainer
        size={theme.IS_MOBILE ? TagLabelSize.small : TagLabelSize.normal}
        label={cashBackLabel}
        color={TagLabelColor.gradientBorder}
        borderRadius={tagLabelBorderRadius}
      />
    </DiscountPointsContainerStyled>
  );
};
