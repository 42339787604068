import React, { ReactElement } from 'react';
import * as S from './iUserBar.styled';
import { TooltipComponent } from '../../tooltip';
import { UserAvatarContainer, UserAvatarSize, useUser } from '../../user';
import { getUserData } from './iUserBar.helper';
import { IconPersonComponent } from '../../icons';
import { useTheme } from '../../../hooks';
import { RegisterPositions, useRegisterPopupContext } from '../../register';

const IUserMenu = (): ReactElement => {
  const { user } = useUser();
  const userData = getUserData(user);

  return (
    <S.IUserMenu>
      {userData.map((data, index) => (
        <S.IUserRow key={index}>{data}</S.IUserRow>
      ))}
    </S.IUserMenu>
  );
};

export const IUserBarContainer = (): ReactElement => {
  const { user } = useUser();
  const theme = useTheme();
  const { handleAction } = useRegisterPopupContext();

  const Content = user?.personal?.isFulfilled ? (
    <UserAvatarContainer
      personal={user.personal}
      size={UserAvatarSize.xmedium}
      isRound
      pointer
    />
  ) : (
    <S.IUserBarDefault
      onClick={() => handleAction({}, RegisterPositions.userBar)}
    >
      <IconPersonComponent
        height={16}
        width={16}
        strokeColor={theme.COLOR.PRIMARY['500']}
      />
    </S.IUserBarDefault>
  );

  return (
    <S.IUserBar>
      <TooltipComponent title={<IUserMenu />}>{Content}</TooltipComponent>
    </S.IUserBar>
  );
};
