import {
  HelpLoggerApiModel,
  HelpLoggerModel,
  HelpLoggerUserType,
} from './helpLogger.model';
import { Nullable } from '../../../models';
import { UserAggregateModel } from '../../user';
import { publicConfig } from '../../../services';

const excludedEmails = ['test_orders@test.ru'];

export const isExcludedEmail = (email?: string): boolean =>
  !!email && excludedEmails.includes(email);

const getUserType = (
  user: Nullable<UserAggregateModel>
): HelpLoggerUserType => {
  switch (true) {
    case !!user?.career?.companyId:
      return 'b2b2c';
    case !!user:
      return 'b2c';
    default:
      return 'guest';
  }
};

export const mapHelpLoggerRequestParams = (
  params: HelpLoggerModel
): HelpLoggerApiModel => ({
  region: publicConfig.REGION,
  url: window.location.href,
  email: params.email,
  phone: params.phone,
  name: params.name,
  message: params.message,
  user_type: getUserType(params.user),
});
