export enum MindboxEventName {
  gotToCourse = 'Website.ViewProduct',
  gotToSphere = 'Website.ViewCategory',
  wheelOfFortuneLocal = 'KastomKolesoFortunyLocal',
  wheelOfFortuneRu = 'CustomFortuneRu',
  wheelOfFortuneBy = 'CustomFortuneBy',
  wheelOfFortuneKz = 'CustomFortuneKz',
  wheelOfFortuneKg = 'KastomKolesoFortuny',
  wheelOfFortuneTj = 'KastomKolesoFortunyTJ',
  wheelOfFortuneUz = 'CustomFortuneUz',
}

export type MindboxSites = 'lerna' | 'skillbox';

export type MindboxOptionsModel = {
  email?: string;
  site?: MindboxSites;
};
