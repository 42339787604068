import {
  CourseModel,
  getBonusPointsLabel,
  getCourseVendorById,
  TranslationModel,
  VendorModel,
} from '@lerna-core';

export const getAdvantages = (
  course: CourseModel,
  vendors: VendorModel[],
  translations: TranslationModel
): string[] => {
  const vendor = getCourseVendorById(vendors, course.vendorUuid);
  const advantages = [];

  if (
    course.price?.priceCustomer?.cashBack?.percent &&
    !course.isFree &&
    !course.isFreeByDiscount
  ) {
    const cashbackText = `${translations.cashback_label} ${
      course.price.priceCustomer.cashBack.percent
    }%: ${getBonusPointsLabel(
      Number(course.price.priceCustomer.cashBack.points),
      [
        translations.points_label_1,
        translations.points_label_2,
        translations.points_label_many,
      ]
    )} ${translations?.on_lerna}`;
    advantages.push(cashbackText);
  }
  if (
    course.uniqueSellingPropositions &&
    !course.isFreeByVendor &&
    !course.isFreeByVendorDiscount
  ) {
    advantages.push(...course.uniqueSellingPropositions);
  }
  if (course.isFreeByVendor) {
    const baseValue = 'Бесплатный курс';
    const freeCourseAdvantage = vendor?.title
      ? `${baseValue} от ${vendor.title}`
      : baseValue;

    advantages.push(freeCourseAdvantage);
  }
  if (course.isFreeByVendorDiscount) {
    const baseValue = `Курс со скидкой ${course?.price?.price?.discount?.value}`;
    const freeCourseByDiscountAdvantage = vendor?.title
      ? `${baseValue} от ${vendor.title}`
      : baseValue;

    advantages.push(freeCourseByDiscountAdvantage);
  }

  return advantages;
};
