import styled, { css } from 'styled-components';

import { UserAvatarSize } from '../userAvatar.model';

type AvatarImageProps = {
  size: UserAvatarSize;
  isRound?: boolean;
};

export const getAvatarImageScale = (
  size: UserAvatarSize
): ReturnType<typeof css> => {
  switch (size) {
    case UserAvatarSize.large:
      return css`
        width: 100px;
        height: 100px;
      `;
    case UserAvatarSize.xbig:
      return css`
        width: 64px;
        height: 64px;
      `;
    case UserAvatarSize.big:
      return css`
        width: 52px;
        height: 52px;
      `;
    case UserAvatarSize.medium:
      return css`
        width: 50px;
        height: 50px;
      `;
    case UserAvatarSize.xmedium:
      return css`
        width: 40px;
        height: 40px;
      `;
    case UserAvatarSize.small:
      return css`
        width: 32px;
        height: 32px;
      `;
    case UserAvatarSize.xsmall:
    default:
      return css`
        width: 24px;
        height: 24px;
      `;
  }
};

export const AvatarImageStyled = styled.div<AvatarImageProps>`
  display: flex;

  img {
    ${({ size }): ReturnType<typeof css> => getAvatarImageScale(size)}
    ${(props): string => `border-radius:${props.isRound ? '50%' : '8px'};`}
        object-fit: cover;
    object-position: center;
  }
`;
