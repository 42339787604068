import { ReactElement } from 'react';
import { FooterContainerStyled, FooterContentStyled } from './footer.styled';
import { FooterTopContainer } from './footerTop';
import { FooterBottomContainer } from './footerBottom';
import { FooterContainerProps } from './footer.model';

export const FooterContainer = ({
  footerImages = [],
  socialLinks = [],
  fastLinks,
  staticLinks,
}: FooterContainerProps): ReactElement => (
  <FooterContainerStyled>
    <FooterContentStyled>
      <FooterTopContainer
        fastLinks={fastLinks}
        staticLinks={staticLinks}
        socialLinks={socialLinks}
      />
      <FooterBottomContainer footerImages={footerImages} />
    </FooterContentStyled>
  </FooterContainerStyled>
);
