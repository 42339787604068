import { css } from 'styled-components';
import { rgba } from 'polished';
import { ITheme } from '../../../../features';

export const getShowAllColor = (
  theme: ITheme,
  accentColor?: string,
  isHovered = false
): ReturnType<typeof css> => {
  switch (true) {
    case isHovered && !!accentColor:
      return css`
        span {
          color: ${accentColor};
          opacity: 0.8;
        }

        svg path {
          fill: ${accentColor};
          opacity: 0.8;
        }
      `;
    case isHovered:
      return css`
        span {
          color: ${rgba(theme.COLOR.PRIMARY['500'], 0.8)};
        }

        svg path {
          fill: ${rgba(theme.COLOR.PRIMARY['500'], 0.8)};
        }
      `;
    case !!accentColor:
      return css`
        span {
          color: ${accentColor};
        }

        svg path {
          fill: ${accentColor};
        }
      `;
    default:
      return css`
        span {
          color: ${theme.COLOR.PRIMARY['500']};
        }

        svg path {
          fill: ${theme.COLOR.PRIMARY['500']};
        }
      `;
  }
};
