import { Nullable } from '../../../models';
import { UserAggregateModel } from '../../user';

export const getFIO = (user: Nullable<UserAggregateModel>): Nullable<string> =>
  user?.personal
    ? `${user.personal.firstName} ${user.personal.patronymic || ''} ${
        user.personal.lastName
      }`.trim()
    : null;

export const getUserData = (user: Nullable<UserAggregateModel>): string[] => {
  const userData = [];
  const fio = getFIO(user);

  if (fio) {
    userData.push(fio);
  }
  if (user?.personal.email) {
    userData.push(user.personal.email);
  }
  if (user?.personal.phone) {
    userData.push(user.personal.phone);
  }
  if (user?.career?.companyTitle) {
    userData.push(user.career.companyTitle);
  }

  return userData;
};
