import { InvalidPhoneMessageContainer } from '@features/common/invalidPhoneMessage';
import { ReferralProgramPopupContainer } from '@features/common/popups';
import {
  browserService,
  ContentWrapperStyled,
  CoursesFAQContainer,
  DeviceInfoContext,
  handleOpenPopup,
  Nullable,
  PopupsEnum,
  QueryParams,
  useUser,
} from '@lerna-core';
import { useRouter } from 'next/router';
import React, { ReactElement, useContext, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { CoursesFilterPopupContainer } from './coursesFilterPopup';
import { CoursesListContainer } from './coursesList';
import { useCoursesPageContext } from './coursesPage';
import { CoursesProfTestContainer } from './coursesProfTest';
import { CoursesSeoTextContainer } from './coursesSeo';
import { CoursesWrapperStyled } from './courses.styled';
import { FiltersContainer } from './coursesFilters';

export const CoursesContainer = (): Nullable<ReactElement> => {
  const router = useRouter();
  const dispatch = useDispatch();
  const { user } = useUser();
  const deviceContext = useContext(DeviceInfoContext);
  const isMobile = browserService.mobileDetectFromContext(deviceContext);
  const { faqs } = useCoursesPageContext();
  const isFiltersHiddenOnListing = user?.career?.isFiltersHiddenOnListing;

  const showProfTest = useMemo(() => !user?.company, [user]);
  const showReferralProgramPopup =
    !!router.query[QueryParams.discountProgram] &&
    router.query[QueryParams.referral] === 'true';

  useEffect(() => {
    showReferralProgramPopup &&
      dispatch(handleOpenPopup(PopupsEnum.referralProgramPopup));
  }, []);

  return (
    <ContentWrapperStyled isMobile={isMobile}>
      <CoursesWrapperStyled>
        <FiltersContainer />
        <CoursesListContainer />
      </CoursesWrapperStyled>
      {showProfTest && <CoursesProfTestContainer />}
      <CoursesSeoTextContainer />
      <CoursesFAQContainer faqs={faqs} />
      {!isFiltersHiddenOnListing && <CoursesFilterPopupContainer />}
      <ReferralProgramPopupContainer isMobile={isMobile} />
      <InvalidPhoneMessageContainer />
    </ContentWrapperStyled>
  );
};
