import React, { ReactElement } from 'react';
import { SearchSuggestContainerProps } from './searchSuggest.model';
import {
  SearchSuggestContainerStyled,
  SearchSuggestWrapperStyled,
} from './searchSuggest.styled';
import { SuggestItemContainer } from './suggestItem';
import { SuggestShowAllContainer } from './suggestShowAll';

export const SearchSuggestContainer = ({
  suggestItems,
  term,
  handleClickSuggest,
  arrowIcon,
  accentColor,
}: SearchSuggestContainerProps): ReactElement | null => {
  if (!suggestItems || suggestItems.length === 0) {
    return null;
  }

  const isShowAllButtonVisible = suggestItems.length > 5;

  return (
    <SearchSuggestContainerStyled>
      <SearchSuggestWrapperStyled>
        {suggestItems.slice(0, 5).map(
          (item): ReactElement => (
            <SuggestItemContainer
              item={item}
              key={item.title}
              term={term}
              handleClick={handleClickSuggest}
              arrowIcon={arrowIcon}
            />
          )
        )}
        {isShowAllButtonVisible && (
          <SuggestShowAllContainer
            term={term}
            handleClickSuggest={handleClickSuggest}
            accentColor={accentColor}
          />
        )}
      </SearchSuggestWrapperStyled>
    </SearchSuggestContainerStyled>
  );
};
