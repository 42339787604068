import {
  CompensationRequestsModel,
  CompensationsRequestsParams,
} from './compensationRequests.model';
import { CompensationRequestsClient } from './compensationRequests.client';

export async function compensationsRequestsClientProvider(
  params: CompensationsRequestsParams
): Promise<CompensationRequestsModel> {
  const response = await new CompensationRequestsClient(params).get();

  return response as unknown as Promise<CompensationRequestsModel>;
}
