import React, { ReactElement } from 'react';
import {
  CommonEventTypes,
  getVendors,
  Nullable,
  PopupContainer,
} from '@lerna-core';
import { useCustomCompensationFormContext } from '../customCompensationForm.context';
import { PopupContentContainer } from './popupContent';
import { popupStyleSettings } from './formCommentPopup.meta';
import {
  OrderFormEvents,
  orderFormPushDataLayer,
} from '@features/common/forms';
import { useSelector } from 'react-redux';

export const FormCommentPopupContainer = (): Nullable<ReactElement> => {
  const {
    isCommentPopupOpened,
    toggleCommentPopupOpened,
    course,
    eventPosition,
  } = useCustomCompensationFormContext();
  const vendors = useSelector(getVendors);

  const handleCloseCommentPopup = (): void => {
    orderFormPushDataLayer(
      OrderFormEvents.formEnrollCourseCloseMotivationLetter,
      CommonEventTypes.coursesEvents,
      eventPosition,
      course,
      vendors
    );

    toggleCommentPopupOpened();
  };

  if (!isCommentPopupOpened) return null;

  return (
    <PopupContainer
      isOpened={isCommentPopupOpened}
      handleClose={handleCloseCommentPopup}
      styleSettings={popupStyleSettings}
      fullHeightMobile={false}
      closeOutside
      width="840px"
    >
      <PopupContentContainer />
    </PopupContainer>
  );
};
