import React, { ReactElement } from 'react';
import { useRouter } from 'next/router';
import {
  filterRouterService,
  getCompositeFastLink,
  getCoursesPath,
  getFastLinkHref,
  getFilters,
  pushDataLayer,
  urlService,
  useFastLinksContext,
} from '@lerna-core';
import {
  ItemHeaderSphereBottomRectangleStyled,
  ItemHeaderSphereLinkStyled,
  ItemHeaderSphereStyled,
  ItemHeaderSphereTopRectangleStyled,
  ItemHeaderSphereValueStyled,
} from './itemHeaderSphere.styled';
import { ItemHeaderSphereModel } from './itemHeaderSphere.model';
import { FilterEvent, getTabFilterEventData } from '@features/common/filters';
import { useSelector } from 'react-redux';

export const ItemHeaderSphereContainer = ({
  sphere,
  withSphereFilter = true,
  sphereColor,
}: ItemHeaderSphereModel): ReactElement => {
  const filters = useSelector(getFilters);
  const fastLinks = useFastLinksContext();
  const router = useRouter();
  const coursesPath = getCoursesPath(router);

  const hasFilterQuery = !!router.query[sphere.filterSlug];
  const isDisabled = !withSphereFilter || hasFilterQuery;

  const handleClick = (): void => {
    const newQuery = filterRouterService.getNewRouteTab(
      {
        ...router,
        query: filterRouterService.getNewRouteFromFilters(router, filters),
      },
      sphere.filterSlug,
      sphere.slug
    );

    const fastLink = getCompositeFastLink(fastLinks, newQuery);

    pushDataLayer(
      getTabFilterEventData(
        FilterEvent.constructorFiltersCardCourse,
        sphere.filterSlug,
        sphere.slug
      )
    );

    if (fastLink) {
      router.push(
        getFastLinkHref(fastLink.slug, coursesPath, newQuery),
        undefined,
        {
          scroll: false,
        }
      );
    } else {
      router.push(
        urlService.createUrlFromPathAndQuery(coursesPath, router, newQuery)
      );
    }
  };

  return (
    <ItemHeaderSphereStyled isDisabled={isDisabled}>
      <ItemHeaderSphereLinkStyled
        onClick={handleClick}
        sphereColor={sphereColor}
      >
        <ItemHeaderSphereTopRectangleStyled sphereColor={sphereColor} />
        <ItemHeaderSphereValueStyled>
          {sphere.value}
        </ItemHeaderSphereValueStyled>
        <ItemHeaderSphereBottomRectangleStyled />
      </ItemHeaderSphereLinkStyled>
    </ItemHeaderSphereStyled>
  );
};
