import React, { ReactElement } from 'react';
import { CommonEventPositions, Nullable, useUser } from '@lerna-core';
import {
  GuestOrderFormContainer,
  UserOrderFormContainer,
} from '@features/common/forms';
import { ListFormsContainerProps } from './listForms.model';
import { useCoursesContext } from '@features/courses';

export const ListFormsContainer = ({
  course,
  courseIndex,
}: ListFormsContainerProps): Nullable<ReactElement> => {
  const { user, reloadUser } = useUser();
  const { requestCourses, courses } = useCoursesContext();

  const handleFormSent = (): Promise<void> => {
    return requestCourses({ page: `${courses.meta.currentPage}` }).then(
      reloadUser
    );
  };

  if (!course) {
    return null;
  }

  if (user) {
    return (
      <UserOrderFormContainer
        course={course}
        handleFormSent={handleFormSent}
        eventPosition={CommonEventPositions.coursesPageList}
        courseIndex={courseIndex}
      />
    );
  }

  return (
    <GuestOrderFormContainer
      course={course}
      handleFormSent={handleFormSent}
      eventPosition={CommonEventPositions.coursesPageList}
      courseIndex={courseIndex}
    />
  );
};
