import React, { ReactElement } from 'react';
import { ContentInfoStyled } from './contentInfo.styled';
import { useTranslations } from '@lerna-core';

export const ContentInfoContainer = (): ReactElement => {
  const translations = useTranslations();

  return (
    <ContentInfoStyled>
      {translations?.compensation_b2b_form_info_1}
    </ContentInfoStyled>
  );
};
