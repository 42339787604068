import React, { ReactElement } from 'react';
import { RightPricesStyled } from './rightPrices.styled';
import { useCompensationFormContext } from '../../../compensationForm.context';
import { PricesCustomerContainer } from './pricesCustomer';
import { PricesB2B2CContainer } from './pricesB2B2C';

export const RightPricesContainer = (): ReactElement => {
  const {
    course,
    isLernaPrime,
    isCourseWithCompensationContent,
    isOnlinePaySelected,
  } = useCompensationFormContext();

  return (
    <RightPricesStyled>
      {isCourseWithCompensationContent ? (
        <PricesCustomerContainer />
      ) : (
        <PricesB2B2CContainer
          course={course}
          isLernaPrime={isLernaPrime}
          withInstallment={!isOnlinePaySelected}
        />
      )}
    </RightPricesStyled>
  );
};
