import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { FilterContainer } from '../filter';
import { FiltersContainerStyled, FiltersStyled } from './filters.styled';
import { useFilters } from './filters.hook';
import { FiltersShowAllContainer } from './filtersShowAll';
import { Nullable, useIntersectionObserver } from '@lerna-core';
import { FiltersCoursesCounterContainer } from '../filtersCoursesCounter';

const CoursesCounter = (): ReactElement => {
  const [isSticky, setIsSticky] = useState<boolean>(false);
  const ref = useRef<Nullable<HTMLDivElement>>(null);
  const entryRef = useIntersectionObserver(ref, {});

  useEffect(() => {
    if (entryRef) {
      const isIntersecting = entryRef?.isIntersecting;

      setIsSticky(!isIntersecting);
    }
  }, [entryRef]);

  return (
    <>
      <div ref={ref} />
      <FiltersCoursesCounterContainer isSticky={isSticky} />
    </>
  );
};

export const FiltersContainer = (): Nullable<ReactElement> => {
  const {
    visibleFilters,
    showFiltersShowAllButton,
    toggleShowAllFilters,
    isMobile,
  } = useFilters();

  if (isMobile) {
    return null;
  }

  return (
    <FiltersContainerStyled>
      <FiltersStyled>
        {visibleFilters.map((filter) => (
          <FilterContainer filter={filter} key={filter.slug} />
        ))}
        {showFiltersShowAllButton && (
          <FiltersShowAllContainer handleClick={toggleShowAllFilters} />
        )}
      </FiltersStyled>
      <CoursesCounter />
    </FiltersContainerStyled>
  );
};
