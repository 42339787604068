import { CompensationApiModel, CompensationModel } from '../models';
import { discountAdapter } from './discount.adapter';

export const compensationAdapter = (
  compensation: CompensationApiModel
): CompensationModel => ({
  discount: discountAdapter(compensation?.data?.discount),
  id: compensation?.data.id,
  type: compensation.data.type,
});
