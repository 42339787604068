import React, { ReactElement } from 'react';
import {
  getCurrencies,
  getMonthlyPaymentUnits,
  Nullable,
  useTranslations,
} from '@lerna-core';
import { CourseInstallmentContainerProps } from './itemInstallment.model';
import {
  ItemInstallmentContainerStyled,
  TitleStyled,
  ValueStyled,
} from './itemInstallment.styled';
import { useSelector } from 'react-redux';

export const ItemInstallmentContainer = ({
  price,
}: CourseInstallmentContainerProps): Nullable<ReactElement> => {
  const translations = useTranslations();
  const currencies = useSelector(getCurrencies);
  const showInstallment =
    (!!price?.priceCustomer && !!price?.priceCustomer?.installment) ||
    (!price?.priceCustomer && !!price?.installment);
  const installment = price?.priceCustomer?.installment
    ? price.priceCustomer.installment
    : price.installment;

  if (!showInstallment) {
    return null;
  }

  const monthlyPayment = installment?.priceWithTotalDiscount
    ? getMonthlyPaymentUnits(installment.priceWithTotalDiscount, currencies)
    : installment?.price
    ? getMonthlyPaymentUnits(installment.price, currencies)
    : '';

  return (
    <ItemInstallmentContainerStyled>
      <TitleStyled>
        {translations.installment_price_label} {installment?.duration}
      </TitleStyled>
      <ValueStyled>{monthlyPayment}</ValueStyled>
    </ItemInstallmentContainerStyled>
  );
};
