import {
  ApiInstallmentModel,
  CurrencyModel,
  InstallmentModel,
  PriceValueModel,
} from '../models';
import { setPriceValue } from './price.adapter';

// TODO: check refactoring
export const getMonthlyPaymentUnits = (
  price: PriceValueModel,
  currencies: CurrencyModel[]
): string => `${setPriceValue(price, currencies)}/мес`;

export const installmentAdapter = (
  installment: ApiInstallmentModel
): InstallmentModel => ({
  // TODO: check refactoring
  duration: `${installment.duration} мес`,
  price: {
    amount: installment.price.amount,
    currency: installment.price.currency,
  },
  priceWithTotalDiscount: {
    amount: installment.price_with_total_discount.amount,
    currency: installment.price_with_total_discount.currency,
  },
});
