import React, { ReactElement, useMemo } from 'react';
import { PricesItemContainer } from '../pricesItem';
import { PricesStyled, WrapperStyled } from './prices.styled';
import { PricesConsultationContainerProps } from './prices.model';
import { PricesInfoContainer } from '../pricesInfo';
import {
  getCurrencies,
  textGradient,
  useTheme,
  useTranslations,
} from '@lerna-core';
import {
  getCustomerB2BPrices,
  getCustomerPrices,
} from '@features/common/course';
import { useSelector } from 'react-redux';

export const PricesContainer = ({
  course,
  withInstallment = true,
  withCustomCompensation = false,
  isLernaPrime,
}: PricesConsultationContainerProps): ReactElement => {
  const translations = useTranslations();
  const currencies = useSelector(getCurrencies);
  const theme = useTheme();
  const prices = useMemo(
    () =>
      withCustomCompensation
        ? getCustomerB2BPrices(course, currencies)
        : getCustomerPrices(course, currencies),
    [withCustomCompensation]
  );
  const {
    startPrice,
    hasCorporateDiscount,
    corporateDiscountValue,
    corporateDiscountAmount,
    hasBonusPointsDiscount,
    bonusPointsDiscount,
    totalPrice,
    hasOneOfDiscounts,
    hasInstallment,
  } = prices;

  const showStartPrice =
    (withInstallment && hasInstallment) || hasOneOfDiscounts;
  const showTotalPrice =
    withInstallment &&
    hasInstallment &&
    hasOneOfDiscounts &&
    !course.isFreeByDiscount;
  const showBonusPoints =
    hasBonusPointsDiscount &&
    !course.isFreeByDiscount &&
    !withCustomCompensation;

  const corporateDiscountTitle = `${translations?.customer_discount_label} - ${corporateDiscountValue}`;
  const gradientText = textGradient(
    theme.COLOR.DEFAULT_GRADIENT_START,
    theme.COLOR.DEFAULT_GRADIENT_END
  );
  const bonusPointsTitle = isLernaPrime
    ? translations?.company_discount_bonuses_label
    : translations?.discount_bonuses_label;

  return (
    <PricesStyled>
      {!course.isFree && (
        <WrapperStyled>
          {showStartPrice && (
            <PricesItemContainer
              title={translations?.item_price_label}
              value={startPrice}
              itemStyles={
                hasOneOfDiscounts
                  ? {
                      value: `text-decoration: line-through; color: ${theme.COLOR.TEXT_LIGHT_COLOR}`,
                    }
                  : undefined
              }
            />
          )}
          {hasCorporateDiscount && (
            <PricesItemContainer
              title={corporateDiscountTitle}
              value={corporateDiscountAmount}
              isNegative
            />
          )}
          {showBonusPoints && (
            <PricesItemContainer
              title={bonusPointsTitle}
              value={bonusPointsDiscount}
              isNegative
              itemStyles={{
                title: gradientText,
                value: gradientText,
              }}
            />
          )}
          {showTotalPrice && (
            <PricesItemContainer
              title={translations?.total_price_label}
              value={totalPrice}
              itemStyles={{
                item: 'font-weight: 700;',
              }}
            />
          )}
        </WrapperStyled>
      )}
      <PricesInfoContainer
        course={course}
        prices={prices}
        withInstallment={withInstallment}
      />
    </PricesStyled>
  );
};
