import React, { ReactElement, useCallback, useMemo, useState } from 'react';

import { useTheme } from '../../../hooks';
import { ButtonChildrenPosition, ButtonContainer } from '../../button';
import { ContactUsFormContainer } from '../../forms';
import { IconHelpComponent } from '../../icons';
import { HelpButtonContainerStyled } from './helpButton.styled';
import { getSimpleEvent, pushDataLayer } from '../../../services';
import {
  CommonEvents,
  CommonEventTypes,
  getProjectEventPosition,
} from '../../../analytics';
import { HelpButtonContainerProps } from './helpButton.model';

export const HelpButtonContainer = ({
  title,
  color,
  formInputColor,
  formInputBordered,
  personal,
  usagePosition,
}: HelpButtonContainerProps): ReactElement => {
  const theme = useTheme();

  const [isOpenedContactUsPopup, setIsOpenedContactUsPopup] =
    useState<boolean>(false);

  const eventPosition = useMemo(
    () => getProjectEventPosition(usagePosition),
    []
  );

  const handleOpenContactUsPopup = useCallback(() => {
    if (eventPosition) {
      pushDataLayer(
        getSimpleEvent(
          CommonEvents.clickButtonAskQuestion,
          CommonEventTypes.overallEvents,
          eventPosition
        )
      );
    }
    setIsOpenedContactUsPopup(true);
  }, []);

  const handleCloseContactUsPopup = useCallback(() => {
    setIsOpenedContactUsPopup(false);
  }, []);

  return (
    <>
      <HelpButtonContainerStyled>
        <ButtonContainer
          title={title}
          color={color}
          childrenPosition={ButtonChildrenPosition.left}
          onClick={handleOpenContactUsPopup}
        >
          <IconHelpComponent
            fillColor={theme.COLOR.TEXT_DARK_COLOR}
            strokeColor={theme.COLOR.TEXT_DARK_COLOR}
          />
        </ButtonContainer>
      </HelpButtonContainerStyled>

      <ContactUsFormContainer
        open={isOpenedContactUsPopup}
        handleClose={handleCloseContactUsPopup}
        inputColor={formInputColor}
        inputBordered={formInputBordered}
        personal={personal}
        usagePosition={usagePosition}
      />
    </>
  );
};
