import React, { ReactElement } from 'react';
import { ContentLeftContainer } from './contentLeft';
import { ContentRightContainer } from './contentRight';
import { FormContentStyled } from './formContent.styled';
import { FormContentContainerProps } from './formContent.model';

export const FormContentContainer = (
  props: FormContentContainerProps
): ReactElement => (
  <FormContentStyled>
    <ContentLeftContainer />
    <ContentRightContainer {...props} />
  </FormContentStyled>
);
