import {
  hasBonusPoints,
  isMBMCompany,
  Nullable,
  publicConfig,
  useUser,
} from '@lerna-core';
import React, { memo, ReactElement, useMemo } from 'react';
import { useCoursesPageContext } from '../coursesPage';
import { AdvantagesCustomerBannersContainer } from './advantagesCustomerBanners';
import { AdvantagesImageContainer } from './advantagesImage';
import { AdvantagesListContainer } from './advantagesList';
import { AdvantagesTextContainer } from './advantagesText';
import { AdvantagesContainerProps } from './coursesAdvantages.model';
import {
  AdvantagesContentStyled,
  CoursesAdvantagesStyled,
} from './coursesAdvantages.styled';

const CoursesAdvantages = ({
  handleScrollToCourses,
}: AdvantagesContainerProps): Nullable<ReactElement> => {
  const { advantages } = useCoursesPageContext();
  const { user, userCoursesCount } = useUser();

  const hasCustomersBanners = useMemo(() => {
    return (
      publicConfig?.SHOW_PERSONAL_BANNERS === 'true' &&
      !!user &&
      (hasBonusPoints(user.career) || userCoursesCount > 0) &&
      !isMBMCompany(user.career?.companyId)
    );
  }, [user]);

  const advantagesText = !!advantages && (
    <AdvantagesTextContainer
      title={advantages.title}
      titleColor={advantages.color}
      subtitle={advantages.subtitle}
      withBanners={hasCustomersBanners}
    />
  );
  const advantagesImage = !!advantages?.image && (
    <AdvantagesImageContainer
      image={advantages.image}
      withBanners={hasCustomersBanners}
    />
  );
  const advantagesList = !hasCustomersBanners && !!advantages?.privileges && (
    <AdvantagesListContainer list={advantages.privileges} />
  );
  const advantagesCustomersBanners = hasCustomersBanners && (
    <AdvantagesCustomerBannersContainer
      handleScrollToCourses={handleScrollToCourses}
    />
  );

  if (!advantages) {
    return null;
  }

  return (
    <CoursesAdvantagesStyled
      background={advantages.background}
      withCustomerBanners={hasCustomersBanners}
    >
      <AdvantagesContentStyled withCustomerBanners={hasCustomersBanners}>
        {advantagesText}
        {advantagesList}
        {advantagesCustomersBanners}
      </AdvantagesContentStyled>
      {advantagesImage}
    </CoursesAdvantagesStyled>
  );
};

export const CoursesAdvantagesContainer = memo(CoursesAdvantages);
