import React, { ReactElement } from 'react';
import { getCourseVendorById, getVendors, Nullable } from '@lerna-core';
import { ListItemVendorStyled } from './listItemVendor.styled';
import { ListItemVendorModel } from './listItemVendor.model';
import { CourseVendorLabelContainer } from '@features/course/courseVendorLabel';
import { CourseVendorLogoContainer } from '@features/course';
import { useSelector } from 'react-redux';

export const ListItemVendorContainer = ({
  vendorUuid,
  vendorLabel,
}: ListItemVendorModel): Nullable<ReactElement> => {
  const vendors = useSelector(getVendors);
  const vendor = getCourseVendorById(vendors, vendorUuid);
  const vendorLogo = vendor?.logo || vendor?.coursePlug;

  if (!vendorLogo) {
    return null;
  }

  return (
    <ListItemVendorStyled>
      <CourseVendorLogoContainer logo={vendorLogo} />
      {vendorLabel && <CourseVendorLabelContainer {...vendorLabel} />}
    </ListItemVendorStyled>
  );
};
