import {
  CommonEventTypes,
  CourseModel,
  Translations,
  VendorModel,
} from '@lerna-core';
import * as Yup from 'yup';
import {
  EVENT_TYPE,
  EVENT_VALUE,
  getTextMaxLengthValidationMessage,
  getTextMinLengthValidationMessage,
  orderFormEvent,
  OrderFormEvents,
  OrderFormEventsValues,
  orderFormPushDataLayer,
} from '@features/common/forms';
import {
  CustomCompensationFormInitialValues,
  CustomCompensationFormValidationSchema,
} from './customCompensationForm.model';
import {
  UserOrderFormPaymentContentTypes,
  UserOrderFormSubmitStatusTypes,
} from '../../userOrderForm.model';

export const getCustomCompensationFormInitialValues =
  (): CustomCompensationFormInitialValues => ({
    withPromoCode: false,
    promoCode: '',
    withCustomCompensation: false,
    comment: '',
  });

export function getCustomCompensationFormValidationSchema(
  translations: Translations,
  isOnlinePaySelected: boolean,
  isCommentPopupOpened: boolean
): CustomCompensationFormValidationSchema {
  const validationObject = {
    ...(!isOnlinePaySelected && {
      withPromoCode: Yup.boolean().optional(),
      promoCode: Yup.string().when('withPromoCode', {
        is: true,
        then: (promoCode) =>
          promoCode
            .required(translations?.input_required_error_label)
            .max(255, getTextMaxLengthValidationMessage(255, translations))
            .trim(),
      }),
      withCustomCompensation: Yup.boolean().optional(),
      ...(isCommentPopupOpened && {
        comment: Yup.string()
          .required(translations?.input_required_error_label)
          .min(5, getTextMinLengthValidationMessage(5, translations))
          .max(1000, getTextMaxLengthValidationMessage(1000, translations))
          .trim(),
      }),
    }),
  };

  return Yup.object().shape(validationObject);
}

export const getFormSubmitEventValues = (
  paymentContent: UserOrderFormPaymentContentTypes,
  showPaymentTabs: boolean,
  withCustomCompensation: boolean
): OrderFormEventsValues[] => {
  const eventValueOptions = [
    OrderFormEventsValues.freeCompensation,
    showPaymentTabs
      ? OrderFormEventsValues.withTabs
      : OrderFormEventsValues.noTabs,
  ];

  switch (paymentContent) {
    case UserOrderFormPaymentContentTypes.consultation:
      eventValueOptions.push(
        withCustomCompensation
          ? OrderFormEventsValues.withCompensation
          : OrderFormEventsValues.consultation
      );
      break;
    case UserOrderFormPaymentContentTypes.onlinePay:
      eventValueOptions.push(OrderFormEventsValues.paymentOnline);
      break;
  }

  return eventValueOptions;
};

export const getCustomCompensationPaymentEventValues = (
  showPaymentTabs: boolean
): OrderFormEventsValues[] => [
  OrderFormEventsValues.freeCompensation,
  showPaymentTabs
    ? OrderFormEventsValues.withTabs
    : OrderFormEventsValues.noTabs,
  OrderFormEventsValues.paymentOnline,
];

export const pushFormSubmitStatusEvent = (
  type: UserOrderFormSubmitStatusTypes,
  course: CourseModel,
  vendors: VendorModel[],
  eventPosition: string,
  paymentContent: UserOrderFormPaymentContentTypes,
  showPaymentTabs: boolean,
  withCustomCompensation: boolean,
  orderId?: string,
  requestType?: string
): void => {
  const eventValueOptions = getFormSubmitEventValues(
    paymentContent,
    showPaymentTabs,
    withCustomCompensation
  );
  const event = `${OrderFormEvents.formEnrollStatusValue.replace(
    EVENT_TYPE,
    type
  )}`;
  const eventType =
    type === UserOrderFormSubmitStatusTypes.success
      ? CommonEventTypes.orderEvents
      : CommonEventTypes.coursesEvents;

  orderFormEvent(
    event,
    eventValueOptions,
    eventType,
    eventPosition,
    course,
    vendors,
    undefined,
    orderId,
    requestType
  );
};

export const pushCustomCompensationFormPaymentEvent = (
  course: CourseModel,
  vendors: VendorModel[],
  eventPosition: string,
  showPaymentTabs: boolean
): void => {
  const eventValueOptions =
    getCustomCompensationPaymentEventValues(showPaymentTabs);

  const event = `${OrderFormEvents.formEnrollGoToOnlinePay.replace(
    EVENT_VALUE,
    eventValueOptions.join(',')
  )}`;

  orderFormPushDataLayer(
    event,
    CommonEventTypes.coursesEvents,
    eventPosition,
    course,
    vendors
  );
};

export const pushCustomCompensationFormNextButtonEvent = (
  course: CourseModel,
  vendors: VendorModel[],
  eventPosition: string,
  showPaymentTabs: boolean
): void => {
  orderFormEvent(
    `${OrderFormEvents.formEnrollCourseClickButtonNext}`,
    [
      OrderFormEventsValues.freeCompensation,
      showPaymentTabs
        ? OrderFormEventsValues.withTabs
        : OrderFormEventsValues.noTabs,
      OrderFormEventsValues.withCompensation,
    ],
    CommonEventTypes.coursesEvents,
    eventPosition,
    course,
    vendors
  );
};
