import React, { ReactElement } from 'react';
import { getCoursesPath, Nullable } from '@lerna-core';
import { FiltersResetStyled } from './filtersReset.styled';
import { ResetItemContainer } from './resetItem';
import { ResetAllItemContainer } from './resetAllItem';
import { FiltersResetContainerProps } from './filtersReset.model';
import { ResetShowMoreItemContainer } from './resetShowMoreItem';
import { useFiltersReset } from './filtersReset.hook';
import { useRouter } from 'next/router';

export const FiltersResetContainer = ({
  type = 'desktop',
  customFilterColor,
}: FiltersResetContainerProps): Nullable<ReactElement> => {
  const router = useRouter();
  const coursesPath = getCoursesPath(router);
  const {
    filtersWithSelectedOptions,
    resetItemsToRender,
    handleClearAllFilters,
    toggleCollapsed,
    isMobileType,
    showResetAllItem,
    showShowMoreItem,
  } = useFiltersReset(type, coursesPath);

  if (!filtersWithSelectedOptions.length) return null;

  return (
    <FiltersResetStyled isMobileType={isMobileType}>
      {resetItemsToRender.map((item) => (
        <ResetItemContainer
          coursesPath={coursesPath}
          key={item.itemValue}
          {...item}
          isMobileType={isMobileType}
          customFilterColor={customFilterColor}
        />
      ))}
      {showShowMoreItem && (
        <ResetShowMoreItemContainer handleClick={toggleCollapsed} />
      )}
      {showResetAllItem && (
        <ResetAllItemContainer handleClearAllFilters={handleClearAllFilters} />
      )}
    </FiltersResetStyled>
  );
};
