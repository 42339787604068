import {
  ButtonChildrenPosition,
  ButtonContainer,
  IconTrashComponent,
  Nullable,
  useTheme,
  useTranslations,
} from '@lerna-core';
import React, { ReactElement } from 'react';
import { EmptyClearButtonStyled } from './emptyClearButton.styled';
import { FiltersClearAllContainer } from '@features/common/filters';
import { rgba } from 'polished';

export const EmptyClearButtonContainer = (): Nullable<ReactElement> => {
  const translations = useTranslations();
  const theme = useTheme();

  return (
    <EmptyClearButtonStyled>
      <FiltersClearAllContainer>
        <ButtonContainer
          additionalColors={{
            color: theme.COLOR.WHITE,
            backgroundColor: '#333333',
            backgroundHoverColor: rgba('#333333', 0.8),
          }}
          title={translations?.clear_filter_label}
          borderRadius={100}
          children={<IconTrashComponent />}
          childrenPosition={ButtonChildrenPosition.left}
        />
      </FiltersClearAllContainer>
    </EmptyClearButtonStyled>
  );
};
