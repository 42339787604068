import React, { ReactElement, useMemo } from 'react';
import { PriceValueContainerProps } from './priceValue.model';
import { PriceValueContainerStyled } from './priceValue.styled';
import { useShowLegalEntitiesPriceHook } from '@hooks';
import { getCurrencies, setPriceValue } from '@lerna-core';
import { useSelector } from 'react-redux';

export const PriceValueContainer = ({
  price,
  isB2B,
}: PriceValueContainerProps): ReactElement => {
  const showLegalEntitiesPrice = useShowLegalEntitiesPriceHook();
  const currencies = useSelector(getCurrencies);

  const value = useMemo(() => {
    if (showLegalEntitiesPrice) {
      return price.priceCustomerB2B;
    }

    if (isB2B) {
      return price.priceCustomer;
    }

    return price.price;
  }, [price, showLegalEntitiesPrice]);

  return (
    <PriceValueContainerStyled>
      {setPriceValue(value!, currencies)}
    </PriceValueContainerStyled>
  );
};
