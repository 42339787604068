import {
  CompensationRequestsModel,
  CompensationRequestsResponse,
  CompensationsRequestsApiParams,
  CompensationsRequestsParams,
} from './compensationRequests.model';
import { publicConfig } from '../../services';
import { SecureRequestClient } from '../../services/request/secureRequest.service';
import { mapCompensationRequestsResponse } from './compensationRequests.adapter';

export class CompensationRequestsClient extends SecureRequestClient<
  CompensationsRequestsApiParams,
  CompensationRequestsResponse,
  CompensationRequestsModel
> {
  protected path = 'user-api/manager/v1/compensation-requests';
  protected requestParameters: CompensationsRequestsApiParams;
  protected adapter = mapCompensationRequestsResponse;

  constructor(requestParameters: CompensationsRequestsParams) {
    super(publicConfig.API_GATEWAY_URL);
    this.requestParameters = requestParameters;
  }
}
