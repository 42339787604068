import React, { ReactElement } from 'react';
import { CourseDescriptionContainerStyled } from './courseDescription.styled';
import { CourseTitleContainer, CourseTitleType } from '../courseTitle';
import { SeoTextContainer } from '@features/common/seoText';
import { CourseDescriptionContainerProps } from './courseDescription.model';
import { useTranslations } from '@lerna-core';

export const CourseDescriptionContainer = ({
  description,
}: CourseDescriptionContainerProps): ReactElement => {
  const translations = useTranslations();

  return (
    <CourseDescriptionContainerStyled>
      <CourseTitleContainer
        title={translations.course_description_title}
        type={CourseTitleType.h3}
      />
      <SeoTextContainer html={description} />
    </CourseDescriptionContainerStyled>
  );
};
