import React, { ReactElement, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getCurrentPopupFromOpen,
  getSimpleEvent,
  Nullable,
  PopupsEnum,
  pushDataLayer,
  useUser,
} from '@lerna-core';
import {
  ContactUsFormEvent,
  ContactUsFormEventType,
} from '@features/common/forms/contactUsForm';
import {
  HeaderContainer,
  HeaderEventPositions,
  HeaderLinkTypes,
} from '@features/common/header';
import { useCoursePageContext } from '../course.context';
import { DiscountProgramLogoContainer } from '@features/common/discountProgram';
import { HeaderBannerContainer } from '@features/common/headerBanner';

export const CourseHeaderContainer = (): Nullable<ReactElement> => {
  const { discountProgram, banners } = useCoursePageContext();
  const { user } = useUser();

  const contactUsFormPopup = useSelector(
    getCurrentPopupFromOpen(PopupsEnum.contactUsFormPopup)
  );
  const eventPosition = useMemo(() => {
    if (contactUsFormPopup) {
      return contactUsFormPopup.target || '';
    }

    return HeaderEventPositions.coursePageBlockHeader;
  }, [contactUsFormPopup]);
  const headerLinkType = Boolean(user)
    ? HeaderLinkTypes.switcher
    : HeaderLinkTypes.catalog;
  const discountProgramLogo = discountProgram?.logo && (
    <DiscountProgramLogoContainer logo={discountProgram.logo} />
  );

  const handleDataLayer = (eventName: ContactUsFormEvent): void => {
    pushDataLayer(
      getSimpleEvent(
        eventName,
        ContactUsFormEventType.overallEvents,
        eventPosition
      )
    );
  };

  const handleOpenContactUs = (): void => {
    handleDataLayer(ContactUsFormEvent.buttonAskQuestion);
    handleDataLayer(ContactUsFormEvent.formAskQuestionOpen);
  };

  const handleErrorContactUs = (): void => {
    handleDataLayer(ContactUsFormEvent.formAskQuestionError);
  };

  const handleSuccessContactUs = (): void => {
    handleDataLayer(ContactUsFormEvent.formAskQuestionSuccess);
  };

  const handleCloseContactUs = (): void => {
    handleDataLayer(ContactUsFormEvent.formAskQuestionClose);
  };

  return (
    <>
      <HeaderBannerContainer banners={banners} />
      <HeaderContainer
        showFilters={false}
        showSearch={false}
        hasSticky={true}
        handleOpenContactUs={handleOpenContactUs}
        handleCloseContactUs={handleCloseContactUs}
        handleErrorContactUs={handleErrorContactUs}
        handleSuccessContactUs={handleSuccessContactUs}
        headerLinkType={headerLinkType}
        discountProgramLogo={discountProgramLogo}
        withLogin={!discountProgram?.isHiddenAuthorization}
        withBreadcrumbs
      />
    </>
  );
};
