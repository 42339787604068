import React, { ReactElement, useCallback, useRef, useState } from 'react';

import {
  TableDateFilterStyled,
  TableFilterButtonStyled,
  TableFilterIconStyled,
} from './tableDateFilter.styled';
import { TableFilterContainerProps } from './tableDateFilter.model';
import { IconFilterComponent } from '../../icons';
import { useTheme } from '../../../hooks';
import { Nullable } from '../../../models';
import { DropdownPosition } from '../../dropdown';
import { FilterDatePopupContainer } from '../../filterDatePopup';
import { useTranslations } from '../../translations';

export const TableDateFilterContainer = ({
  filters,
  handleApplyFilter,
  buttonTitle,
}: TableFilterContainerProps): ReactElement => {
  const translations = useTranslations();
  const triggerRef = useRef<Nullable<HTMLDivElement>>(null);
  const [open, setOpen] = useState<boolean>(false);

  const theme = useTheme();

  const togglePopup = useCallback(() => {
    setOpen(!open);
  }, [open]);

  const applyFilter = (from: string, to: string): void => {
    handleApplyFilter(from, to);
  };

  const resetFilter = (): void => {
    handleApplyFilter('', '');
  };

  return (
    <TableDateFilterStyled ref={triggerRef}>
      <TableFilterButtonStyled onClick={togglePopup}>
        {buttonTitle}
        <TableFilterIconStyled selected={!!(filters.from || filters.to)}>
          <IconFilterComponent
            width={16}
            height={16}
            strokeColor={theme.COLOR.TEXT_LIGHT_COLOR}
          />
        </TableFilterIconStyled>
      </TableFilterButtonStyled>

      <FilterDatePopupContainer
        ref={triggerRef}
        open={open}
        fromTitle={translations.date_from}
        toTitle={translations.date_to}
        onClose={togglePopup}
        onReset={resetFilter}
        onApply={applyFilter}
        position={DropdownPosition.right}
        resetButtonTitle={translations.clear_filter_label}
        applyButtonTitle={translations.apply_filter_label}
      />
    </TableDateFilterStyled>
  );
};
