import { logger, Nullable, publicConfig, useUser } from '@lerna-core';
import React, { ReactElement, useContext } from 'react';
import {
  GoogleSheetLoggerModel,
  GoogleSheetLoggerProviderProps,
} from './googleSheetLogger.model';
import { isExcludedEmail } from './googleSheetLogger.helper';
import { googleSheetLoggerClientProvider } from './googleSheetLogger.clientProvider';
import { OrderFormEventsValues } from '@features/common/forms';

const GoogleSheetLogger =
  React.createContext<Nullable<GoogleSheetLoggerModel>>(null);

export const GoogleSheetLoggerProvider = ({
  course,
  children,
}: GoogleSheetLoggerProviderProps): ReactElement => {
  const { user } = useUser();
  const googleSheetLogger = (
    formEventOptions: OrderFormEventsValues[],
    requestParams: any = {}
  ): void => {
    const customer = user
      ? {
          user_name: `${user.personal.firstName} ${user.personal.lastName}`,
          email: user.personal.email,
          phone: user.personal.phone,
        }
      : null;

    if (isExcludedEmail(requestParams.email || customer?.email)) {
      return;
    }

    googleSheetLoggerClientProvider(publicConfig.GOOGLE_SHEET_ORDERS_PATH, {
      formType: formEventOptions.join(','),
      isB2B2C: !!user?.career?.companyId,
      isB2C: !!user?.personal && !user?.career?.companyId,
      isGuest: !user?.personal,
      course: {
        uuid: course.uuid,
        slug: course.slug,
        title: course.title,
      },
      region: publicConfig.REGION,
      url: window.location.href,
      ...customer,
      ...requestParams,
    });
  };

  return (
    <GoogleSheetLogger.Provider value={{ googleSheetLogger }}>
      {children}
    </GoogleSheetLogger.Provider>
  );
};

export const useGoogleSheetLoggerContext = (): GoogleSheetLoggerModel => {
  const value = useContext(GoogleSheetLogger);

  if (!value) {
    logger.error('[ERROR]: you cannot use context without a provider');

    return {} as GoogleSheetLoggerModel;
  }

  return value;
};
