import styled, { css } from 'styled-components';
import { breakpoint, fontSettings, TextareaStyled } from '@lerna-core';

export const PopupContentStyled = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 40px;

  ${breakpoint(
    'mediumDown',
    css`
      flex-direction: column;
      justify-content: flex-start;
      gap: 20px;
      padding-top: 30px;
      padding-bottom: 8px;
    `
  )}
`;

export const TitleStyled = styled.div`
  font-weight: 600;
  flex: 1;
  ${({ theme }): string => fontSettings(theme.FONT.SIZE_40, theme.FONT)}

  ${breakpoint(
    'mediumDown',
    css`
      font-weight: 700;
      ${({ theme }): string => fontSettings(theme.FONT.SIZE_34, theme.FONT)}
    `
  )}
`;

export const WrapperStyled = styled.div`
  max-width: 560px;
  width: 100%;

  ${TextareaStyled} {
    background: rgba(0, 0, 0, 0.04);
    border-radius: 8px;
    height: 150px;
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_16, theme.FONT)};
  }

  ${breakpoint(
    'largeDown',
    css`
      max-width: 100%;
    `
  )}
`;

export const ButtonStyled = styled.div`
  margin-top: 32px;

  button {
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_18, theme.FONT)}
    padding: 15px 40px;
  }

  ${breakpoint(
    'mediumDown',
    css`
      margin-top: 20px;

      button {
        padding: 15px;
        width: 100%;
      }
    `
  )}
`;
