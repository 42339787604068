import { FilterRangePrefixEnum, FiltersSlugEnum } from './filters.enum';
import { stringService } from '../../services';
import { FilterOptionModel } from './filters.model';
import { ParsedUrlQuery } from 'querystring';

/**
 * Checking if the url contains a filter parameter
 * @return {boolean}
 * @params
 * */
export const containUrlFilter = (query: ParsedUrlQuery): boolean => {
  const queryKeys = Object.keys(query);
  const filtersKeys = Object.values(FiltersSlugEnum);

  return filtersKeys.some((filterKey) => {
    const hasRangeFilter =
      queryKeys.includes(
        `${filterKey}${stringService.capitalizeFirstLetter(
          FilterRangePrefixEnum.from
        )}`
      ) ||
      queryKeys.includes(
        `${filterKey}${stringService.capitalizeFirstLetter(
          FilterRangePrefixEnum.to
        )}`
      );
    const hasCheckboxFilter = queryKeys.includes(filterKey);
    const hasAnyFilter = hasRangeFilter || hasCheckboxFilter;

    return !!hasAnyFilter;
  });
};

export const filterOptionsByTerm = (
  term: string,
  options: FilterOptionModel[]
): FilterOptionModel[] => {
  if (!term) {
    return options;
  }

  return options.filter((option) =>
    option.value.toLowerCase().includes(term.toLowerCase())
  );
};
