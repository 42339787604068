import {
  GenericInputClearStyled,
  getSearchTermValueSelector,
  handleSetSearchTerm,
  handleToggleSearchForm,
  IconCloseComponent,
  IconMagnifierComponent,
  Nullable,
  useTheme,
  useTranslations,
} from '@lerna-core';
import React, {
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCoursesPageContext } from '../coursesPage';
import {
  SearchContainerStyled,
  SearchTextStyled,
} from './coursesSearch.styled';

export const CoursesSearchContainer = (): Nullable<ReactElement> => {
  const coursesContext = useCoursesPageContext();
  const translations = useTranslations();
  const dispatch = useDispatch();
  const theme = useTheme();

  const suggestFromStore = useSelector(getSearchTermValueSelector);
  const [suggest, setSuggest] = useState(coursesContext?.suggest || '');

  const inputWrapRef = useRef<Nullable<HTMLElement>>(
    null
  ) as MutableRefObject<HTMLInputElement>;

  const searchPlaceholder = theme.IS_MOBILE
    ? translations?.search_placeholder_mobile
    : translations?.search_placeholder;

  useEffect(() => {
    if (suggestFromStore !== null) {
      setSuggest(suggestFromStore);
    }
  }, [suggestFromStore]);

  const getSuggestColor = (): string =>
    !!suggest ? theme.COLOR.TEXT_DARK_COLOR : theme.COLOR.TEXT_LIGHT_COLOR;

  const searchContainerText = suggest || searchPlaceholder;

  const handleOpen = (): void => {
    let currentPosition = null;
    if (inputWrapRef) {
      const position = (
        inputWrapRef.current as Element
      ).getBoundingClientRect();
      currentPosition = {
        top: position.top,
        left: position.left,
        width: position.width,
      };
    }
    dispatch(handleToggleSearchForm(true, currentPosition));
  };

  const handleClear = (): void => {
    handleOpen();
    dispatch(handleSetSearchTerm(''));
  };

  return (
    <SearchContainerStyled
      onClick={handleOpen}
      ref={inputWrapRef}
      hasSuggest={!!suggest}
      color={getSuggestColor()}
    >
      <IconMagnifierComponent
        width={24}
        height={24}
        fillColor={getSuggestColor()}
        strokeColor={getSuggestColor()}
      />
      <SearchTextStyled>{searchContainerText}</SearchTextStyled>
      <GenericInputClearStyled
        visibleClearControl={!!suggest}
        onClick={handleClear}
      >
        <IconCloseComponent
          width={8}
          height={8}
          strokeWidth={'4'}
          strokeColor={theme.COLOR.WHITE}
        />
      </GenericInputClearStyled>
    </SearchContainerStyled>
  );
};
