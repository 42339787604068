import { css } from 'styled-components';
import { ColumnStickyModel } from 'react-table';

export const getStickyColumBorder = (
  sticky: string
): ReturnType<typeof css> => {
  const border = '1px solid rgba(0, 0, 0, 0.08)';

  switch (true) {
    case sticky === 'left':
      return css`
        border-right: ${border};
      `;
    case sticky === 'right':
      return css`
        border-left: ${border};
      `;
  }
  return css``;
};

export const getStickyColumnStyles = (
  sticky?: ColumnStickyModel,
  rowIndex = 0
): ReturnType<typeof css> => {
  if (sticky) {
    return css`
      background-color: #fff;
      ${getStickyColumBorder(sticky)}
      position: sticky;
      ${sticky}: 0;
      z-index: ${50 - rowIndex};
    `;
  }

  return css``;
};
