import React, { ReactElement } from 'react';

import { IconProps } from './icon.model';

export const IconTrashComponent = ({
  width = 25,
  height = 24,
  strokeColor = '#fff',
}: IconProps): ReactElement => (
  <svg
    width={width}
    height={height}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="m6.5 6 .876 13.133A2 2 0 0 0 9.37 21h6.258a2 2 0 0 0 1.996-1.867L18.5 6m-12 0h3m-3 0h-2m14 0h2m-2 0h-3m0 0V5a2 2 0 0 0-2-2h-2a2 2 0 0 0-2 2v1m6 0h-6"
      stroke={strokeColor}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
