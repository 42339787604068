import { CourseModel, CurrencyModel, setPriceValue } from '@lerna-core';
import { CustomerPricesModel } from './coursePrices.model';

export const getCustomerB2BPrices = (
  { price }: CourseModel,
  currencies: CurrencyModel[]
): CustomerPricesModel => {
  const startPrice = setPriceValue(
    price.startPriceCustomerB2B || price.startPrice,
    currencies
  );

  const corporateDiscountAmount = price?.priceCustomerB2B
    ?.vendorWithPartnershipDiscount
    ? setPriceValue(
        price?.priceCustomerB2B?.vendorWithPartnershipDiscount,
        currencies
      )
    : '';
  const corporateDiscountValue =
    price?.priceCustomerB2B?.vendorWithPartnershipDiscount?.value;
  const hasCorporateDiscount = Boolean(
    corporateDiscountValue && parseInt(corporateDiscountValue)
  );

  const bonusPointsDiscount = price?.priceCustomerB2B?.discountBonuses
    ? setPriceValue(price?.priceCustomerB2B?.discountBonuses, currencies)
    : '';
  const hasBonusPointsDiscount = Boolean(
    bonusPointsDiscount && parseInt(bonusPointsDiscount)
  );

  const companyCompensation = price?.priceCustomerB2B?.compensation?.discount
    ? setPriceValue(price?.priceCustomerB2B?.compensation?.discount, currencies)
    : '';
  const hasCompanyCompensation = Boolean(
    companyCompensation && parseInt(companyCompensation)
  );

  const totalPrice = price.priceCustomerB2B
    ? setPriceValue(price.priceCustomerB2B, currencies)
    : '';

  const totalDiscount = price.priceCustomerB2B?.totalDiscount?.value;
  const hasTotalDiscount = Boolean(totalDiscount && parseInt(totalDiscount));

  const hasInstallment = !!price?.priceCustomerB2B?.installment;

  const hasDiscounts = hasBonusPointsDiscount && hasCorporateDiscount;
  const hasOneOfDiscounts = hasBonusPointsDiscount || hasCorporateDiscount;

  return {
    startPrice,
    hasCorporateDiscount,
    corporateDiscountAmount,
    corporateDiscountValue,
    hasBonusPointsDiscount,
    bonusPointsDiscount,
    hasCompanyCompensation,
    companyCompensation,
    totalPrice,
    hasTotalDiscount,
    totalDiscount,
    hasDiscounts,
    hasOneOfDiscounts,
    hasInstallment,
  };
};
