import React, { ReactElement } from 'react';
import {
  ButtonStyled,
  FormStatusStyled,
  IconStyled,
  SubtitleStyled,
  TitleStyled,
} from './formStatus.styled';
import { getStatusIcon } from './formStatus.meta';
import { getStatusMessage } from './formStatus.helper';
import { FormStatusContainerProps } from './formStatus.model';
import { ButtonColor, ButtonContainer, useTranslations } from '@lerna-core';
import { EXCEL_TEST_HREF } from '../formWithoutPrices.constants';
import { useRouter } from 'next/router';

export const FormStatusContainer = ({
  isSuccessDisplayed,
  isErrorDisplayed,
  isExistOrder,
  isExcelCourse,
}: FormStatusContainerProps): ReactElement => {
  const translations = useTranslations();
  const router = useRouter();
  const statusIcon = getStatusIcon(
    isSuccessDisplayed,
    isErrorDisplayed,
    isExistOrder
  );
  const statusMessage = getStatusMessage(
    isSuccessDisplayed,
    isErrorDisplayed,
    isExistOrder,
    isExcelCourse,
    translations
  );

  const showStatusButton = isExcelCourse && isSuccessDisplayed;

  const handleStatusButtonClick = (): void => {
    router.push(EXCEL_TEST_HREF);
  };

  return (
    <FormStatusStyled>
      <IconStyled>{statusIcon}</IconStyled>
      <TitleStyled>{statusMessage.title}</TitleStyled>
      {statusMessage.subtitle && (
        <SubtitleStyled>{statusMessage.subtitle}</SubtitleStyled>
      )}
      {showStatusButton && (
        <ButtonStyled>
          <ButtonContainer
            onClick={handleStatusButtonClick}
            color={ButtonColor.transparent}
            borderRadius={8}
            title={translations?.go_to_test}
          />
        </ButtonStyled>
      )}
    </FormStatusStyled>
  );
};
