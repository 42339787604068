import {
  PartnershipDiscount,
  PartnershipDiscountApi,
  PartnershipDiscountApiModel,
  PartnershipDiscountModel,
  PartnershipDiscountsResponse,
} from '../models';
import { discountAdapter } from './discount.adapter';
import { vendorAdapter } from '../features';

export const partnershipDiscountAdapter = (
  partnershipDiscount: PartnershipDiscountApi
): PartnershipDiscount => ({
  companyId: partnershipDiscount.company_uuid,
  percentage: partnershipDiscount.percentage,
  vendor: vendorAdapter(partnershipDiscount.vendor.data),
  type: partnershipDiscount.type,
});

export const partnershipDiscountsAdapter = (
  partnershipDiscounts: PartnershipDiscountApi[]
): PartnershipDiscount[] => {
  return partnershipDiscounts.map(partnershipDiscountAdapter);
};

export const partnershipDiscountsApiAdapter = ({
  data: { data },
}: PartnershipDiscountsResponse): PartnershipDiscount[] =>
  partnershipDiscountsAdapter(data);

export const partnershipDiscountPriceAdapter = (
  partnershipDiscount: PartnershipDiscountApiModel
): PartnershipDiscountModel => ({
  discount: discountAdapter(partnershipDiscount?.data?.discount),
});
