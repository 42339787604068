import { MutableRefObject, useCallback, useEffect, useState } from 'react';
import { Hooks, Row } from 'react-table';
import { Nullable } from '../../models';
import { CompareFunction } from './table.model';

export const useDisabledRowsHook =
  (getIsDisabled: CompareFunction) =>
  (hooks: Hooks): void => {
    hooks.prepareRow.push((row: Row, meta) => {
      row.isDisabled = getIsDisabled(row, meta);
    });
  };

export const useHorizontalScroll = (
  tableRef: MutableRefObject<Nullable<HTMLTableElement>>,
  tableBodyRef: MutableRefObject<Nullable<HTMLTableSectionElement>>
): [boolean, boolean] => {
  const [hasLeftOverscroll, setLeftOverscroll] = useState<boolean>(false);
  const [hasRightOverscroll, setRightOverscroll] = useState<boolean>(false);

  const toggleBlur = useCallback(() => {
    if (!tableRef.current) return;

    const scrollWidth =
      tableRef.current.scrollWidth - tableRef.current.clientWidth;

    if (!scrollWidth) {
      setRightOverscroll(false);
      setLeftOverscroll(false);
      return;
    }

    const scrollPosition = tableRef.current.scrollLeft;

    if (scrollPosition > 0) {
      setLeftOverscroll(true);
    } else {
      setLeftOverscroll(false);
    }

    if (scrollPosition < scrollWidth) {
      setRightOverscroll(true);
    } else {
      setRightOverscroll(false);
    }
  }, [tableRef]);

  useEffect(() => {
    if (!tableBodyRef.current) return;

    const resizeObserver = new ResizeObserver(toggleBlur);

    resizeObserver.observe(tableBodyRef.current);

    return () => resizeObserver.disconnect(); // clean up
  }, [tableBodyRef, toggleBlur]);

  useEffect(() => {
    if (!tableRef.current) return;

    tableRef.current!.addEventListener('scroll', () => {
      toggleBlur();
    });

    return tableRef.current!.removeEventListener('scroll', () => {
      toggleBlur();
    });
  }, [tableRef, toggleBlur]);

  return [hasLeftOverscroll, hasRightOverscroll];
};
