import React, { ReactElement } from 'react';
import { ContentLeftStyled, TitleStyled } from './contentLeft.styled';
import { LeftAdvantagesContainer } from './leftAdvantages';
import { LeftCourseInfoContainer } from './leftCourseInfo';
import { useFormSubscriptionContext } from '../../formSubscription.context';
import { useTranslations } from '@lerna-core';

export const ContentLeftContainer = (): ReactElement => {
  const translations = useTranslations();
  const { course } = useFormSubscriptionContext();

  return (
    <ContentLeftStyled>
      <TitleStyled>{translations?.sign_up_for_course}</TitleStyled>
      <LeftAdvantagesContainer />
      <LeftCourseInfoContainer course={course} />
    </ContentLeftStyled>
  );
};
