import React, { ReactElement } from 'react';
import {
  IconArrowComponent,
  Nullable,
  useTheme,
  useTranslations,
} from '@lerna-core';
import { FilterOptionsShowAllContainerProps } from './filterOptionsShowAll.model';
import { optionsCollapseLimit } from '../filterOptions.meta';
import {
  ArrowStyled,
  FilterOptionsShowAllContainerStyled,
} from './filterOptionsShowAll.styled';

export const FilterOptionsShowAllContainer = ({
  handleClick,
  optionsLength,
  showAllFilterOptions,
  filterColor,
}: FilterOptionsShowAllContainerProps): Nullable<ReactElement> => {
  const translations = useTranslations();
  const checkVisibleControl = optionsLength > optionsCollapseLimit;
  const remainingOptions = optionsLength - optionsCollapseLimit;
  const theme = useTheme();

  const text = !showAllFilterOptions
    ? `${translations?.show_filter_options_label} ${remainingOptions}`
    : translations?.hide_filter_options_label;

  if (checkVisibleControl) {
    return (
      <FilterOptionsShowAllContainerStyled
        onClick={handleClick}
        filterColor={filterColor}
      >
        {text}
        <ArrowStyled showAllFilterOptions={showAllFilterOptions}>
          <IconArrowComponent
            strokeColor={filterColor || theme.COLOR.PRIMARY[500]}
          />
        </ArrowStyled>
      </FilterOptionsShowAllContainerStyled>
    );
  }

  return null;
};
