import React, { ReactElement, useMemo } from 'react';
import { IconBurgerComponent } from '../../../icons';
import { MenuMobileBurgerStyled, MenuMobileStyled } from './menuMobile.styled';
import { PopupContainer } from '../../../popups';
import { MenuMobileContainerProps } from './menuMobile.model';
import { MobileDynamicLinksContainer } from './mobileDynamicLinks';
import { MobileStaticLinksContainer } from './mobileStaticLinks';
import {
  getUserMenuBottomStaticLinks,
  getUserMenuTopStaticLinks,
} from '../userMenu.helpers';
import { useRouter } from 'next/router';
import { UserInfoContainer } from './mobileUserInfo';
import { useTheme, useToggle } from '../../../../hooks';
import { RegisterPositions, useRegisterPopupContext } from '../../../register';
import { useTranslations } from '../../../translations';
import { useUser } from '../../user.hook';

export const MenuMobileContainer = ({
  menu,
  isMobile,
  bottomStaticLinks,
  projectPageName,
  withLogin,
}: MenuMobileContainerProps): ReactElement => {
  const translations = useTranslations();
  const router = useRouter();
  const theme = useTheme();
  const { user } = useUser();
  const [isOpened, toggleIsOpened] = useToggle(false);
  const { handleAction } = useRegisterPopupContext();

  const staticLinksTop = useMemo(
    () => getUserMenuTopStaticLinks(translations, router, user, withLogin),
    []
  );

  const staticLinksBottom = useMemo(
    () =>
      getUserMenuBottomStaticLinks(
        translations,
        router,
        user,
        bottomStaticLinks
      ),
    [bottomStaticLinks]
  );

  const handleMenuMobileOpen = () => {
    handleAction({ onSuccess: toggleIsOpened }, RegisterPositions.userBar);
  };

  return (
    <>
      <MenuMobileBurgerStyled onClick={handleMenuMobileOpen}>
        <IconBurgerComponent strokeColor={theme.COLOR.TEXT_DARK_COLOR} />
      </MenuMobileBurgerStyled>
      {isOpened && (
        <PopupContainer
          isOpened={isOpened}
          handleClose={toggleIsOpened}
          isMobile={isMobile}
        >
          <MenuMobileStyled>
            <UserInfoContainer />
            <MobileStaticLinksContainer
              links={staticLinksTop}
              handleClose={toggleIsOpened}
              projectPageName={projectPageName}
            />
            <MobileDynamicLinksContainer
              menu={menu}
              handleClose={toggleIsOpened}
              projectPageName={projectPageName}
            />
            <MobileStaticLinksContainer
              links={staticLinksBottom}
              handleClose={toggleIsOpened}
              projectPageName={projectPageName}
            />
          </MenuMobileStyled>
        </PopupContainer>
      )}
    </>
  );
};
