import React, { ReactElement } from 'react';
import { ContentLeftStyled, TitleStyled } from './contentLeft.styled';
import { useTranslations } from '@lerna-core';

export const ContentLeftContainer = (): ReactElement => {
  const translations = useTranslations();

  return (
    <ContentLeftStyled>
      <TitleStyled>{translations?.form_without_prices_title}</TitleStyled>
    </ContentLeftStyled>
  );
};
