import styled from 'styled-components';
import { fontSettings, transition } from '../../../../styles';
import { getShowAllColor } from './suggestShowAll.mixin';

export const SuggestShowAll = styled.div<{ accentColor?: string }>`
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 16px 0;
  margin-left: ${({ theme }) => (theme.IS_MOBILE ? '0' : '4px')};
  cursor: pointer;
  ${transition('color, fill')};

  span {
    ${({ theme }): string => fontSettings(theme.FONT.SIZE_15, theme.FONT)};
  }

  ${({ theme, accentColor }) => getShowAllColor(theme, accentColor)};

  &:hover {
    ${({ theme, accentColor }) => getShowAllColor(theme, accentColor, true)};
  }
`;
