import React, { ReactElement } from 'react';
import { ContentLeftStyled, TitleStyled } from './contentLeft.styled';
import { LeftAdvantagesContainer } from './leftAdvantages';
import { ContentLeftContainerProps } from './contentLeft.model';
import { useTranslations } from '@lerna-core';

export const ContentLeftContainer = ({
  course,
}: ContentLeftContainerProps): ReactElement => {
  const translations = useTranslations();

  return (
    <ContentLeftStyled>
      <TitleStyled>
        {translations?.course_free_access_by_subscription}
      </TitleStyled>
      <LeftAdvantagesContainer course={course} />
    </ContentLeftStyled>
  );
};
