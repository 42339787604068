import { CourseModel, DiscountProgramColorsModel, Nullable } from '@lerna-core';

export type UserOrderFormContainerProps = {
  course: CourseModel;
  eventPosition: string;
  courseIndex?: Nullable<number>;
  handleFormSent: () => Promise<void>;
  discountProgramColors?: DiscountProgramColorsModel;
};

export enum UserOrderFormPaymentContentTypes {
  consultation = 'consultation',
  onlinePay = 'onlinePay',
}

export enum UserOrderFormSubmitStatusTypes {
  error = 'error',
  success = 'success',
  exist = 'repeat_send',
}
