import {
  CommonEventTypes,
  CourseModel,
  CourseOptionLogicTypes,
  getCourseOption,
  getCourseVendorById,
  GTMEventProductModel,
  GTMProductModel,
  Nullable,
  objectService,
  VendorModel,
} from '@lerna-core';

export const getProductDataLayer = (
  course: CourseModel,
  vendors: VendorModel[]
): GTMProductModel => {
  const section = getCourseOption(
    course.options,
    CourseOptionLogicTypes.spheres
  );

  return {
    url: course.aboutLink,
    name: course.title,
    id: course.uuid,
    price: course.price.price?.amount || '',
    section: section?.value || '',
    school: getCourseVendorById(vendors, course.vendorUuid)?.title || '',
  };
};

export const getCourseEventData = (
  eventName: string,
  eventType: string,
  eventPosition: string,
  course: CourseModel,
  vendors: VendorModel[],
  courseIndex?: Nullable<number>,
  orderId?: string,
  requestType?: string
): GTMEventProductModel => {
  const eventDescription = {
    product: objectService.clearObject({
      ...getProductDataLayer(course, vendors),
    }),
  };

  if (typeof courseIndex === 'number') {
    eventDescription.product = {
      ...eventDescription.product,
      position: courseIndex + 1,
    };
  }

  return {
    event: eventName,
    event_type: eventType,
    event_position: eventPosition,
    event_description: eventDescription,
    ...(eventType === CommonEventTypes.orderEvents && {
      request_id: orderId || '',
    }),
    ...(requestType && { request_type: requestType }),
  };
};
