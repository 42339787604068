import React, { ReactElement } from 'react';
import { ContentRightStyled } from './contentRight.styled';
import { RightHeadContainer } from './rightHead';
import { useFormArchiveContext } from '../../formArchive.context';
import { SubscriptionButtonsContainer } from '@features/common/forms';

export const ContentRightContainer = (): ReactElement => {
  const {
    course,
    handleSubmit,
    isLoading,
    isMobile,
    subscriptionAvailability,
  } = useFormArchiveContext();

  return (
    <ContentRightStyled>
      <RightHeadContainer course={course} isMobile={isMobile} />
      <SubscriptionButtonsContainer
        subscriptionAvailability={subscriptionAvailability}
        handleSignUp={handleSubmit}
        isSignUpDisabled={isLoading}
      />
    </ContentRightStyled>
  );
};
