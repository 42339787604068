import { useTranslations, useUser } from '@lerna-core';
import { useCoursesPageContext } from '../coursesPage';
import { getCoursesPaginationData } from './coursesPagination.helper';
import {
  CoursesPaginationContainerProps,
  CoursesPaginationHookModel,
} from './coursesPagination.model';
import { useCoursesContext } from '@features/courses';

export const useCoursesPagination = ({
  isLoading,
  handleClick,
  handleSuccess,
}: CoursesPaginationContainerProps): CoursesPaginationHookModel => {
  const translations = useTranslations();
  const {
    additionalColors: { buttonAdditionalColors },
  } = useCoursesPageContext();
  const { courses, requestMoreCourses } = useCoursesContext();
  const { reloadUser } = useUser();

  const coursesPaginationData = getCoursesPaginationData(
    courses.meta,
    courses.meta.currentPage,
    translations
  );

  const handleLoadMore = async (): Promise<void> => {
    handleClick();
    reloadUser();
    requestMoreCourses({
      page: `${courses.meta.currentPage + 1}`,
    }).then(handleSuccess);
  };

  return {
    buttonAdditionalColors,
    coursesPaginationData,
    handleLoadMore,
    isLoading,
  };
};
