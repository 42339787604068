import React, { ReactElement } from 'react';
import {
  getCourseVendorById,
  getDurationsString,
  getVendors,
  useTranslations,
} from '@lerna-core';
import {
  CourseLogoStyled,
  DurationStyled,
  LeftHeadStyled,
  StartDateStyled,
  TitleStyled,
  VendorLogoStyled,
  WrapperStyled,
} from './leftHead.styled';
import { RightHeadContainerProps } from './leftHead.model';
import { CourseVendorLogoContainer } from '@features/course/courseVendorLogo';
import {
  CourseLogoContainer,
  CourseLogoSizeEnum,
} from '@features/course/courseLogo';
import { useSelector } from 'react-redux';

export const LeftHeadContainer = ({
  course,
  isMobile,
}: RightHeadContainerProps): ReactElement => {
  const translations = useTranslations();
  const vendors = useSelector(getVendors);
  const vendor = getCourseVendorById(vendors, course.vendorUuid);
  const vendorLogo = vendor?.logo || vendor?.coursePlug;
  const courseLogoSize = isMobile
    ? CourseLogoSizeEnum.big
    : CourseLogoSizeEnum.xbig;

  return (
    <LeftHeadStyled>
      <WrapperStyled>
        {vendorLogo && (
          <VendorLogoStyled>
            <CourseVendorLogoContainer logo={vendorLogo} />
          </VendorLogoStyled>
        )}
        <TitleStyled>{course.title}</TitleStyled>
        {course.durations && (
          <DurationStyled>
            {translations?.item_duration_label}:{' '}
            {getDurationsString(course.durations)}
          </DurationStyled>
        )}
        {!!course.startDate && (
          <StartDateStyled>
            {translations?.course_start}: {course.startDate}
          </StartDateStyled>
        )}
      </WrapperStyled>
      {course.logo && (
        <CourseLogoStyled>
          <CourseLogoContainer logo={course.logo} logoSize={courseLogoSize} />
        </CourseLogoStyled>
      )}
    </LeftHeadStyled>
  );
};
