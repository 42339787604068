import {
  CourseItemApiModel,
  CourseItemModel,
  CourseItemProgressApiModel,
  CourseItemProgressModel,
} from './courseItem.model';
import { mapImageResource } from '../../image';
import { customerCourseVendorAdapter } from '../../customer';

export const mapCourseItem = (course: CourseItemApiModel): CourseItemModel => ({
  uuid: course.id,
  access: {
    accessType: course.access.access_type,
    isHomeworkAvailable: course.access.is_homework_available,
  },
  title: course.lms_course.course?.title || course.lms_course.title,
  studyType: course.lms_course.course?.study_type.title || '',
  type: course.lms_course.course?.type || '',
  courseUrl: course.lms_course.course?.url || '',
  courseId: course.lms_course.course?.id || '',
  vendor: customerCourseVendorAdapter(course.lms_course.vendor),
  logo: course.lms_course.course?.logo
    ? mapImageResource(course.lms_course.course?.logo)
    : null,
  progress: course?.progress ? mapCourseItemProgress(course.progress) : null,
});

const mapCourseItemProgress = (
  progress: CourseItemProgressApiModel
): CourseItemProgressModel => ({
  progressPercent: progress.progress_percent,
  diplomas: progress.diplomas,
  modules: progress.modules,
  practical: progress.practical,
  tests: progress.tests,
  videos: progress.videos,
  total: progress.total,
});
