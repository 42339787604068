import { useDiscountProgram } from '@features/common/discountProgram';
import {
  getSearchTermValueSelector,
  getShowcaseStatusSelector,
  handleSetSearchTerm,
  handleToggleSearchForm,
  IconArrowComponent,
  IconMagnifierComponent,
  Nullable,
  pushDataLayer,
  SearchContainer,
  SearchPopupContainer,
  urlService,
  useTheme,
  useTranslations,
} from '@lerna-core';
import { useRouter } from 'next/router';
import { ParsedUrlQuery } from 'querystring';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useCoursesPageContext } from '../../coursesPage';
import {
  CaseSearchFormEvent,
  getSearchEventData,
} from '../coursesSearch.analytics';
import { SearchPopupWrapContainerProps } from './searchPopupWrap.model';
import { SearchPopupWrapStyled } from './searchPopupWrap.styled';

export const SearchPopupWrapContainer = ({
  isSticky,
  handleScrollToCourses,
}: SearchPopupWrapContainerProps): Nullable<ReactElement> => {
  const coursesContext = useCoursesPageContext();
  const translations = useTranslations();
  const { discountProgramQuery } = useDiscountProgram();

  const dispatch = useDispatch();
  const router = useRouter();
  const theme = useTheme();
  const defaultPath = router.pathname;

  const { isLoaded, isLoading } = useSelector(getShowcaseStatusSelector);
  const suggestFromStore = useSelector(getSearchTermValueSelector);

  const [suggest, setSuggest] = useState(coursesContext?.suggest || '');
  const [searchTerm, setSearchTerm] = useState<string>('');

  const eventName = useMemo(() => {
    if (isSticky) {
      return CaseSearchFormEvent.constructorSearchFixed;
    }

    return CaseSearchFormEvent.constructorSearchMain;
  }, [isSticky]);
  const placeholder = useMemo(
    () =>
      theme.IS_MOBILE
        ? translations.search_placeholder_mobile
        : translations.search_placeholder,
    []
  );
  const magnifierIcon = useMemo(
    () => (
      <IconMagnifierComponent
        fillColor={theme.COLOR.TEXT_LIGHT_COLOR}
        strokeColor={theme.COLOR.TEXT_LIGHT_COLOR}
      />
    ),
    []
  );
  const suggestItemArrowIcon = useMemo(
    () => <IconArrowComponent strokeColor={theme.COLOR.TEXT_LIGHT_COLOR} />,
    []
  );

  useEffect(() => {
    if (suggestFromStore !== null) {
      setSuggest(suggestFromStore);
    }
  }, [suggestFromStore]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | null = null;

    if (isLoaded && !isLoading && !!searchTerm) {
      timeout = setTimeout(() => handleScrollToCourses(), 0);
    }

    return (): void => {
      if (timeout !== null) {
        clearTimeout(timeout);
      }
    };
  }, [isLoaded, isLoading, searchTerm]);

  const handleClickSuggest = (title: string, term: string): void => {
    const trimTitle = title.trim();

    if (!!trimTitle) {
      pushDataLayer(getSearchEventData(eventName, term, trimTitle));
    }

    dispatch(handleSetSearchTerm(trimTitle));
    setSearchTerm(trimTitle);

    const newQuery: ParsedUrlQuery = trimTitle
      ? {
          ...router.query,
          title: trimTitle,
        }
      : router.query;

    if (!trimTitle && coursesContext?.suggest) {
      !!router.query?.title && delete router.query?.title;
    }

    router.push(
      urlService.createUrlFromPathAndQuery(defaultPath, router, newQuery),
      undefined,
      { scroll: false }
    );
  };

  const handleClearUrl = (): void => {
    !!router.query?.title && delete router.query?.title;

    router.push(
      urlService.createUrlFromPathAndQuery(defaultPath, router, router.query),
      undefined,
      { scroll: false }
    );
  };

  const handleClose = (hasChange: boolean): void => {
    dispatch(handleToggleSearchForm(false, null));
    if (hasChange && !suggest && coursesContext?.suggest) {
      handleClearUrl();
    }
  };

  const searchPopupContent = (
    <SearchPopupWrapStyled>
      <SearchContainer
        initialTerm={suggest}
        placeholder={placeholder}
        magnifierIcon={magnifierIcon}
        suggestItemArrowIcon={suggestItemArrowIcon}
        handleClickSuggest={handleClickSuggest}
        handleClose={handleClose}
        handleClearUrl={handleClearUrl}
        accentColor={coursesContext.additionalColors.filtersAccentColor}
        discountProgram={discountProgramQuery}
      />
    </SearchPopupWrapStyled>
  );

  return (
    <SearchPopupContainer
      children={searchPopupContent}
      handleClose={handleClose}
      isMobile={theme.IS_MOBILE}
    />
  );
};
