import React, { ReactElement, useMemo, useRef } from 'react';

import { UserAvatarContainer } from '../userAvatar';
import { UserBarContainerProps } from './userBar.model';
import {
  UserBarAvatarDefaultStyled,
  UserBarContainerStyled,
  UserBarGradientBorderStyled,
  UserBarLabelStyle,
  UserBarTargetStyled,
} from './userBar.styled';
import { BonusPointsContainer, hasBonusPoints } from '../../bonusPoints';
import { stringService } from '../../../services';
import { MenuDesktop } from '../userMenu';
import { useTheme, useToggle } from '../../../hooks';
import { Nullable } from '../../../models';
import { IconPersonComponent } from '../../icons';
import { RegisterPositions, useRegisterPopupContext } from '../../register';
import { useTranslations } from '../../translations';
import { useUser } from '../user.hook';

export const UserBarContainer = ({
  avatarSize,
  isRound,
  handleClick,
  borderRadius = 100,
  menu,
  projectPageName,
}: UserBarContainerProps): Nullable<ReactElement> => {
  const theme = useTheme();
  const translations = useTranslations();
  const { user } = useUser();
  const { handleAction } = useRegisterPopupContext();
  const [isMenuOpened, toggleMenuOpened] = useToggle(false);
  const userBarRef = useRef<Nullable<HTMLDivElement>>(null);
  const withPoints = useMemo(
    () => hasBonusPoints(user?.career),
    [user?.career]
  );

  const bonusLabel = useMemo(() => {
    if (user?.bonuses?.points !== null) {
      return stringService.pluralTranslations(user?.bonuses?.points || 0, [
        translations.points_label_1,
        translations.points_label_2,
        translations.points_label_many,
      ]);
    }

    return '';
  }, [user?.bonuses]);

  const avatar = user?.personal?.isFulfilled ? (
    <UserAvatarContainer
      size={avatarSize}
      personal={user?.personal}
      isRound={isRound}
    />
  ) : (
    <UserBarAvatarDefaultStyled>
      <IconPersonComponent
        height={20}
        width={20}
        strokeColor={theme.COLOR.PRIMARY['500']}
      />
    </UserBarAvatarDefaultStyled>
  );

  const handleClickUserBar = (): void => {
    const onSuccess = () => {
      toggleMenuOpened();

      if (typeof handleClick === 'function') {
        handleClick();
      }
    };

    if (typeof handleAction === 'function') {
      handleAction(
        {
          onSuccess,
        },
        RegisterPositions.userBar
      );
    } else {
      onSuccess();
    }
  };

  return (
    <UserBarContainerStyled ref={userBarRef} borderRadius={borderRadius}>
      <UserBarTargetStyled
        borderRadius={borderRadius}
        onClick={handleClickUserBar}
      >
        <>
          <UserBarGradientBorderStyled borderRadius={borderRadius} />
          {withPoints && user?.bonuses?.points !== null && (
            <UserBarLabelStyle>
              <BonusPointsContainer
                points={user?.bonuses?.points || 0}
                label={bonusLabel}
              />
            </UserBarLabelStyle>
          )}
        </>
        {avatar}
      </UserBarTargetStyled>

      {user?.personal?.isFulfilled && (
        <MenuDesktop
          ref={userBarRef}
          menu={menu}
          user={user}
          isOpened={isMenuOpened}
          handleClose={toggleMenuOpened}
          translations={translations}
          projectPageName={projectPageName}
        />
      )}
    </UserBarContainerStyled>
  );
};
