import { HelpLoggerClient } from './helpLogger.client';
import { isExcludedEmail } from './helpLogger.helper';
import { HelpLoggerModel } from './helpLogger.model';

export const helpLoggerClientProvider = async (
  requestParams: HelpLoggerModel
): Promise<void> => {
  if (isExcludedEmail(requestParams.email)) {
    return Promise.resolve();
  }

  const response = new HelpLoggerClient(requestParams).post();

  return response as unknown as Promise<void>;
};
