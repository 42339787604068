import React, { ReactElement } from 'react';

import { TableHeaderProps } from './tableHeader.model';
import {
  TableHeaderHeadStyled,
  TableHeaderRowStyled,
  TableHeaderStyled,
} from './tableHeader.styled';
import { HeaderGroup } from 'react-table';
import { selectionColumn } from '../tableSelection';

const renderCommonHeader = (
  headerGroup: HeaderGroup,
  hasLeftOverscroll: boolean,
  hasRightOverscroll: boolean
): ReactElement[] => {
  return (headerGroup.columns || headerGroup.headers).map(
    (column, index, list) => {
      const { key, ...headerProps } = column.getHeaderProps();

      const headerSticky = column.sticky;
      let sticky;

      if (index === 0 || index + 1 === list.length) {
        switch (headerSticky) {
          case 'left':
            sticky = hasLeftOverscroll ? headerSticky : undefined;
            break;
          case 'right':
            sticky = hasRightOverscroll ? headerSticky : undefined;
            break;
          default:
            break;
        }
      }

      const showResizer =
        index !== headerGroup.headers.length - 1 &&
        column.canResize &&
        column?.getResizerProps;

      return (
        <TableHeaderHeadStyled key={key} sticky={sticky} {...headerProps}>
          {column.render('Header')}

          {showResizer && (
            <div
              {...column.getResizerProps()}
              className={`resizer ${column.isResizing ? 'isResizing' : ''}`}
            />
          )}
        </TableHeaderHeadStyled>
      );
    }
  );
};

const renderCustomHeader = (
  headerGroup: HeaderGroup,
  hasLeftOverscroll: boolean,
  hasRightOverscroll: boolean,
  component: ReactElement | boolean
): ReactElement => {
  const group = {
    ...headerGroup,
    headers: headerGroup.headers.filter(
      (col) => col?.id === selectionColumn.id
    ),
  };

  return (
    <>
      {renderCommonHeader(group, hasLeftOverscroll, hasRightOverscroll)}
      {component}
    </>
  );
};

export const TableHeaderContainer = ({
  headerGroups,
  isSticky,
  headerComponent,
  hasLeftOverscroll,
  hasRightOverscroll,
}: TableHeaderProps): ReactElement => {
  return (
    <TableHeaderStyled isSticky={isSticky}>
      {headerGroups.map((headerGroup) => {
        const { key, ...headerGroupProps } = {
          ...headerGroup.getHeaderGroupProps(),
        };

        return (
          <TableHeaderRowStyled key={key} {...headerGroupProps}>
            {headerComponent
              ? renderCustomHeader(
                  headerGroup,
                  hasLeftOverscroll,
                  hasRightOverscroll,
                  headerComponent
                )
              : renderCommonHeader(
                  headerGroup,
                  hasLeftOverscroll,
                  hasRightOverscroll
                )}
          </TableHeaderRowStyled>
        );
      })}
    </TableHeaderStyled>
  );
};
