import React, { useContext } from 'react';
import {
  CoursesModel,
  initialPaginationMeta,
  logger,
  Nullable,
} from '@lerna-core';

export const CoursesByTitleContext =
  React.createContext<Nullable<CoursesModel>>(null);

export const useCoursesByTitleContext = (): CoursesModel => {
  const value = useContext(CoursesByTitleContext);

  if (!value) {
    logger.error(
      '[ERROR]: CoursesContext: you cannot use context without a provider'
    );

    return {
      data: [],
      meta: initialPaginationMeta,
    } as CoursesModel;
  }

  return value;
};
