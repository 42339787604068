import React, { ReactElement } from 'react';
import {
  ButtonColor,
  ButtonContainer,
  FiltersSlugEnum,
  getFastLinkHref,
  IconNextComponent,
  urlService,
} from '@lerna-core';
import {
  PageNotFoundFilterOptionStyled,
  PageNotFoundFilterStyled,
} from '../pageNotFound.styled';
import {
  PageNotFoundLinkContainerProps,
  PageNotFoundLinksContainerProps,
} from '../pageNotFound.model';
import { pageUrls } from '@constants';

export const PageNotFoundLinksContainer = ({
  coursesData,
}: PageNotFoundLinksContainerProps): ReactElement => {
  const { all_directions_title } = coursesData?.translations;

  const fastLinksWithSphereOption = (coursesData?.fastLinks || []).filter(
    (fastLink) => {
      const optionsLength = Object.keys(fastLink.options || {}).length;
      if (optionsLength === 1) {
        return !!fastLink.options?.[FiltersSlugEnum.sphere]?.length;
      }

      return false;
    }
  );

  return (
    <PageNotFoundFilterStyled>
      <PageNotFoundLinkContainer
        key="all"
        title={all_directions_title}
        href={urlService.normalizeUrl(pageUrls.courses)}
      />
      {fastLinksWithSphereOption.map(({ title, slug }) => (
        <PageNotFoundLinkContainer
          key={slug}
          title={title}
          href={getFastLinkHref(slug, pageUrls.courses)}
        />
      ))}
    </PageNotFoundFilterStyled>
  );
};

export const PageNotFoundLinkContainer = ({
  title,
  href,
}: PageNotFoundLinkContainerProps): ReactElement => {
  return (
    <PageNotFoundFilterOptionStyled href={href}>
      <ButtonContainer
        color={ButtonColor.white}
        boxShadow={'0 5px 25px -5px rgba(12, 11, 13, 0.04)'}
        title={title}
      >
        <IconNextComponent />
      </ButtonContainer>
    </PageNotFoundFilterOptionStyled>
  );
};
